import * as brandActions from '@store/brand';
import * as campaignDraftActions from '@store/campaign-draft';
import { Actions, ofType } from '@ngrx/effects';
import { AppState } from '@store/app.state';
import { CampaignModel } from '@app/shared/model';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DialogService } from '@ngneat/dialog';
import { MatDialog } from '@angular/material/dialog';
import { PaymentsDialogComponent } from '@app/dialogs/payments-dialog/payments-dialog.component';
import { PaypalDialogComponent } from '@app/dialogs/paypal-dialog/paypal-dialog.component';
import { RegistrationDialogComponent } from '@app/dialogs/registration-dialog/registration-dialog.component';
import { Router } from '@angular/router';
import { concat, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { last, take, takeUntil } from 'rxjs/operators';
import { selectBrandFunds, selectCampaignDraft } from '@app/store';
import {AccountPlan} from "@app/shared/types/account-plan.enum";
import { CampaignService } from '@app/services/campaign.service';

@Component({
  selector: 'app-campaign-wizard-step',
  templateUrl: './campaign-wizard.component.html',
  styleUrls: ['./campaign-wizard.component.scss'],
})
export class CampaignWizardComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  @Input() stepTitle: string;
  @Input() enabledNextStep: boolean;
  @Output() previousStepClicked = new EventEmitter();
  @Output() nextStepClicked = new EventEmitter();

  steps: {
    reward: false;
    campaign: false;
    brief: false;
    review: false;
    submit: false;
  };

  isCreatedCampaign = false;
  nextButtonText = '';
  editCampaignName = 'create new campaign';
  submitCampaignProcess = false;
  isEdit = false;
  reviewStep = false;
  private campaignMoodImages = [];
  private brandFunds: number;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private translate: TranslateService,
    private actions: Actions,
    private toastr: ToastrService,
    private dialog: DialogService,
    private dialogBrand: DialogService,
    private dialogPayments: MatDialog,
    private campaignService: CampaignService
) {}

  ngOnInit(): void {
    this.steps = {
      ...this.steps,
      [this.stepTitle]: true,
    };

    if (this.router.routerState.snapshot.url.includes('edit')) {
      this.isEdit = true;
      this.store
        .pipe(select(selectCampaignDraft), takeUntil(this.destroyed$))
        .subscribe((campaignDraft: CampaignModel) => {
          this.editCampaignName = campaignDraft?.campaignName;
          this.isCreatedCampaign = !!campaignDraft?.createdDatetime;
        });
    } else {
      this.store.pipe(select(selectBrandFunds), takeUntil(this.destroyed$)).subscribe((funds) => {
        this.brandFunds = funds;
      });
    }
    if (this.router.routerState.snapshot.url.includes('review')) {
      this.reviewStep = true;
    }
    this.store.dispatch(brandActions.getBrand());
    this.actions.pipe(ofType(brandActions.getBrandSuccess), take(1)).subscribe(({brand}) => {
      if (!brand) {
        const dialogRef = this.dialogBrand.open(
          RegistrationDialogComponent,
          {
            enableClose: false,
            width: '1200px',
          }
        );
      }
      if (brand?.subscription.status !== 'active' || (brand?.subscription.status === 'active' && brand?.subscription.plan === AccountPlan.test)) {
        // if (brand.subscription.plan === AccountPlan.test) {
        //   const dialogRef = this.dialogBrand.confirm(`Your subscription is no longer active. <br> Please upgrade or renew your subscription.`, {closeButton: true});
        //   dialogRef.afterClosed$.subscribe((result) => {
        //     if (result) {
        //       this.router.navigate(['profile', {subscription: 'update'}]);
        //     }
        //   });
        // } else {
          const components = brand?.subscription.active_until.split('-');
          const day = parseInt(components[0], 10);
          const month = parseInt(components[1], 10) - 1; // Subtract 1 from the month because months are zero-based in JavaScript
          const year = parseInt(components[2], 10);
          const specificDate = new Date(year, month, day).toLocaleString().split(',')[0];
          const currentDate = new Date().toLocaleString().split(',')[0];
          if (specificDate > currentDate && brand?.subscription.plan !== AccountPlan.test) {
            return;
          }
          this.dialogPayments.open(PaymentsDialogComponent, {
            data: {
              subscription: brand.subscription
            }
          });
        }
      // }
    });
    this.subscribeSubmitCampaign();
    this.subscribeSubmitCampaignSuccess();
    this.subscribeSubmitCampaignFail();

    this.translate
      .get('newCampaign.review.submitBtn')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const nextButton = this.translate.instant('newCampaign.review.nextBtn');
        const submitButton = this.translate.instant('newCampaign.review.submitBtn');
        this.nextButtonText = this.stepTitle === 'submit' ? submitButton : nextButton;
        if (!this.isEdit) {
          this.editCampaignName = this.translate.instant('newCampaign.newCampaignTitle');
        }

        if (!this.isEdit && this.reviewStep) {
          this.editCampaignName = this.translate.instant('newCampaign.reviewCampaignTitle');
        }
      });
  }
  subscribeSubmitCampaign(): void {
    this.actions
      .pipe(ofType(campaignDraftActions.editCampaignDraftSubmit), takeUntil(this.destroyed$))
      .subscribe(({ campaign }) => {
        if (campaign.moodImages?.length) {
          this.campaignMoodImages = campaign.moodImages.slice();
        }
        this.submitCampaignProcess = true;
      });
  }

  private successSubmitRedirect(campaign: CampaignModel) {
    let submitCampaignSuccess: string;

    if (this.isEdit) {
      submitCampaignSuccess = this.translate.instant('newCampaign.submitMsg.submitEditMsgSuccess');
    } else {
      submitCampaignSuccess = this.translate.instant('newCampaign.submitMsg.submitCreateMsgSuccess');
    }

    this.submitCampaignProcess = false;
    this.toastr.success(submitCampaignSuccess);
    if (!this.isCreatedCampaign) {
      if (campaign.reward.cash !== true) {
        const amount = (campaign.reward.voucher_percentage) ?
          campaign.reward.voucher_percentage + ' %' :
          campaign.reward.voucher_fixed + ' EUR';
        // const message = `For this campaign, you must upload vouchers ${amount} discount. <br> Pay attention, the campaign goes live only when there is at least one active voucher.`
        // const dialogRef = this.dialog.success(message);
        // return dialogRef.afterClosed$.subscribe(() => {
        //   this.router.navigate(['admin/vouchers/create']);
        // });
        return this.router.navigate([ 'admin/vouchers/create' ]);
      } else {
        if (this.brandFunds === 0) {
          const message = `Please, replenish the balance of your wallet to be able to approve the posts.`;
          const dialogRef = this.dialog.success(message);
          return dialogRef.afterClosed$.subscribe(() => {
            this.router.navigate([ 'campaigns' ]);
            this.dialog.open(PaypalDialogComponent, {
              closeButton: true
            });
          });
        }
      }
    }
    this.router.navigate([ 'campaigns' ]);
  }

  subscribeSubmitCampaignSuccess(): void {
    this.actions
    .pipe(ofType(campaignDraftActions.editCampaignDraftSubmitSuccess), take(1), takeUntil(this.destroyed$))
    // @ts-ignore
    .subscribe(({ campaign }) => {
      const images = this.campaignMoodImages.map(({ image }) => this.campaignService.addCampaignMoodImage(campaign.id, image));
      if (images.length) {
        concat(...images).pipe(last()).subscribe(() => {
          this.successSubmitRedirect(campaign);
        });
      } else {
        this.successSubmitRedirect(campaign);
      }
    });
  }
  subscribeSubmitCampaignFail(): void {
    this.actions
      .pipe(ofType(campaignDraftActions.editCampaignDraftSubmitFail), takeUntil(this.destroyed$))
      .subscribe((error) => {
        console.log(error);
        this.submitCampaignProcess = false;
        this.toastr.error(error.error?.campaign.detail);
      });
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  goToPreviousStep(): void {
    if (this.isEdit && this.steps.campaign && this.isCreatedCampaign) {
     this.toastr.warning('You can not edit the reward option of the created campaign!');
      return;
    }
    console.log(this.steps)
    this.previousStepClicked.emit();
  }

  goToNextStep(): void {
    this.nextStepClicked.emit();
  }
}
