import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { selectUserInfo } from '@app/store';
import {PlanDescriptionEnum} from "@app/dialogs/payments-dialog/plan-descriptions";
import { FormBuilder } from '@angular/forms';
import { take } from 'rxjs/operators';
import { PaymentsService } from '@app/services/payments.service';
import { PlanDto } from '@app/shared/dto';

@Component({
  selector: 'app-change-plan-dialog',
  templateUrl: './change-plan-dialog.component.html',
  styleUrls: ['./change-plan-dialog.component.scss']
})
export class ChangePlanDialogComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private paymentsService: PaymentsService,
    private dialogRef: MatDialogRef<ChangePlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  accountPlans: any[] = [];
  planDescriptions = PlanDescriptionEnum;
  public stepNum = 3;

  public priceForm = this.fb.group({
    discountCoupon: [ '' ],
    isExtendedOptions: [ false ]
  });

  public selectedPlan: string;
  public isTest = false;
  public isCorporate = false;

  public success: string;
  public error: string;
  public isDiscountInput = false;

  private paypalPlanId: string;
  public totalPrice: number;
  public currency: string;

  // AccountPlan = AccountPlan;

  ngOnInit(): void {
    this.store.pipe(select(selectUserInfo)).subscribe((user) => {
      // if (user?.promocode) {
      //   this.promocode = user.promocode;
      //   if (this.data.selectedPlan) {
      //     this.accountPlans = this.data?.accountPlans?.filter((plan) => {
      //       return !plan?.slug?.includes('test');
      //     });
      //   } else {
      //     this.accountPlans = this.data?.accountPlans;
      //   }
      // } else {
      if (this.data.selectedPlan) {
        this.accountPlans = this.data?.accountPlans?.filter((plan) => {
          return !plan?.slug?.includes('test') && !plan?.slug?.toLowerCase().includes('corporate');
        });
      } else {
        this.accountPlans = this.data?.accountPlans;
      }
      // }
    });
    this.accountPlans = this.accountPlans.sort((a, b) => {
      return a?.price - b?.price;
    });
    this.selectedPlan = this.data.selectedPlan; // string
    this.currency = this.accountPlans.find((plan) => plan?.name.includes('STARTER'))?.currency;

    this.priceForm.controls.isExtendedOptions.valueChanges.subscribe((fee) => this.getPlans(fee));
  }

  public getPlans(isExtendedOptions?: boolean): void {
    const form = this.priceForm.value;

    const params = {
      fee: isExtendedOptions ?? form.isExtendedOptions,
      promocode: form.discountCoupon,
      isTrial: this.isTest
    };

    this.paymentsService.getPlans(params)
    .pipe(take(1))
    .subscribe((res) => {
      const selectedPlan = res.plan.find((plan) => plan.slug === this.selectedPlan);
      if (!selectedPlan) {
        return;
      }

      if (selectedPlan.error) {
        this.success = null;
        this.error = selectedPlan.error ? 'Wrong discount code' : null;
      } else {
        this.error = null;
        this.success = selectedPlan.message ? 'Discount ' + selectedPlan.message + '%' : null;
      }

      if (!selectedPlan.slug.includes('test')) {
        this.totalPrice = selectedPlan.price;
        this.currency = selectedPlan.currency;
        this.paypalPlanId = selectedPlan.paypal_plan_id;
      }
    });
  }

  public selectPlan(plan: PlanDto): void {
    this.selectedPlan = plan.slug;

    const isTest = plan.slug.includes('test');
    const isCorporate = plan.slug.toLowerCase().includes('corporate');

    if (isTest || isCorporate) {
      this.isTest = isTest;
      this.isCorporate = isCorporate;

      this.isDiscountInput = false;
      this.totalPrice = 0;
      this.paypalPlanId = '';
      this.priceForm.setValue({
        isExtendedOptions: false,
        discountCoupon: ''
      }, { emitEvent: false });
    } else {
      if (this.stepNum === 3) {
        this.isTest = false;
        this.isCorporate = false;
      }
    }

    this.getPlans();
  }

  public clearPromocode(): void {
    this.isDiscountInput = false;
    this.priceForm.controls.discountCoupon.setValue('');
    this.getPlans();
  }

  closeDialog() {
    const isTest = this.selectedPlan?.includes('test');
    if (isTest) {
      this.isTest = true;
      this.accountPlans = this.accountPlans.filter((plan) => !plan?.slug?.includes('test') && !plan?.slug?.toLowerCase().includes('corporate'));
      this.stepNum++;
      this.selectedPlan = null;
      return;
    }

    const isCurrentPlanAvailable = this.accountPlans.find((plan) => plan?.slug === this.selectedPlan);
    const newPlan = isCurrentPlanAvailable ? this.selectedPlan : null;
    this.dialogRef.close({
      plan: newPlan,
      totalPrice: this.totalPrice,
      currency: this.currency,
      discountCoupon: this.priceForm.value.discountCoupon,
      isExtendedOptions: this.priceForm.value.isExtendedOptions,
      isTest: this.isTest,
      isCorporate: this.isCorporate,
      paypalPlanId: this.paypalPlanId,
    });
  }
}
