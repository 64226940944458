<section class="video-box mt-160 mb-100">
  <div class="wrapper">
    <div class="title-group">
      <h2>SaaS Agency</h2>
      <p class="title-krona">{{'landing.platform.videoBlock.groupDescription' | translate}}</p>

    </div>

    <div class="video-box__block">
      <div class="video">
        <video #video width="100%" height="100%" controls preload="auto" poster='assets/img/poster.png'>
          <source src="{{ linkToVideo }}" type="video/mp4" >
        </video>
      </div>

      <div class="video-box__wrapper-small">
        <div class="video-box__group-text">
          <span class="video-box__title">{{'landing.platform.videoBlock.title' | translate}}</span>
          <span class="video-box__text">{{'landing.platform.videoBlock.description' | translate}}</span>

          <button (click)='openDemoDialog()' type="button" class="btn big primary-btn-filled">
            <span class="btn__text">{{'landing.home.buttonFindOutMore' | translate}}</span>
          </button>
        </div>
      </div>

      <img src="assets/img/video-box_bg.jpg" alt="" class="video-box__bg">
    </div>
  </div>
</section>

<section class="brand-text">
  <div class="brand-text__cont">
    <div class="brand-text__colum">
      <img src="assets/img/brand-text__img.png" alt="" class="brand-text__img">
    </div>
    <div class="brand-text__colum">
      <h2>{{'landing.platform.brandTitle' | translate}}</h2>
      <span class="brand-text__text" [innerHTML]="'landing.platform.brandDescription' | translate"></span>
    </div>
  </div>
</section>

<div class="social-media social-media-brand pb-100">
  <div class="wrapper">
    <div id="social-media" class="social-media__block">
      <img src="assets/img/social-media-bg.jpg" alt="" class="social-media__bg">

      <div class="social-media__colum box-caption">
        <span class="social-media__title">{{'landing.platform.socialMediaTitle' | translate}}</span>
      </div>
      <div class="social-media__group">
        <div class="social-media__colum box-one">
          <span class="social-media__line" style="background-color: #41E2BA;"></span>
          <span class="social-media__number"><b id="influencers">0</b>+</span>
          <span class="social-media__name">Influencers</span>
        </div>
        <div class="social-media__colum box-two">
          <span class="social-media__line" style="background-color: #E241DC;"></span>
          <span class="social-media__number"><b id="instagram-reach">0</b>+</span>
          <span class="social-media__name">instagram reach</span>
        </div>
        <div class="social-media__colum box-three">
          <span class="social-media__line" style="background-color: #205EFF;"></span>
          <span class="social-media__number"><b id="facebook-reach">0</b>+</span>
          <span class="social-media__name">facebook reach</span>
        </div>
      </div>
    </div>

    <div class="social-media-group pt-70">
      <div class="social-media-group__text">
      </div>

      <img src="assets/img/social-media__img.png" alt="" class="social-media-group__img">
    </div>
  </div>
</div>

<section class="notebook-block notebook-block-left pb-70">
  <div class="wrapper">
    <img src="assets/img/notebook-create-campaign.png" alt="" class="notebook-block__img">

    <div class="notebook-block__colum">
      <div class="notebook-block__block colum">
        <h2>{{'landing.platform.notebook.howDoesItWork' | translate}}</h2>
        <span class="notebook-block__text mb-40"><b>{{'landing.platform.notebook.howDoesItWorkText' | translate}}</b></span>
      </div>

      <div class="notebook-block__block colum">
        <span class="notebook-block__title">{{'landing.platform.advantage' | translate}}</span>
        <div class="notebook-block__group">
          <span class="notebook-block__text">{{'landing.platform.notebook.advantageText' | translate}}</span>

          <div class="social-media__colum box-one">
            <span class="social-media__line" style="background-color: #41E2BA;"></span>
            <span class="social-media__number"><b>20 000</b>+</span>
            <span class="social-media__name">Influencers</span>
          </div>
        </div>

        <img src="assets/img/La-piattaforma__bg.jpg" alt="" class="notebook-block__bg">
      </div>
    </div>
  </div>
</section>

<section class="notebook-block notebook-block-right pb-70">
  <div class="wrapper">
    <div class="notebook-block__colum">
      <div class="notebook-block__block colum">
        <h2>{{'landing.platform.choosePostsTitle' | translate}}</h2>
        <span class="notebook-block__text mb-40"><b>{{'landing.platform.choosePostsDescription' | translate}}</b></span>
      </div>

      <div class="notebook-block__block">
        <div class="notebook-block__group colum">
          <span class="notebook-block__title">{{'landing.platform.advantage' | translate}}</span>
          <span class="notebook-block__text">{{'landing.platform.advantagePayDescription' | translate}}</span>
        </div>

        <div class="honor">
          <svg class="honor__svg" width="120" height="120" viewBox="0 0 120 120" fill="currentColor">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M62 75.5556V73.1758C65.967 72.5453 69.3975 70.067 71.5627 66.5508C76.9908 65.7197 80 60.4952 80 53.3333V46.6667C80 44.2121 78.2091 42.2222 76 42.2222H73.4649C72.7733 40.8938 71.4806 40 70 40H50C48.5194 40 47.2267 40.8938 46.5351 42.2222H44C41.7909 42.2222 40 44.2121 40 46.6667V53.3333C40 60.4952 43.0092 65.7197 48.4373 66.5508C50.6025 70.067 54.033 72.5453 58 73.1758V75.5556H56C53.7909 75.5556 52 77.5454 52 80H68C68 77.5454 66.2091 75.5556 64 75.5556H62ZM44 46.6667H46V57.7778C46 58.795 46.0879 59.7894 46.2557 60.7522C44.7806 59.277 44 56.7361 44 53.3333V46.6667ZM74 46.6667V57.7778C74 58.795 73.9121 59.7894 73.7443 60.7522C75.2194 59.277 76 56.7361 76 53.3333V46.6667H74ZM50 57.7777V44.4443H70V57.7777C70 63.9142 65.5228 68.8888 60 68.8888C54.4772 68.8888 50 63.9142 50 57.7777Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6671 79.6059C11.0862 89.6956 12.5324 97.3853 17.5736 102.426C22.6148 107.468 30.3044 108.914 40.3941 107.333C46.4106 115.585 52.8707 120 60 120C67.1293 120 73.5894 115.585 79.6059 107.333C89.6956 108.914 97.3853 107.468 102.426 102.426C107.468 97.3853 108.914 89.6956 107.333 79.6059C115.585 73.5894 120 67.1293 120 60C120 52.8707 115.585 46.4106 107.333 40.3941C108.914 30.3044 107.468 22.6148 102.426 17.5736C97.3853 12.5324 89.6956 11.0862 79.6059 12.6671C73.5894 4.41478 67.1293 0 60 0C52.8707 0 46.4106 4.41478 40.3941 12.6671C30.3044 11.0862 22.6148 12.5324 17.5736 17.5736C12.5324 22.6148 11.0862 30.3044 12.6671 40.3941C4.41478 46.4106 0 52.8707 0 60C0 67.1293 4.41478 73.5894 12.6671 79.6059ZM47.4625 98.3586C46.2322 96.4577 43.9553 95.5146 41.7411 95.9888C33.0955 97.8403 27.7263 97.1513 25.2872 94.7123C22.8482 92.2733 22.1592 86.9041 24.0108 78.2584C24.4849 76.0442 23.5418 73.7674 21.6409 72.537C14.2182 67.7329 10.9089 63.449 10.9089 59.9998C10.9089 56.5505 14.2182 52.2666 21.6409 47.4625C23.5418 46.2322 24.4849 43.9553 24.0108 41.7411C22.1592 33.0955 22.8482 27.7263 25.2872 25.2872C27.7263 22.8482 33.0955 22.1592 41.7411 24.0108C43.9553 24.4849 46.2322 23.5418 47.4625 21.6409C52.2666 14.2182 56.5505 10.9089 59.9998 10.9089C63.449 10.9089 67.7329 14.2182 72.537 21.6409C73.7674 23.5418 76.0442 24.4849 78.2584 24.0108C86.9041 22.1592 92.2733 22.8482 94.7123 25.2872C97.1513 27.7263 97.8403 33.0955 95.9888 41.7411C95.5146 43.9553 96.4577 46.2322 98.3586 47.4625C105.781 52.2666 109.091 56.5505 109.091 59.9998C109.091 63.449 105.781 67.7329 98.3586 72.537C96.4577 73.7674 95.5146 76.0442 95.9888 78.2584C97.8403 86.9041 97.1513 92.2733 94.7123 94.7123C92.2733 97.1513 86.9041 97.8403 78.2584 95.9888C76.0442 95.5146 73.7674 96.4577 72.537 98.3586C67.7329 105.781 63.449 109.091 59.9998 109.091C56.5505 109.091 52.2666 105.781 47.4625 98.3586Z"/>
          </svg>
          <span class="honor__title">100% QUALITY</span>
        </div>

        <img src="assets/img/La-piattaforma__bg.jpg" alt="" class="notebook-block__bg">
      </div>
    </div>

    <img src="assets/img/notebook-view-campaign.png" alt="" class="notebook-block__img">
  </div>
</section>

<section class="scegli-quali notebook-block notebook-block-left pb-100">
  <div class="wrapper">
    <img src="assets/img/scegli-quali.png" alt="" class="notebook-block__img">

    <div class="notebook-block__colum">
      <div class="notebook-block__block colum">
        <h2>{{'landing.platform.chooseWhichContentTitle' | translate}}</h2>
        <span class="notebook-block__text mb-40"><b>{{'landing.platform.chooseWhichContentDescription' | translate}}</b></span>
      </div>

      <div class="notebook-block__block">
        <div class="notebook-block__group colum">
          <span class="notebook-block__title">{{'landing.platform.advantage' | translate}}</span>
          <span class="notebook-block__text">{{'landing.platform.advantageGuaranteedResultsDescription' | translate}}</span>
        </div>

        <div class="honor">
          <svg class="honor__svg" width="120" height="120" viewBox="0 0 120 120" fill="currentColor">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9979 57.999C79.9979 54.6342 77.7372 51.9993 73.9982 51.9993H67.8524C67.8703 51.9502 67.8889 51.8998 67.9081 51.8479C67.9635 51.6979 68.1196 51.2865 68.2715 50.8863L68.2722 50.8843L68.2724 50.8838C68.4071 50.5289 68.5382 50.1832 68.5926 50.0372C68.9729 49.0166 69.2503 48.1942 69.4749 47.3798C69.8142 46.1489 69.9984 45.0399 69.9984 43.9997C69.9984 41.0558 68.1822 39.2274 65.4952 38.445C64.2832 38.0921 63.2283 37.9998 61.9988 38H61.0398L60.4394 38.7477C59.7611 39.5924 58.4289 41.1147 56.893 42.8698C54.0597 46.1074 50.5332 50.1371 49.1402 52.166C48.7787 52.0587 48.3959 52.0011 47.9996 52.0011H43.9998C41.7908 52.0011 40 53.7918 40 56.0009V74C40 76.209 41.7908 77.9998 43.9998 77.9998H47.9996C49.3738 77.9998 50.586 77.3069 51.3061 76.2514C51.9128 76.6437 52.5897 76.9712 53.3254 77.2345C55.01 77.8372 56.6807 78.0222 58.0678 77.9969L71.9983 77.9981C77.5083 77.9981 79.9979 69.8856 79.9979 57.999ZM54.673 73.4682C52.9097 72.8373 52.0059 71.81 51.9994 70.0177V56.0009L51.9994 55.988C52.0007 55.5121 52.0897 55.0897 52.2828 54.6389C52.6323 53.823 55.8031 50.2055 58.8084 46.7767L58.8086 46.7764C60.3596 45.0069 61.8665 43.2877 62.9188 42.0298C63.4216 42.0675 63.8956 42.1448 64.3771 42.285C65.5231 42.6187 65.9987 43.0976 65.9987 43.9995C65.9987 44.6326 65.8706 45.404 65.619 46.3166C65.431 46.9985 65.1876 47.72 64.8446 48.6406C64.7959 48.7712 64.6745 49.0913 64.5454 49.4317C64.388 49.8465 64.2192 50.2914 64.1567 50.4603C63.8967 51.1635 63.7253 51.6812 63.6081 52.1517C63.1099 54.1517 63.6097 55.9989 65.9987 55.9989H73.9983C75.3807 55.9989 75.9982 56.7186 75.9982 57.9988C75.9982 67.5798 74.0286 73.998 71.9984 73.998H57.9991L57.9252 73.9992C57.0499 74.0149 55.8204 73.8788 54.673 73.4682ZM44 74.0001V56.001H47.9998V74.0001H44Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6671 79.6059C11.0862 89.6956 12.5324 97.3853 17.5736 102.426C22.6148 107.468 30.3044 108.914 40.3941 107.333C46.4106 115.585 52.8707 120 60 120C67.1293 120 73.5894 115.585 79.6059 107.333C89.6956 108.914 97.3853 107.468 102.426 102.426C107.468 97.3853 108.914 89.6956 107.333 79.6059C115.585 73.5894 120 67.1293 120 60C120 52.8707 115.585 46.4106 107.333 40.3941C108.914 30.3044 107.468 22.6148 102.426 17.5736C97.3853 12.5324 89.6956 11.0862 79.6059 12.6671C73.5894 4.41478 67.1293 0 60 0C52.8707 0 46.4106 4.41478 40.3941 12.6671C30.3044 11.0862 22.6148 12.5324 17.5736 17.5736C12.5324 22.6148 11.0862 30.3044 12.6671 40.3941C4.41478 46.4106 0 52.8707 0 60C0 67.1293 4.41478 73.5894 12.6671 79.6059ZM47.4625 98.3586C46.2322 96.4577 43.9553 95.5146 41.7411 95.9888C33.0955 97.8403 27.7263 97.1513 25.2872 94.7123C22.8482 92.2733 22.1592 86.9041 24.0108 78.2584C24.4849 76.0442 23.5418 73.7674 21.6409 72.537C14.2182 67.7329 10.9089 63.449 10.9089 59.9998C10.9089 56.5505 14.2182 52.2666 21.6409 47.4625C23.5418 46.2322 24.4849 43.9553 24.0108 41.7411C22.1592 33.0955 22.8482 27.7263 25.2872 25.2872C27.7263 22.8482 33.0955 22.1592 41.7411 24.0108C43.9553 24.4849 46.2322 23.5418 47.4625 21.6409C52.2666 14.2182 56.5505 10.9089 59.9998 10.9089C63.449 10.9089 67.7329 14.2182 72.537 21.6409C73.7674 23.5418 76.0442 24.4849 78.2584 24.0108C86.9041 22.1592 92.2733 22.8482 94.7123 25.2872C97.1513 27.7263 97.8403 33.0955 95.9888 41.7411C95.5146 43.9553 96.4577 46.2322 98.3586 47.4625C105.781 52.2666 109.091 56.5505 109.091 59.9998C109.091 63.449 105.781 67.7329 98.3586 72.537C96.4577 73.7674 95.5146 76.0442 95.9888 78.2584C97.8403 86.9041 97.1513 92.2733 94.7123 94.7123C92.2733 97.1513 86.9041 97.8403 78.2584 95.9888C76.0442 95.5146 73.7674 96.4577 72.537 98.3586C67.7329 105.781 63.449 109.091 59.9998 109.091C56.5505 109.091 52.2666 105.781 47.4625 98.3586Z"/>
          </svg>
          <span class="honor__title">100% GUARANTEE</span>
        </div>

        <img src="assets/img/La-piattaforma__bg.jpg" alt="" class="notebook-block__bg">
      </div>
    </div>
  </div>
</section>
