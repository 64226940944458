<section class="video-box mt-160 mb-50">
  <div class="wrapper">
    <div class="title-group">
      <h2>{{'landing.saas.title' | translate}}</h2>
      <p class="title-krona">{{'landing.saas.videoBlock.blockDescription' | translate}}</p>
    </div>

    <div class="video-box__block">
      <div class="video">
        <video  #video width="100%" height="100%" controls preload="auto" poster='assets/img/poster.png'>
          <source src="{{ linkToVideo }}" type="video/mp4">
        </video>
      </div>

      <div class="video-box__wrapper-small">
        <div class="video-box__group-text">
          <span class="video-box__title">{{'landing.saas.videoBlock.howDoesItWork' | translate}}</span>
          <span class="video-box__text">{{'landing.saas.videoBlock.description' | translate}}</span>

          <button (click)='openDemoDialog()' type="button" class="btn big primary-btn-filled">
            <span class="btn__text">{{'landing.home.buttonFindOutMore' | translate}}</span>
          </button>
        </div>
      </div>

      <img src="assets/img/video-box_bg.jpg" alt="" class="video-box__bg">
    </div>
  </div>
</section>

<section class="saas-text mb-60">
  <div class="wrapper-small">
    <h2>{{'landing.saas.saasBlock.title' | translate}}</h2>
    <span class="saas-text__text"><b>{{'landing.saas.saasBlock.description' | translate}}</b></span>
    <span class="saas-text__text">{{'landing.saas.saasBlock.nextDescription' | translate}}</span>
    <span class="saas-text__text color"><b>{{'landing.saas.saasBlock.boldDescription' | translate}}</b></span>
  </div>
</section>

<section class="perche-sponsorizzare pb-50">
  <div class="wrapper-small">
    <div class="perche-sponsorizzare__colum">
      <div class="perche-sponsorizzare__block">
        <h4>{{'landing.saas.advantagesTitle' | translate}}</h4>
      </div>

      <div class="perche-sponsorizzare__block">
        <ul class="perche-sponsorizzare-list">
          <li class="perche-sponsorizzare-list__item">{{'landing.saas.advantages.0' | translate}}</li>
          <li class="perche-sponsorizzare-list__item">{{'landing.saas.advantages.1' | translate}}</li>
          <li class="perche-sponsorizzare-list__item">{{'landing.saas.advantages.2' | translate}}</li>
          <li class="perche-sponsorizzare-list__item">{{'landing.saas.advantages.3' | translate}}</li>
          <li class="perche-sponsorizzare-list__item">{{'landing.saas.advantages.4' | translate}}</li>
        </ul>

        <img src="assets/img/perche-sponsorizzare-bg.jpg" alt="" class="perche-sponsorizzare__bg">
      </div>
    </div>

    <img src="assets/img/scegli-quali.png" alt="" class="perche-sponsorizzare__img">
  </div>
</section>

<section class="qualche">
  <div class="wrapper-small">
    <h2>{{'landing.saas.examples.title' | translate}}</h2>

    <div class="qualche-block qualche-block-left mb-90">
      <img src="assets/img/qualche-block-3.png" alt="" class="qualche-block__img">

      <div class="qualche-block__colum">
        <h3>{{'landing.saas.examples.items.0.title' | translate}}</h3>
        <div class="qualche-block__group">
          <svg class="qualche-block__quotes" width="70" height="70" viewBox="0 0 70 70" fill="currentColor">
            <path d="M22.4038 32.2627C20.6797 31.7663 18.9557 31.5144 17.2786 31.5144C14.6888 31.5144 12.5279 32.1063 10.8523 32.831C12.4677 26.9172 16.3483 16.7131 24.0787 15.5639C24.7946 15.4575 25.3812 14.9398 25.5766 14.243L27.2661 8.19996C27.4086 7.68891 27.3241 7.14188 27.0326 6.69838C26.7411 6.25488 26.2726 5.95897 25.7476 5.88701C25.1771 5.80918 24.5956 5.76953 24.0192 5.76953C14.7402 5.76953 5.55086 15.4545 1.67319 29.3219C-0.603041 37.4576 -1.27049 49.6891 4.33638 57.3879C7.47391 61.6958 12.0513 63.9963 17.9416 64.2261C17.9659 64.2268 17.9894 64.2276 18.0136 64.2276C25.2814 64.2276 31.7261 59.3329 33.6866 52.3258C34.8577 48.1368 34.3283 43.7437 32.1945 39.9527C30.0835 36.2042 26.6067 33.472 22.4038 32.2627Z"/>
            <path d="M67.8989 39.9534C65.7879 36.2042 62.3112 33.472 58.1082 32.2627C56.3841 31.7663 54.6601 31.5144 52.9837 31.5144C50.394 31.5144 48.2323 32.1063 46.5567 32.831C48.1721 26.9172 52.0527 16.7131 59.7838 15.5639C60.4997 15.4575 61.0857 14.9398 61.2817 14.243L62.9713 8.19996C63.1137 7.68891 63.0293 7.14188 62.7378 6.69838C62.447 6.25488 61.9785 5.95897 61.4528 5.88701C60.883 5.80918 60.3015 5.76953 59.7243 5.76953C50.4454 5.76953 41.256 15.4545 37.3776 29.3219C35.1021 37.4576 34.4347 49.689 40.0423 57.3893C43.1791 61.6965 47.7572 63.9977 53.6468 64.2268C53.671 64.2276 53.6945 64.2283 53.7195 64.2283C60.9865 64.2283 67.4319 59.3337 69.3924 52.3265C70.5621 48.1375 70.032 43.7437 67.8989 39.9534Z"/>
          </svg>
          <span class="qualche-block__text">{{'landing.saas.examples.items.0.description' | translate}}</span>
        </div>
      </div>
      <img src="assets/img/qualche-block__bg.jpg" alt="" class="qualche-block__bg">
    </div>


    <div class="qualche-block qualche-block-right mb-100">
      <div class="qualche-block__colum">
        <h3>{{'landing.saas.examples.items.1.title' | translate}}</h3>
        <div class="qualche-block__group">
          <svg class="qualche-block__quotes" width="70" height="70" viewBox="0 0 70 70" fill="currentColor">
            <path d="M22.4038 32.2627C20.6797 31.7663 18.9557 31.5144 17.2786 31.5144C14.6888 31.5144 12.5279 32.1063 10.8523 32.831C12.4677 26.9172 16.3483 16.7131 24.0787 15.5639C24.7946 15.4575 25.3812 14.9398 25.5766 14.243L27.2661 8.19996C27.4086 7.68891 27.3241 7.14188 27.0326 6.69838C26.7411 6.25488 26.2726 5.95897 25.7476 5.88701C25.1771 5.80918 24.5956 5.76953 24.0192 5.76953C14.7402 5.76953 5.55086 15.4545 1.67319 29.3219C-0.603041 37.4576 -1.27049 49.6891 4.33638 57.3879C7.47391 61.6958 12.0513 63.9963 17.9416 64.2261C17.9659 64.2268 17.9894 64.2276 18.0136 64.2276C25.2814 64.2276 31.7261 59.3329 33.6866 52.3258C34.8577 48.1368 34.3283 43.7437 32.1945 39.9527C30.0835 36.2042 26.6067 33.472 22.4038 32.2627Z"/>
            <path d="M67.8989 39.9534C65.7879 36.2042 62.3112 33.472 58.1082 32.2627C56.3841 31.7663 54.6601 31.5144 52.9837 31.5144C50.394 31.5144 48.2323 32.1063 46.5567 32.831C48.1721 26.9172 52.0527 16.7131 59.7838 15.5639C60.4997 15.4575 61.0857 14.9398 61.2817 14.243L62.9713 8.19996C63.1137 7.68891 63.0293 7.14188 62.7378 6.69838C62.447 6.25488 61.9785 5.95897 61.4528 5.88701C60.883 5.80918 60.3015 5.76953 59.7243 5.76953C50.4454 5.76953 41.256 15.4545 37.3776 29.3219C35.1021 37.4576 34.4347 49.689 40.0423 57.3893C43.1791 61.6965 47.7572 63.9977 53.6468 64.2268C53.671 64.2276 53.6945 64.2283 53.7195 64.2283C60.9865 64.2283 67.4319 59.3337 69.3924 52.3265C70.5621 48.1375 70.032 43.7437 67.8989 39.9534Z"/>
          </svg>
          <span class="qualche-block__text">{{'landing.saas.examples.items.1.description' | translate}}</span>
        </div>
      </div>


      <img src="assets/img/qualche-block-1.jpg" alt="" class="qualche-block__img">
      <img src="assets/img/qualche-block__bg.jpg" alt="" class="qualche-block__bg">
    </div>



    <div class="qualche-block qualche-block-left mb-90">
      <img src="assets/img/qualche-block-2.jpg" alt="" class="qualche-block__img">
      <div class="qualche-block__colum">
        <h3>{{'landing.saas.examples.items.2.title' | translate}}</h3>
        <div class="qualche-block__group">
          <svg class="qualche-block__quotes" width="70" height="70" viewBox="0 0 70 70" fill="currentColor">
            <path d="M22.4038 32.2627C20.6797 31.7663 18.9557 31.5144 17.2786 31.5144C14.6888 31.5144 12.5279 32.1063 10.8523 32.831C12.4677 26.9172 16.3483 16.7131 24.0787 15.5639C24.7946 15.4575 25.3812 14.9398 25.5766 14.243L27.2661 8.19996C27.4086 7.68891 27.3241 7.14188 27.0326 6.69838C26.7411 6.25488 26.2726 5.95897 25.7476 5.88701C25.1771 5.80918 24.5956 5.76953 24.0192 5.76953C14.7402 5.76953 5.55086 15.4545 1.67319 29.3219C-0.603041 37.4576 -1.27049 49.6891 4.33638 57.3879C7.47391 61.6958 12.0513 63.9963 17.9416 64.2261C17.9659 64.2268 17.9894 64.2276 18.0136 64.2276C25.2814 64.2276 31.7261 59.3329 33.6866 52.3258C34.8577 48.1368 34.3283 43.7437 32.1945 39.9527C30.0835 36.2042 26.6067 33.472 22.4038 32.2627Z"/>
            <path d="M67.8989 39.9534C65.7879 36.2042 62.3112 33.472 58.1082 32.2627C56.3841 31.7663 54.6601 31.5144 52.9837 31.5144C50.394 31.5144 48.2323 32.1063 46.5567 32.831C48.1721 26.9172 52.0527 16.7131 59.7838 15.5639C60.4997 15.4575 61.0857 14.9398 61.2817 14.243L62.9713 8.19996C63.1137 7.68891 63.0293 7.14188 62.7378 6.69838C62.447 6.25488 61.9785 5.95897 61.4528 5.88701C60.883 5.80918 60.3015 5.76953 59.7243 5.76953C50.4454 5.76953 41.256 15.4545 37.3776 29.3219C35.1021 37.4576 34.4347 49.689 40.0423 57.3893C43.1791 61.6965 47.7572 63.9977 53.6468 64.2268C53.671 64.2276 53.6945 64.2283 53.7195 64.2283C60.9865 64.2283 67.4319 59.3337 69.3924 52.3265C70.5621 48.1375 70.032 43.7437 67.8989 39.9534Z"/>
          </svg>
          <span class="qualche-block__text">{{'landing.saas.examples.items.2.description' | translate}}</span>
        </div>
      </div>

      <img src="assets/img/qualche-block__bg.jpg" alt="" class="qualche-block__bg">
    </div>



  </div>
</section>
