import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, State, Store } from '@ngrx/store';

import { getCampaigns, selectCampaigns, selectCampaignsByInterests, selectFilteredCampaigns } from '@store/campaigns';
import { Subject, Subscription } from 'rxjs';
import {BrandModel, CampaignModel} from '@app/shared/model';
import { AppState } from '@store/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofType } from '@ngrx/effects';
import { take, takeUntil } from 'rxjs/operators';
import * as campaignDraftActions from '@store/campaign-draft';
import { FormBuilder } from '@angular/forms';
import { CONFIG } from '@config/configuration';
import {selectBrand, selectUserInfo} from '@app/store';
import { PaymentsDialogComponent } from '@app/dialogs/payments-dialog/payments-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as brandActions from '@store/brand';
import { RegistrationDialogComponent } from '@app/dialogs/registration-dialog/registration-dialog.component';
import { DialogService } from '@ngneat/dialog';
import {AccountPlan} from "@app/shared/types/account-plan.enum";
import {ChangePlanDialogComponent} from "@app/dialogs/payments-dialog/change-plan-dialog/change-plan-dialog.component";
import {PaymentsService} from "@app/services/payments.service";

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss'],
})
export class CampaignsComponent implements OnInit, OnDestroy {
  campaignSubscription: Subscription;
  activeCampaigns: CampaignModel[];
  completedCampaigns: CampaignModel[];
  private destroyed$ = new Subject<boolean>();
  private interests: string[] = [];
  private initialCampaigns = [];
  search: any;
  availablePlans: any;
  noFilteredCampaigns = false;
  interestsFilter = false;
  interestsArrays = CONFIG.platform.interests;
  searchForm = this.fb.group({
    search: [''],
  });

  constructor(
    private store: Store<AppState>,
    private state: State<AppState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private actions: Actions,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogBrand: DialogService,
    private paymentsService: PaymentsService,
  ) {}

  ngOnInit(): void {
    // this.fetchPlans();
    this.search = this.searchForm.controls.search;
    this.store.pipe(select(selectUserInfo)).subscribe((user) => {
      return this.store.dispatch(getCampaigns({ user }));
    });
    this.actions
      .pipe(ofType(campaignDraftActions.editCampaignDraftSubmitSuccess), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.subscribeCampaigns();
      });
    this.subscribeCampaigns();

    this.search.valueChanges.subscribe((value: string) => {
      this.store.pipe(select(selectFilteredCampaigns(value)), takeUntil(this.destroyed$)).subscribe((campaigns) => {
        const filteredCampaigns = value ? campaigns : this.initialCampaigns;
        this.updateCampaigns(filteredCampaigns);

        this.noFilteredCampaigns = value && !this.activeCampaigns.length && !this.completedCampaigns.length;
      });
    });
    this.store.dispatch(brandActions.getBrand());
    this.actions.pipe(ofType(brandActions.getBrandSuccess), take(1)).subscribe(({brand}) => {
      if (!brand) {
        const dialogRef = this.dialogBrand.open(
          RegistrationDialogComponent,
          {
            enableClose: false,
            width: '1200px',
          }
        );
      }
      if (brand?.subscription.status === 'pending' && brand?.subscription.plan !== AccountPlan.test) {
        const components = brand?.subscription.active_until.split('-');
        const day = parseInt(components[0], 10);
        const month = parseInt(components[1], 10) - 1; // Subtract 1 from the month because months are zero-based in JavaScript
        const year = parseInt(components[2], 10);
        const specificDate = new Date(year, month, day).toLocaleString().split(',')[0];
        const currentDate = new Date().toLocaleString().split(',')[0];
        if (specificDate > currentDate) {
          return;
        }
        this.dialog.open(PaymentsDialogComponent, {
          data: {
            subscription: brand.subscription
          }
        });
      }
      if (brand?.subscription.status === 'cancelled' && brand?.subscription.plan === AccountPlan.test || brand?.subscription.plan === null) {
        this.paymentsService.getPlans()
        .pipe(take(1))
        .subscribe((res) => {
          if (res?.plan?.length) {
            this.availablePlans = res.plan;
          }
          this.dialog.open(ChangePlanDialogComponent, {
            data: {
              accountPlans: this.availablePlans,
              selectedPlan: brand?.subscription.plan
            }
          }).afterClosed().subscribe((res) => {
            if (!res) {
              return;
            }
            this.paymentsService.createSubscription({
              plan: res.plan,
              currency: res.currency,
              discount_coupon: res.discountCoupon,
              is_extended_options: res.isExtendedOptions,
              is_trial: res.isTest,
              is_corporate: res.isCorporate,
              subscription_sum: res.totalPrice,
              paypal_plan_id: res.paypalPlanId
            }).subscribe(({ subscription }: any) => {
              if (subscription.success === true) {
                this.store.dispatch(brandActions.getBrand());
                this.store.pipe(select(selectBrand), take(1)).subscribe((brand: BrandModel) => {
                  this.dialog.open(PaymentsDialogComponent, {
                    data: {
                      subscription: subscription.subscription_object
                    }
                  });
                });
              }
            });
          });
        });
      }
    });
  }

  fetchPlans(): void {
    this.paymentsService.getPlans().subscribe((res) => {
      if (res?.plan?.length) {
        this.availablePlans = res.plan;
      }
    });
  }

  private subscribeCampaigns(): void {
    this.store.pipe(select(selectCampaigns), takeUntil(this.destroyed$)).subscribe((campaigns) => {
      this.initialCampaigns = campaigns.slice();
      this.updateCampaigns(campaigns);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  changeInterests(event, interest: string): void {
    const checked = event.target.checked;
    if (checked) {
      this.searchForm.reset();
      this.interests.push(interest);
    } else {
      this.interests.splice(this.interests.indexOf(interest), 1);
    }

    this.store
      .pipe(select(selectCampaignsByInterests(this.interests)), takeUntil(this.destroyed$))
      .subscribe((campaigns) => {
        if (!campaigns.length) {
          this.subscribeCampaigns();
          return;
        }
        this.updateCampaigns(campaigns);
      });
  }

  private updateCampaigns(campaigns: CampaignModel[]): void {
    this.activeCampaigns = this.getActiveCampaigns(campaigns);
    this.completedCampaigns = this.getCompletedCampaigns(campaigns);
  }

  getActiveCampaigns(campaigns: CampaignModel[]): CampaignModel[] {
    return (campaigns && campaigns.filter((campaign) => campaign.isLive === true)) || [];
  }

  getCompletedCampaigns(campaigns: CampaignModel[]): CampaignModel[] {
    return (campaigns && campaigns.filter((campaign) => campaign.isLive === false)) || [];
  }

  openCampaign(campaign: CampaignModel): void {
    if (!campaign.reviewed) {
      return;
    }
    this.router.navigate([campaign.id], { relativeTo: this.activatedRoute });
  }
}
