import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '@app/services/common.service';
import { catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
})
export class ContactComponent implements OnInit {
  contactUsOptions: string[];

  contactForm = this.fb.group(
    {
      company: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      acceptRules: ['', [Validators.required, Validators.requiredTrue]],
      surname: ['', [Validators.required]],
      subject: [''],
      message: ['', [Validators.required]],
    },
    {
      updateOn: 'change',
    }
  );

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.contactUsOptions = this.translate.instant('contactUs.options');
  }

  contactUs(): void {
    this.contactForm.markAllAsTouched();
    const successMsg = this.translate.instant('contactUs.successMsg');
    const errorMsg = this.translate.instant('commonMsg.somethingWentWrong');

    const company = this.contactForm.get('company').value;
    const name = this.contactForm.get('name').value;
    const email = this.contactForm.get('email').value;
    const surname = this.contactForm.get('surname').value;
    const subject = this.contactForm.get('subject').value;
    const message = this.contactForm.get('message').value;

    const contact = {
      company,
      name,
      email,
      surname,
      subject,
      message,
    };

    if (!this.contactForm.valid) {
      return;
    }

    this.commonService
      .contactUs(contact)
      .pipe(
        catchError((err) => {
          this.toastr.error(errorMsg);
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.toastr.success(successMsg);
        this.contactForm.reset();
        this.router.navigate(['/']);
      });
  }
  checkFieldError(fieldName): boolean {
    return !!(!this.contactForm.get(fieldName).valid && this.contactForm.get(fieldName).touched);
  }
}
