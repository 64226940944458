
<section class="come-attivare mt-160">
  <div class="wrapper">
    <div class="title-group">
      <h2>Influencer Amplification</h2>
      <p class="title-krona">Want to maximize your visibility to their audience?</p>
    </div>

    <div class="come-attivare__block">

      <div class="come-attivare__box">
        <div class="come-attivare__circle">
          <img src="assets/img/icons/heart.svg" alt="">
        </div>
        <span class="come-attivare__title">Attiva la tua Campagna di Creator Amplification</span>
        <span class="come-attivare__text">Scegli i KPI della tua campagna a Performance (CPC, CPL o CPV)</span>
      </div>

      <div class="come-attivare__box">
        <div class="come-attivare__circle">
          <img src="assets/img/icons/star-cricle.svg" alt="">
        </div>
        <span class="come-attivare__title">Creazione dei post</span>
        <span class="come-attivare__text">La nostra favolosa Community di Creators realizza i post. Ti aiutiamo a scegliere i migliori da approvare</span>
      </div>

      <div class="come-attivare__box">
        <div class="come-attivare__circle">
          <img src="assets/img/icons/lightning.svg" alt="">
        </div>
        <span class="come-attivare__title">Amplificazione ADV</span>
        <span class="come-attivare__text">La nostra potente tecnologia permette ai migliori post di essere sponsorizzati dai Creator stessi sui i propri canali Social. FlyTrendy garantisce i KPI!</span>
      </div>

    </div>
  </div>
</section>

<section class="amplification-stat">
  <div class="wrapper-small">
    <div class="amplification-stat__cont">
      <div class="amplification-stat__group">
        <span class="amplification-stat__title">Skyrocket your creators’ post value with Flytrendy</span>
        <span class="amplification-stat__subtitle">How to amplify influencer<br>marketing with our help</span>
      </div>
      <div class="amplification-stat__img">
        <img src="assets/img/slider/1.png" alt="">
      </div>
      <div class="amplification-stat__box">
        <img src="assets/img/amplification-stat__stat-svg.svg" alt="">
        <img src="assets/img/amplification-stat__stat-svg-2.svg" alt="">
      </div>

      <img src="assets/img/social-media-bg.jpg" alt="" class="social-media__bg">
    </div>
  </div>
</section>

<div class="amplification-text">
  <div class="wrapper-small">
    <p><b>Influencer marketing rose in popularity out of the consumer appetite for authentic content amidst the noise of paid social. There are 2 reasons influencer marketing doesn’t deliver the results that it can:</b><br><br>Decline in organic reach has made it difficult to realize the full potential of influencer collaborations. There’s a ton of great content being created and shared, but it’s not reaching the right audience(s) the same way it used to.<br><br>Organic Instagram posts don’t have a clickable call to action button. Interested consumers can’t easily click through to learn more. Instagram stories allow for a swipe up, but have an even more limited shelf life of 24 hours.</p>
  </div>
</div>

<section class="video-box mb-100 mt-100">
  <div class="wrapper">
    <div class="video-box__block">
      <div class="video">
        <video width="100%" height="100%" controls preload="auto">
          <source src="https://flytrendy.com/videos/Influencer-Marketing.mp4" type="video/mp4">
        </video>
      </div>

      <div class="video-box__group-text video-box__group-app">
        <span class="video-box__title">Boost your metrics with us<br>Start campaign for free today</span>

        <button type="button" class="btn big primary-btn-filled">
          <span class="btn__text">start for free</span>
        </button>
      </div>

      <img src="assets/img/video-box_bg.jpg" alt="" class="video-box__bg">
    </div>
  </div>
</section>

<div class="amplification-text">
  <div class="wrapper-small">
    <p>Savvy social media marketers have caught on to the fact that there is a better way to drive results than simply “boosting” influencer posts and repurposing assets as user-generated content on social media platforms. Combining influencers with paid media by including dark posts as part of an influencer strategy unlocks a new channel to reach consumers.<br><br>Opening up this new capability for influencer marketing campaigns requires advertising access. Here’s where influencer whitelisting and influencer dark posts enter the picture. When you combine the influencer storytelling (content) that consumers relate to, with the ability control targeting and ads directly through influencer handles (identity) and even target their audiences, the value of influencer paid media is realized. This process unlocks a whole new acquisition channel.</p>
  </div>
</div>

<section class="contact-us mt-100 mb-100">
  <div class="wrapper">
    <div class="title-group">
      <h2>Get in touch with us</h2>
      <p class="title-krona">Fill out our form to get in touch with us<br>for more info about our software or to schedule a demo</p>
    </div>

    <form action="#" method="post" class="form form--w-740">
      <div class="form__group--two">
        <div class="form__box">
          <span class="form__title">Company name</span>
          <input type="text" class="form__input">
          <span class="form__error">This field cannot be empty</span>
        </div>
        <div class="form__box">
          <span class="form__title">Your Name</span>
          <input type="text" class="form__input">
          <span class="form__error">This field cannot be empty</span>
        </div>
      </div>

      <div class="form__group--two">
        <div class="form__box">
          <span class="form__title">Email</span>
          <input type="text" class="form__input">
          <span class="form__error">This field cannot be empty</span>
        </div>
        <div class="form__box">
          <span class="form__title">Your surName</span>
          <input type="text" class="form__input">
          <span class="form__error">This field cannot be empty</span>
        </div>
      </div>

      <div class="form__group--three">
        <div class="form__box">
          <span class="form__title">city</span>
          <input type="text" class="form__input">
          <span class="form__error">This field cannot be empty</span>
        </div>
      </div>

      <div class="form__group--three">
        <div class="form__box">
          <span class="form__title">address</span>
          <input type="text" class="form__input">
          <span class="form__error">This field cannot be empty</span>
        </div>
      </div>

      <div class="form__group--three">
        <div class="form__box">
          <span class="form__title">phone number</span>
          <input type="text" class="form__input">
          <span class="form__error">This field cannot be empty</span>
        </div>
      </div>

      <div class="form__box" style="margin-bottom: 10px;">
        <span class="form__title">what are you looking for?</span>
        <textarea class="form__textarea"></textarea>
      </div>

      <div class="form__checked">
        <label class="checked">
          <input type="checkbox" class="checked__input">
          <span class="checked__square"></span>
          <span class="checked__text">Accept <a href="terms-conditions-influencer.html" target="_blank">T&C’s</a> and <a href="privacy-policy.html" target="_blank">Privacy Policy</a></span>
        </label>
      </div>

      <button type="button" class="btn big primary-btn-filled">
        <span class="btn__text">get free demo now</span>
      </button>
    </form>
  </div>
</section>
