import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-amplification',
  templateUrl: './amplification.component.html',
  styleUrls: ['./amplification.component.scss']
})
export class AmplificationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
