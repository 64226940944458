import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HighchartsChartModule} from 'highcharts-angular';
import { ColumnChartComponent } from '@app/common/chart/column-chart/column-chart.component';

@NgModule({
  declarations: [ColumnChartComponent],
  imports: [
    CommonModule,
    HighchartsChartModule
  ],
  exports: [ColumnChartComponent]
})
export class ColumnChartModule { }
