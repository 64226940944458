import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { Subject } from 'rxjs';
import { getUser, selectBrand, selectBrandPlan, selectUserInfo, updateBrandInfo, updateUserInfo } from '@app/store';
import { BrandModel, UserModel } from '@app/shared/model';
import { FormBuilder, Validators } from '@angular/forms';
import {take, takeUntil} from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import * as userActions from '@app/store/user';
import * as brandActions from '@app/store/brand';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@ngneat/dialog';
import { ChangePasswordComponent } from '@app/dialogs/change-password/change-password.component';
import { logOutUser } from '@store/app.actions';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { PaymentsService } from '@app/services/payments.service';
import { ChangePlanDialogComponent } from '@app/dialogs/payments-dialog/change-plan-dialog/change-plan-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PaymentsDialogComponent } from '@app/dialogs/payments-dialog/payments-dialog.component';
import {AccountPlan} from "@app/shared/types/account-plan.enum";
import {RegistrationDialogComponent} from "@app/dialogs/registration-dialog/registration-dialog.component";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  editPersonalInfo = false;
  editBillingInfo = false;
  brand: BrandModel;
  userId: number;
  brandId: number;
  isAgency: boolean;
  isInvoice: boolean;
  isTestPlan: boolean;
  plan: any;
  availablePlans: any;

  personalDataForm = this.fb.group(
    {
      firstName: [null],
      lastName: [null],
      company: [null, [Validators.required]],
      email: [null, [Validators.required]],
      phone: [null],
      mobile: [null],
    },
    {
      updateOn: 'change',
    }
  );

  billingDataForm = this.fb.group(
    {
      billingCompany: [null],
      streetAddress: [null, [Validators.required]],
      locationCity: [null, [Validators.required]],
      locationCountry: [null, [Validators.required]],
      locationState: [null],
      locationPostCode: [null, [Validators.required]],
      currency: [null, [Validators.required]],
    },
    {
      updateOn: 'change',
    }
  );

  taxDataForm = this.fb.group(
    {
      iva: [null, [Validators.required]],
    },
    {
      updateOn: 'change',
    }
  );

  subscriptionDataForm = this.fb.group(
    {
      plan: [null, [Validators.required]],
      subscribed_at: [null, [Validators.required]],
      status: [null, [Validators.required]],
      active_until: [null, [Validators.required]]
    },
    {
      updateOn: 'change',
    }
  );

  ccEmailForm = this.fb.group(
    {
      ccEmail: [null, [Validators.required]],
    },
    {
      updateOn: 'change',
    }
  );

  firstNameInput = this.personalDataForm.get('firstName');
  lastNameInput = this.personalDataForm.get('lastName');
  companyInput = this.personalDataForm.get('company');
  emailInput = this.personalDataForm.get('email');
  phoneInput = this.personalDataForm.get('phone');
  mobileInput = this.personalDataForm.get('mobile');

  billingCompanyInput = this.billingDataForm.get('billingCompany');
  streetAddressInput = this.billingDataForm.get('streetAddress');
  locationCityInput = this.billingDataForm.get('locationCity');
  locationCountryInput = this.billingDataForm.get('locationCountry');
  locationStateInput = this.billingDataForm.get('locationState');
  locationPostCodeInput = this.billingDataForm.get('locationPostCode');
  ivaCodeInput = this.taxDataForm.get('iva');
  ccEmailInput = this.ccEmailForm.get('ccEmail');
  editTaxInfo: boolean;

  public isCorporateSubs: boolean;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private actions: Actions,
    private toastr: ToastrService,
    private translate: TranslateService,
    private dialog: DialogService,
    private matDialog: MatDialog,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private paymentsService: PaymentsService,
  ) {}

  checkUpdateSubscription(brand): void {
    if (this.activeRoute.snapshot.paramMap.get('subscription') === 'update' && brand.subscription) {
      this.changeSubscription(brand.subscription.id);
    }
  }

  ngOnInit(): void {
    this.personalDataForm.controls.email.disable();

    this.selectUser();
    this.selectBrand();
    this.selectPlan();
    this.fetchPlans();
    this.userInfoUpdateSuccess();
    this.userInfoUpdateFail();
  }

  fetchPlans(): void {
    this.paymentsService.getPlans().subscribe((res) => {
      if (res?.plan?.length) {
        this.availablePlans = res.plan;
      }
    });
  }

  userInfoUpdateSuccess(): void {
    const userInfoUpdateSuccessMsg = this.translate.instant('myProfile.userInfoUpdateSuccessMsg');

    this.actions
      .pipe(ofType(userActions.updateUserInfoSuccess, brandActions.updateBrandInfoSuccess), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.toastr.success(userInfoUpdateSuccessMsg);
        this.resetEditState();
      });
  }

  userInfoUpdateFail(): void {
    let userInfoUpdateFailMsg = this.translate.instant('myProfile.userInfoUpdateFailMsg');

    this.actions
    .pipe(ofType(userActions.updateUserInfoFail, brandActions.updateBrandInfoFail), takeUntil(this.destroyed$))
    .subscribe(({ isTooOftenRequest }) => {
      if (isTooOftenRequest) {
        userInfoUpdateFailMsg = this.translate.instant('myProfile.brandInfoUpdateFail429Msg');
      }
        this.toastr.error(userInfoUpdateFailMsg);
        this.resetEditState();
      });
  }

  selectUser(): void {
    this.store.pipe(select(selectUserInfo), takeUntil(this.destroyed$)).subscribe((user: UserModel) => {
      if (user) {
        this.personalDataForm.patchValue(user, { emitEvent: false });
        this.userId = user.id;
        this.isInvoice = user.invoice;
      }
    });
  }

  resetEditState(): void {
    this.editPersonalInfo = false;
    this.editBillingInfo = false;
  }

  selectPlan(): void {
    this.store.pipe(select(selectBrandPlan), takeUntil(this.destroyed$)).subscribe((plan) => {
      this.plan = plan;
      this.isTestPlan = String(plan).toLowerCase().includes('test');
    });
  }

  selectBrand(): void {
    this.store.pipe(select(selectBrand), takeUntil(this.destroyed$)).subscribe((brand: BrandModel) => {
      this.personalDataForm.patchValue(brand, { emitEvent: false });
      this.billingDataForm.patchValue(brand, { emitEvent: false });
      this.taxDataForm.patchValue(brand, { emitEvent: false });
      this.ccEmailForm.patchValue(brand, { emitEvent: false });
      this.subscriptionDataForm.patchValue({
        ...brand.subscription,
        plan: String(brand?.subscription?.plan).split('_')[0],
      }, { emitEvent: false });
      this.brandId = brand.id;
      this.isAgency = brand.isAgency;
      this.brand = brand;
      this.isCorporateSubs = brand.subscription?.plan.toString().toLowerCase().includes('corporate');
      this.checkUpdateSubscription(brand);
    });
  }

  changeInvoice(): void {
    this.isInvoice = !this.isInvoice;
    const user = { invoice: this.isInvoice, id: this.userId };
    this.store.dispatch(updateUserInfo({ user }));
  }

  changePassword(): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      closeButton: true,
    });
  }

  changeAgency(): void {
    this.isAgency = !this.isAgency;
    const brand = { isAgency: this.isAgency, id: this.brandId };
    this.store.dispatch(updateBrandInfo({ brand }));
  }

  saveBrandInfo(): void {
    const { userId } = this;
    const user = {
      firstName: this.firstNameInput.value,
      lastName: this.lastNameInput.value,
      id: userId,
      invoice: this.isInvoice,
    } as Partial<UserModel>;

    const brand = {
      id: this.brandId,
      phone: this.phoneInput.value,
      mobile: this.mobileInput.value,
      company: this.companyInput.value,
      billing_company: this.billingCompanyInput.value,
      location_city: this.locationCityInput.value,
      location_state: this.locationStateInput.value,
      location_postcode: this.locationPostCodeInput.value,
      street_address: this.streetAddressInput.value,
      location_country: this.locationCountryInput.value,
      cc_email: this.ccEmailInput.value,
      currency: this.brand.currency,
      is_agency: this.isAgency,
      iva: this.ivaCodeInput.value,
    };

    if (this.personalDataForm.valid && this.billingDataForm.valid) {
      this.store.dispatch(updateUserInfo({ user }));
      this.store.dispatch(updateBrandInfo({ brand }));
    }
  }
  cancelEditInfoChanges(): void {
    this.editPersonalInfo = false;
    this.editBillingInfo = false;
    this.selectUser();
    this.selectBrand();
  }

  logout(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.authService.logout();
    this.store.dispatch(logOutUser());
    this.router.navigate(['/']);
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  cancelSubscription(id) {
    this.dialog.confirm('Are you sure to cancel this subscription?').afterClosed$.subscribe((res) => {
      if (res) {
        this.paymentsService.cancelSubscription(id).subscribe(() => {
          this.store.dispatch(brandActions.getBrand());
          this.toastr.success('Your subscription has been canceled');
        });
      }
    });
  }

  chooseYourPlan() {
    if (!this.availablePlans?.length) {
      return;
    }

    this.matDialog.open(ChangePlanDialogComponent, {
      data: {
        accountPlans: this.availablePlans,
        selectedPlan: this.brand?.subscription?.plan,
      }
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.paymentsService.createSubscription({
        plan: res.plan,
        currency: res.currency,
        discount_coupon: res.discountCoupon,
        is_extended_options: res.isExtendedOptions,
        is_trial: res.isTest,
        is_corporate: res.isCorporate,
        subscription_sum: res.totalPrice,
        paypal_plan_id: res.paypalPlanId
      }).subscribe(({subscription}: any) => {
        if (subscription.success === true) {
          this.store.dispatch(brandActions.getBrand());
          this.store.pipe(select(selectBrand), take(1)).subscribe((brand: BrandModel) => {
            this.matDialog.open(PaymentsDialogComponent, {
              data: {
                subscription: subscription.subscription_object
              }
            });
          });
        }
      });
    })
  }

  changeSubscription(id: number) {
    if (!this.availablePlans?.length) {
      return;
    }

    this.matDialog.open(ChangePlanDialogComponent, {
      data: {
        accountPlans: this.availablePlans,
        selectedPlan: this.plan,
      }
    }).afterClosed().subscribe((res) => {
      if (res) {
        const newPlan = {
          plan: res.plan,
          currency: res.currency,
          discount_coupon: res.discountCoupon,
          is_extended_options: res.isExtendedOptions,
          is_trial: res.isTest,
          is_corporate: res.isCorporate,
          subscription_sum: res.totalPrice,
          paypal_plan_id: res.paypalPlanId
        };

        if (this.brand.subscription.plan === AccountPlan.test) {
          this.paymentsService.createSubscription(newPlan).subscribe(({subscription}: any) => {
            if (subscription.success === true) {
              this.store.dispatch(brandActions.getBrand());
              this.store.pipe(select(selectBrand), take(1)).subscribe((brand: BrandModel) => {
                this.matDialog.open(PaymentsDialogComponent, {
                  data: {
                    subscription: subscription.subscription_object
                  }
                });
              });
            } else {
              this.toastr.error(subscription.comments);
            }
          });
        } else {
          const host = location.protocol + '//' + location.host;
          const url = host + '/redirect';
          this.paymentsService.changeActivePlan(id, newPlan, url, host, res?.promocode).subscribe((response: any) => {
            if (response.subscription.redirect) {
              window.open(response.subscription.redirect, '_blank');
            } else {
              this.store.dispatch(getUser());
              this.toastr.success('Your subscription has been changed');
            }
          });
        }
      }
    });
  }

  buyNewSubscription() {
    if (this.brand?.subscription.status !== 'active') {
      this.matDialog.open(PaymentsDialogComponent, {
        data: {
          subscription: this.brand.subscription
        }
      });
    }
  }
}
