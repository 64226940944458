import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '@app/services/helper.service';

enum videoLinks {
  'it' = 'https://s3.eu-central-1.amazonaws.com/flytrendy.com/videos/Performance+Influencer.mp4',
  'en' = 'https://flytrendy-assets.s3.eu-central-1.amazonaws.com/static/videos/SpotFlyTrendyBrandEN.mp4\n',
}

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit {
  linkToVideo = videoLinks[this.translate.currentLang];
  @ViewChild('video') video: ElementRef<HTMLVideoElement>;

  constructor(
    private title: Title,
    private meta: Meta,
    private helperService: HelperService,
    private translate: TranslateService
  ) {}

  getVideoLink(): void {
    const lang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;
    this.linkToVideo = videoLinks[lang];
    this.video?.nativeElement.load();
  }

  ngOnInit(): void {
    this.title.setTitle('Campagna Influencer Marketing a Performance');

    this.meta.updateTag({
      name: 'keyword',
      content:
        'influencer marketing, martech, creator, cpc garantit, cpv garantiti, cpl garantiti, kpi garantiti, ugc, data driven, mirata, targetizzata, creazione contenuti, attivazione campagna.',
    });

    this.meta.updateTag({
      name: 'description',
      content: 'UGC e influencer marketing campaign con risultati garantiti',
    });

    this.getVideoLink();
    this.translate.onLangChange.subscribe(() => this.getVideoLink());
  }

  openDemoDialog(): void {
    this.helperService.openDemoDialog();
  }
}
