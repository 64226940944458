import {Injectable, OnDestroy} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable, Subject, throwError} from 'rxjs';
import {catchError, map, take, takeUntil} from "rxjs/operators";
import {DialogService} from "@ngneat/dialog";
import {Router} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {selectBrand, selectBrandPlan, selectSubscription, selectUserInfo} from "@app/store";
import {AppState} from "@store/app.state";
import {BrandModel} from "@app/shared/model";
import {PaymentsDialogComponent} from "@app/dialogs/payments-dialog/payments-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor, OnDestroy {
  destroyed$ = new Subject<boolean>();
  isShownError = false;

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private store: Store<AppState>,
    private dialog: DialogService,
    private matDialog: MatDialog,
  ) {}

  subscription

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.pipe(select(selectSubscription), takeUntil(this.destroyed$)).subscribe((subscription: any) => {
          if (subscription && !this.isShownError) {
            this.subscription = subscription;
            this.isShownError = true;
            if (error?.status === 403 && this.subscription?.status === 'failed') {
              if (error.error?.campaign?.detail === "Not allowed to get the resource without the subscription") {
                const LeavePageChangeText = 'Your payment has been failed. Please, try again';
                const dialogRef = this.dialog.success(LeavePageChangeText, {closeButton: false});
                dialogRef.afterClosed$.subscribe((res) => {
                  this.isShownError = false;
                  this.store.pipe(select(selectBrand), take(1)).subscribe((brand: BrandModel) => {
                    this.matDialog.open(PaymentsDialogComponent, {
                      data: {
                        subscription: this.subscription
                      }
                    });
                  });
                })
              }
            }
          }
        });
        return throwError(error);
      }))
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
