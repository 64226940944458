import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CampaignModel } from '@app/shared/model';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import * as campaignDraftActions from '@store/campaign-draft';
import { getCampaignDraft, selectCampaignDraft } from '@store/campaign-draft';
import { Subscription } from 'rxjs';
import { selectBrandCurrency } from '@app/store';

@Component({
  selector: 'app-reward-step',
  templateUrl: './reward-step.component.html',
  styleUrls: ['./reward-step.component.scss']
})
export class RewardStepComponent implements OnInit {
  currency: string;
  rewardFormSubscription: Subscription;
  stepTitle = 'reward';
  reward = {
    cash: null,
    voucher_fixed: 0,
    voucher_percentage: 0
  };

  rewardForm = this.fb.group(
    {
      voucherFixed: [ '', [ Validators.max(999), Validators.min(1) ] ],
      voucherPercentage: [ '', [ Validators.max(100), Validators.min(1) ] ]
    },
    {
      updateOn: 'change',
    }
  );

  submitted = false;
  voucherFixedInput = this.rewardForm.get('voucherFixed');
  voucherPercentageInput = this.rewardForm.get('voucherPercentage');

  constructor(private fb: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.dispatch(getCampaignDraft());
    this.store.pipe(select(selectBrandCurrency)).subscribe((currency: string) => {
      this.currency = currency?.toLowerCase() || 'eur';
    });
    this.rewardFormSubscription = this.store
      .pipe(select(selectCampaignDraft))
      .subscribe((campaignDraft: CampaignModel) => {
        if (!campaignDraft){
          return
        }
        this.rewardForm.patchValue({voucherFixed: campaignDraft.reward.voucher_fixed});
        this.rewardForm.patchValue({voucherPercentage: campaignDraft.reward.voucher_percentage});
      //  this.rewardForm.patchValue(campaignDraft, { emitEvent: false });
        this.reward.cash = campaignDraft.reward.cash;
        this.reward.voucher_fixed = campaignDraft.reward.voucher_fixed;
        this.reward.voucher_percentage = campaignDraft.reward.voucher_percentage;
      });

  }

  setRewardOption(reward: string): void {
    Object.keys(this.reward).forEach(item => {
      item == reward ? this.reward[item] = true : this.reward[item] = false
    })

    if (reward === 'cash') {
      this.rewardForm.reset();
    }
  }

  enableNextStep(): boolean {
    return (this.reward.cash ||
        this.reward.voucher_fixed && this.voucherFixedInput.value ||
        this.reward.voucher_percentage && this.voucherPercentageInput.value)
      && this.rewardForm.valid;
  }

  goToNextStep():void {
    this.submitted = true;

    const campaign = {
      //brandCompanyName: this.brandNameInput.value,
      reward: {
        cash: this.reward.cash,
        voucher_percentage: (this.reward.voucher_percentage) ? this.voucherPercentageInput.value : null,
        voucher_fixed: (this.reward.voucher_fixed) ? this.voucherFixedInput.value : null
      }
    } as Partial<CampaignModel>;


    this.store.dispatch(campaignDraftActions.patchDraftCampaign({ campaign }));
    this.router.navigate(['campaign'], { relativeTo: this.activatedRoute });
  }

  onRewardFieldChanged(inputName: string) {
    Object.keys(this.rewardForm.controls).forEach(item => {
      if(item != inputName) {
        this.rewardForm.get(item).reset();
      }
    })
  }
}
