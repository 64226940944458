import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RewardStepComponent } from './reward-step.component';
import {ReactiveFormsModule} from "@angular/forms";
import {ImageCropperModule} from "ngx-image-cropper";
import {CampaignWizardModule} from "@app/campaign/campaign-wizard/campaign-wizard.module";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslateModule} from "@ngx-translate/core";
import {FeatureFlagDirectiveModule} from "@app/shared/directives/feature-flag-directive/feature-flag-directive.module";
import {MatInputModule} from "@angular/material/input";



@NgModule({
  declarations: [
    RewardStepComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ImageCropperModule,
    CampaignWizardModule,
    MatTooltipModule,
    TranslateModule,
    FeatureFlagDirectiveModule,
    MatInputModule
  ],
  exports: [RewardStepComponent]
})
export class RewardStepModule { }
