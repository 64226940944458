<app-campaign-wizard-step [stepTitle]="stepTitle" [enabledNextStep]='enableNextStep()' (nextStepClicked)="goToNextStep()" (previousStepClicked)='goToPreviousStep()'>

<!--    <div class="new-campaign-influencers new-campaign-form__wrapper mt-50 mb-40">-->
<!--      <div class='new-campaign-form__colum'>-->
<!--        <div class='form__box'>-->
<!--          <div class='form__title-group'>-->
<!--            <div *ngIf='selectedSocialPlatform.length >= 2 && this.socialPlatform?.platformLinkedin' class='select-type-group'>-->
<!--              <mat-tab-group>-->
<!--                <mat-tab [label]="'newCampaign.socialMediaInterests' | translate">-->
<!--                  <app-select-interests-->
<!--                    [(selectInterests)]='interests'-->
<!--                    [isTabs]='true'-->
<!--                  ></app-select-interests>-->
<!--                </mat-tab>-->
<!--                <mat-tab [label]="'newCampaign.linkedinOnlyInterests' | translate">-->
<!--                  <app-select-interests-->
<!--                    [linkedinInterests]='linkedinInterests'-->
<!--                    [(selectInterests)]='selectedLinkedinInterests'-->
<!--                    [isTabs]='true'-->
<!--                  ></app-select-interests>-->
<!--                </mat-tab>-->
<!--              </mat-tab-group>-->
<!--            </div>-->

<!--            <div *ngIf='selectedSocialPlatform.length <= 1 && this.socialPlatform?.platformLinkedin'>-->
<!--              <span class="form__title">{{'newCampaign.review.interests' | translate }}</span>-->
<!--              <app-select-interests-->
<!--                [linkedinInterests]='linkedinInterests'-->
<!--                [(selectInterests)]='selectedLinkedinInterests'-->
<!--              ></app-select-interests>-->
<!--            </div>-->

<!--            <div *ngIf='!this.socialPlatform?.platformLinkedin'>-->
<!--              <span class="form__title">{{'newCampaign.review.interests' | translate }}</span>-->
<!--              <app-select-interests-->
<!--                [(selectInterests)]='interests'-->
<!--              ></app-select-interests>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--        <div class="new-campaign-form__colum">-->
<!--          <div class="form__box">-->
<!--            <span class="form__title">{{'newCampaign.review.age' | translate }}</span>-->
<!--            <div class="new-campaign-form__slider">-->
<!--                <ngx-slider [(value)]="age.ageMin" [(highValue)]="age.ageMax" [options]="options"></ngx-slider>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="form__box">-->
<!--            <span class="form__title">{{'newCampaign.review.gender' | translate }}</span>-->

<!--            <div class="new-campaign-form__btn-group">-->
<!--              <button (click)="gender.female = !gender.female" type="button" class="btn small campaign-secondary-btn" [ngClass]='{"active" : gender.female}'>-->
<!--                <span class="btn__text">female</span>-->
<!--              </button>-->

<!--              <button (click)="gender.male = !gender.male" type="button" class="btn small campaign-secondary-btn" [ngClass]='{"active" : gender.male}'>-->
<!--                <span class="btn__text">male</span>-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="form__box">-->
<!--            <span class="form__title">{{'newCampaign.review.demographic' | translate }}</span>-->

<!--            <div class="new-campaign-form__btn-group">-->

<!--              <button (click)='country.active = !country.active' [ngClass]='{"active" : country.active}' type="button" class="btn small campaign-secondary-btn" *ngFor='let country of targetRegion'>-->
<!--                <span class="btn__text">{{country.country_name}}</span>-->
<!--              </button>-->

<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!-- -->

  <div class="builder__cont step-4">
    <div class="builder__head">
      <h2 class="h1">Influencers</h2>
    </div>
    <div class="builder__main">
      <div class="builder__col">
        <div class="form__box">
                                    <span class="form__label">
                                        Interests
<!--                                        <span class="info tooltip-wrap">-->
<!--                                            <img src="assets/img/sc_icons/message-question.svg" alt="info">-->
<!--                                            <span class="tooltip right">Voucher: Fixed discount amount</span>-->
<!--                                        </span>-->
                                    </span>
          <div class="btn-group">
            <button type="button" class="btn btn--br btn--secondary active">All social media</button>
<!--            <button type="button" class="btn btn&#45;&#45;br btn&#45;&#45;secondary">Linked In only</button>-->
          </div>

          <app-select-interests [(selectInterests)]='interests' [isTabs]='true'></app-select-interests>

          <div class="linked-only builder__grid-two" style="display: none">
            <div class="interest-tile">
              <span class="interest-tile__text">Agriculture - Livestock - Fishing</span>
            </div>
            <div class="interest-tile">
              <span class="interest-tile__text">Industry - Crafts - Construction - Chemistry - Energy</span>
            </div>
            <div class="interest-tile">
              <span class="interest-tile__text">Processing of food products</span>
            </div>
            <div class="interest-tile active">
              <span class="interest-tile__text">Services - Consulting</span>
            </div>
            <div class="interest-tile">
              <span class="interest-tile__text">Banking - Insurance - Financial</span>
            </div>
            <div class="interest-tile">
              <span class="interest-tile__text">Wholesale import export trade</span>
            </div>
            <div class="interest-tile">
              <span class="interest-tile__text">Medical - Health - Healthcare</span>
            </div>
            <div class="interest-tile">
              <span class="interest-tile__text">Informatics - Software - Internet</span>
            </div>
            <div class="interest-tile active">
              <span class="interest-tile__text">Catering - Hotels - Tourism</span>
            </div>
            <div class="interest-tile">
              <span class="interest-tile__text">Communication Agencies - Marketing - Digital - social media</span>
            </div>
            <div class="interest-tile">
              <span class="interest-tile__text">Onlus, NGO, Non Profit</span>
            </div>
            <div class="interest-tile">
              <span class="interest-tile__text">Public Administration - Education</span>
            </div>
          </div>
        </div>
      </div>
      <div class="builder__col">
        <div class="form__box">
          <span class="form__label">{{'newCampaign.review.age' | translate }}</span>
          <div>
            <ngx-slider [(value)]="age.ageMin" [(highValue)]="age.ageMax" [options]="options"></ngx-slider>
          </div>
        </div>
        <div class="form__box gender">
          <span class="form__label">{{'newCampaign.review.gender' | translate }}</span>
          <div class="gender__box">
            <label class="toggle">
              <input [checked]="gender.male" type="checkbox" name="male" (change)="changeGender('male')">
              <span class="toggle__box"></span>
              <span class="toggle__text">Male</span>
            </label>
            <label class="toggle">
              <input [checked]="gender.female" type="checkbox" name="female" (change)="changeGender('female')">
              <span class="toggle__box"></span>
              <span class="toggle__text">Female</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

</app-campaign-wizard-step>
