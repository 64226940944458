<div class="modal modal-payment modal--padding">
  <div class="modal-reg__header">
    <div class="modal-reg__caption-box">
      <span class="h1">{{ 'paypalDialog.title' | translate }}</span>
    </div>
    <span class="h2"
          [innerHTML]="('paypalDialog.title'| translate) + ' <b>' + currencySymbol + (brand$ | async)?.funds + '</b>'"></span>
  </div>
  <form [formGroup]="paymentForm" >
  <div class="modal-reg__main">
    <div class="modal__wrapper">
      <div class="form__box" [ngClass]='{"error": paymentForm.get("amount").invalid && paymentForm.get("amount").touched}'>
      <span class="form__title" style="margin-bottom: 30px">{{ 'paypalDialog.inputLabel' | translate }}</span>
        <input formControlName='amount' type="number" class="form__input">
        <span class="modal-wallet__text" [innerHTML]="'paypalDialog.inputHint' | translate:{fee: currencySymbol + getFeeAmount(), sum: currencySymbol + 50}"></span>
<!--        <span class="modal-wallet__text" [innerHTML]="'">-->
<!--        </span>-->
      </div>
              <div class="modal-payment__group">
        <!--        <span class="modal-payment__title">You will be charged</span>-->
<!--        <span class="modal-payment__price"><b>€400</b>/month </span>-->
      </div>
      <div class="modal-payment__group-btn">

        <div class='pay-pal-wrapper'>
          <div id="paypal-button"></div>
        </div>
          <!--        <button type="button" class="pay-pal-btn">-->
<!--          <img src="assets/img/sc_icons/paypal.svg" alt="" class="pay-pal-btn__svg">-->
<!--          <span class="pay-pal-btn__text">Checkout</span>-->
<!--        </button>-->
<!--        <button type="button" class="btn btn&#45;&#45;bg btn&#45;&#45;secondary">Pay with credit card</button>-->
      </div>
    </div>
  </div>
  </form>
</div>
