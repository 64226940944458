<section class="insights-top mt-160">
  <div class="wrapper-small">
    <div class="title-group center">
      <h2 class="analytics-title">{{'campaignOverview.analyticsPage.title' | translate}}</h2>
      <p class="title-krona">{{'campaignOverview.analyticsPage.realTime' | translate}}</p>
    </div>
  </div>

  <div class="wrapper">
    <div class="insights-bar-top">
      <div class="insights-bar-top__group">
      </div>

      <div class="insights-bar-top__group">
      </div>
    </div>

    <div class="insights-bar">
      <div class="insights-bar__colum" *ngIf='!hideAnalytics'>
        <span class="insights-bar__title">{{ 'campaignOverview.analyticsPage.numCreators' | translate }} <!-- <br>score <b>®</b> --> </span>
        <span class="insights-bar__number">{{analytics?.numberCreators || 0}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <div class="insights-bar__colum" *ngIf='!hideAnalytics'>
        <span class="insights-bar__title">{{ 'campaignOverview.analyticsPage.numContents' | translate }}</span>
        <span class="insights-bar__number">{{analytics?.numberContents || 0}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <div class="insights-bar__colum" *ngIf='!hideAnalytics'>
        <span class="insights-bar__title">{{ 'campaignOverview.analyticsPage.potentialReach' | translate }} <!-- <br>score <b>®</b> --> </span>
        <span class="insights-bar__number">{{analytics?.potentialReachTotal || 0}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <div class="insights-bar__colum" *ngIf='!hideAnalytics'>
        <span class="insights-bar__title">{{ 'campaignOverview.analyticsPage.totalEngagement' | translate }}</span>
        <span class="insights-bar__number">{{analytics?.totalEngagement || 0}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <img src="assets/img/insights-bar-bg.png" alt="" class="insights-bar__bg">
    </div>

  </div>
</section>

<section class="audience mt-50">
  <div class="wrapper">

    <div style='width: 100%; position: relative;'>
      <h3 class="insights-h3">{{'campaignOverview.analyticsPage.totalContent' | translate}}</h3>
      <canvas baseChart
              [datasets]="totalContentData"
              [options]="contentOptions"
              [colors]="lineChartColors"
              [chartType]="barChartType"
              [labels]="totalContentLabel">
      </canvas>
      <div *ngIf="isEmptyTotalContentData" class="analytics__empty-canvas">
        <section class="warning-section">
          <mat-icon class="warning-section__icon">error_outline</mat-icon>
          <span class="warning-section__text">{{ 'campaignOverview.analyticsPage.emptyDataMsg' | translate }}</span>
        </section>
      </div>
    </div>

    <div style='width: 100%; margin-top: 50px; position: relative;'>
      <h3 class="insights-h3">{{'campaignOverview.analyticsPage.totalEngagement' | translate}}</h3>
      <canvas baseChart
              [datasets]="totalEngagementsData"
              [options]="contentOptions"
              [colors]="lineChartColors"
              [chartType]="barChartType"
              [labels]="totalEngagementLabel">
      </canvas>
      <div *ngIf="isEmptyEngagementsData" class="analytics__empty-canvas">
        <section class="warning-section">
          <mat-icon class="warning-section__icon">error_outline</mat-icon>
          <span class="warning-section__text">{{ 'campaignOverview.analyticsPage.emptyDataMsg' | translate }}</span>
        </section>
      </div>
    </div>

    <div style='width: 100%; margin-top: 50px; position: relative;'>
      <h3 class="insights-h3">{{'campaignOverview.analyticsPage.totalReachImpressions' | translate}}</h3>
      <canvas baseChart
              [datasets]="totalTrueReachData"
              [options]="contentOptions"
              [colors]="lineChartColors"
              [chartType]="barChartType"
              [labels]="totalTrueReachLabel">
      </canvas>
      <div *ngIf="isEmptyTrueReachData" class="analytics__empty-canvas">
        <section class="warning-section">
          <mat-icon class="warning-section__icon">error_outline</mat-icon>
          <span class="warning-section__text">{{ 'campaignOverview.analyticsPage.emptyDataMsg' | translate }}</span>
        </section>
      </div>
    </div>
  </div>
</section>
