import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONFIG } from '@config/configuration';
import { PlanDto, SubscriptionDto } from '@app/shared/dto';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(private http: HttpClient) {}

  getPlans(query?: any): Observable<{ plan: PlanDto[] }> {
    let params = new HttpParams();
    if (query?.fee) {
      params = params.append('is_extended_options', query.fee);
    }
    if (query?.promocode) {
      params = params.append('discount_coupon', query.promocode);
    }
    if (query?.isTrial) {
      params = params.append('is_trial', query.isTrial);
    }
    if (query?.currency) {
      params = params.append('currency', query.currency);
    }
    return this.http.get<any>(CONFIG.baseUrl + 'payments/plans', { params });
  }

  createSubscription(plan: SubscriptionDto) {
    return this.http.post(CONFIG.baseUrl + `payments/subscriptions`, plan);
  }

  // completeSubscription(subscription_id) {
  //   console.log('completeSubscription');
  //   return this.http.post(CONFIG.baseUrl + 'payments/subscriptions/complete', { subscription_id });
  // }

  cancelSubscription(subscriptionId: number) {
    return this.http.delete(CONFIG.baseUrl + `payments/subscriptions/${subscriptionId}`);
  }

  changeActivePlan(subscriptionId: number, newPlan: any, onApproveUrl: string, onCancelUrl: string, promocode?: string) {
    const body = promocode ? { plan: newPlan, promocode } : { plan: newPlan };
    return this.http.put(CONFIG.baseUrl + `payments/subscriptions/${subscriptionId}?on_approve=${onApproveUrl}&on_cancel=${onCancelUrl}`, body);
  }

  completeUpdatePlan() {
    return this.http.post(CONFIG.baseUrl + 'payments/subscriptions/complete-update', {})
  }
}
