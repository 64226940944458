<main>
  <section class="hero">
    <div class="wrapper">
      <div class="hero__col">
        <h1 class="wow fadeInUp">{{ 'landing.home.heroMainTitle' | translate }}</h1>
        <button (click)="openDemoDialog()" type="button" class="btn btn--bg btn--primary wow fadeInUp" data-wow-delay=".1s">{{ 'landing.home.buttonFindOutMore' | translate }}</button>
      </div>
      <div class="hero__img">
        <img src="assets/images/hero-girls.png" alt="hero image">
      </div>
    </div>
  </section>

  <section class="what-sc">
    <div class="wrapper-large">
      <div class="what-sc__wrap">
        <span class="h1 wow fadeInUp" data-wow-offset="100">{{ 'landing.home.videoBlock.groupTitle' | translate }}</span>
        <span class="h2 wow fadeInUp" data-wow-offset="100" data-wow-delay=".1s">{{ 'landing.home.videoBlock.groupDescription' | translate }}</span>
      </div>
      <div class="what-sc__video wow fadeIn" data-wow-offset="150">
        <video width="100%" height="100%" controls preload="auto">
          <source src="assets/video/SocialCollabzEN.mp4" type="video/mp4">
        </video>
      </div>
      <div class="what-sc__wrap">
        <span class="what-sc__title wow fadeInUp" data-wow-offset="100">{{ 'landing.home.revenueStreams.title' | translate }}</span>
        <span class="what-sc__text wow fadeInUp" data-wow-offset="100" data-wow-delay=".1s" [innerHTML]="'landing.home.revenueStreams.description' | translate"></span>
        <button type="button" class="btn btn--bg btn--primary wow fadeInUp" data-wow-offset="50" data-wow-delay=".15s">{{ 'landing.home.buttonFindOutMore' | translate }}</button>
      </div>
    </div>
  </section>

  <section class="ambassadors">
    <div class="wrapper">
      <div class="ambassadors__wrap">
        <h2 class="h1 wow fadeInUp" data-wow-offset="100" [innerHTML]="'landing.home.brandAmbassadors.title' | translate"></h2>
        <span class="h2 wow fadeInUp" data-wow-offset="100" data-wow-delay=".1s">
          {{ 'landing.home.brandAmbassadors.subtitle' | translate }}
        </span>
      </div>
      <div class="ambassadors__grid">
        <div class="ambassadors__box wow fadeInUp" data-wow-offset="100">
          <div class="ambassadors__circle">
            <img src="assets/icons/like-tag.svg" alt="icon">
          </div>
          <span class="ambassadors__text" [innerHTML]="'landing.home.brandAmbassadors.growAdvocacy' | translate"></span>
        </div>
        <div class="ambassadors__box wow fadeInUp" data-wow-offset="100" data-wow-delay=".15s">
          <div class="ambassadors__circle">
            <img src="assets/icons/money-send.svg" alt="icon">
          </div>
          <span class="ambassadors__text" [innerHTML]="'landing.home.brandAmbassadors.driveSales' | translate"></span>
        </div>
        <div class="ambassadors__box wow fadeInUp" data-wow-offset="100" data-wow-delay=".3s">
          <div class="ambassadors__circle">
            <img src="assets/icons/lovely.svg" alt="icon">
          </div>
          <span class="ambassadors__text" [innerHTML]="'landing.home.brandAmbassadors.increaseLoyalty' | translate"></span>
        </div>
        <div class="ambassadors__box wow fadeInUp" data-wow-offset="100" data-wow-delay=".45s">
          <div class="ambassadors__circle">
            <img src="assets/icons/people-3.svg" alt="icon">
          </div>
          <span class="ambassadors__text" [innerHTML]="'landing.home.brandAmbassadors.buildCommunities' | translate"></span>
        </div>
        <div class="ambassadors__box wow fadeInUp" data-wow-offset="100" data-wow-delay=".6s">
          <div class="ambassadors__circle">
            <img src="assets/icons/home-trend-down.svg" alt="icon">
          </div>
          <span class="ambassadors__text" [innerHTML]="'landing.home.brandAmbassadors.lowerCosts' | translate"></span>
        </div>
      </div>
      <button type="button" class="btn btn--bg btn--primary wow fadeInUp" (click)="openDemoDialog()" data-wow-offset="50">
        {{ 'landing.home.brandAmbassadors.bookDemo' | translate }}
      </button>
    </div>
  </section>

  <section class="banner">
    <div class="wrapper-large">
      <div class="banner__img">
        <img src="assets/images/banner-bg.png" alt="banner image">
      </div>
      <div class="banner__col">
        <h2 class="wow fadeInUp" data-wow-offset="100">{{ 'landing.home.trustRealCustomers' | translate }}</h2>
        <button (click)="openDemoDialog()" type="button" class="btn btn--bg btn--primary wow fadeInUp" data-wow-offset="100" data-wow-delay=".1s">
          {{ 'landing.home.readMore' | translate }}
        </button>
      </div>
    </div>
  </section>

  <section class="customers">
    <div class="wrapper-large">
      <h1 class="wow fadeInUp" data-wow-offset="100">{{ 'landing.home.customersMarketers.title' | translate }}</h1>
    </div>
    <div class="wrapper">
      <span class="h1 wow fadeInUp" data-wow-offset="100">{{ 'landing.home.customersMarketers.subtitle' | translate }}</span>
      <span class="h2 wow fadeInUp" data-wow-offset="100" data-wow-delay=".1s" [innerHTML]="'landing.home.customersMarketers.description' | translate">
      </span>
      <div id="customers" class="customers__progress wow fadeInUp" data-wow-offset="150">
        <div class="progress-circle progress-circle--one">
          <span class="progress-circle__number"><b id="progress-1">0</b>%</span>
          <span class="progress-circle__text" [innerHTML]="'landing.home.rejectInfluencers' | translate"></span>
          <svg class="progress-circle__line" width="342" height="342" viewBox="0 0 342 342" stroke="#4BD5CA" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="170.669" cy="171.169" r="153.169" stroke-width="35"/>
          </svg>
        </div>
        <div class="progress-circle progress-circle--two">
          <span class="progress-circle__number"><b id="progress-2">0</b>%</span>
          <span class="progress-circle__text" [innerHTML]="'landing.home.improveTrust' | translate"></span>
          <svg class="progress-circle__line" width="342" height="342" viewBox="0 0 342 342" stroke="#4BD5CA" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="170.669" cy="171.169" r="153.169" stroke-width="35"/>
          </svg>
        </div>
        <div class="progress-circle progress-circle--three">
          <span class="progress-circle__number"><b id="progress-3">0</b>%</span>
          <span class="progress-circle__text" [innerHTML]="'landing.home.wantContentCreation' | translate"></span>
          <svg class="progress-circle__line" width="342" height="342" viewBox="0 0 342 342" stroke="#4BD5CA" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="170.669" cy="171.169" r="153.169" stroke-width="35"/>
          </svg>
        </div>
      </div>
    </div>
  </section>
</main>
