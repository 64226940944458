<div class="modal-reg__header">
  <div class="modal-reg__caption-box">
    <span class="h1">{{ 'regDialog.brandInfo.title' | translate }}</span>
    <span class="modal-reg__step-text">{{ 'regDialog.step' | translate }} 2</span>
  </div>
  <span class="h2">{{ 'regDialog.brandInfo.subtitle' | translate }}</span>
</div>
<div class="modal-reg__main">
  <div class="modal__wrapper" [formGroup]="formGroup">
    <div class="modal-reg__input-group">
      <div class="form__box"
           [class.error]="formGroup.get('firstName').invalid && formGroup.get('firstName').touched">
        <span class="form__label">{{ 'regDialog.brandInfo.name' | translate }}</span>
        <input
          type="text"
          class="form__input"
          formControlName="firstName"
        >
        <span class="form__valid">{{ 'regDialog.brandInfo.wrongFormat' | translate }}</span>
      </div>
      <div
        class="form__box"
        [class.error]="formGroup.get('lastName').invalid && formGroup.get('lastName').touched">
        <span class="form__label">{{ 'regDialog.brandInfo.surname' | translate }}</span>
        <input
          type="text"
          class="form__input"
          formControlName="lastName"
        >
        <span class="form__valid">{{ 'regDialog.brandInfo.wrongFormat' | translate }}</span>
      </div>
    </div>
    <div class="modal-reg__input-group">
      <div
        class="form__box">
        <span class="form__label">{{ 'regDialog.brandInfo.currency' | translate }}</span>
        <div class="select__container" (click)="toggleDropDown()">
          <div
            class="form__select"
          >
          <span class="select__value"
                [innerHTML]="getCurrencySymbol(formGroup.get('currency').value) + ' ' + formGroup.get('currency').value"></span>
            <div class="select__drop_down" *ngIf="isDropDownVisible">
              <div
                class="form__select_option currencyItem"
                [class.selected]="formGroup.get('currency').value === 'EUR'"
                (click)="setCurrency('EUR', $event)"
              >
                &nbsp;&#8364; EUR {{formGroup.get('currency').value === 'EUR' ? '✓ ' : ''}}
                <img alt="EUR" src="assets/img/flags/flag-eu.png" width="25" height="25">
              </div>
              <div
                class="form__select_option currencyItem"
                [class.selected]="formGroup.get('currency').value === 'USD'"
                (click)="setCurrency('USD', $event)"

              >
                &nbsp;&#36; USD {{formGroup.get('currency').value === 'USD' ? '✓ ' : ''}}
                <img alt="USD" src="assets/img/flags/flag-us.png" width="25" height="25">
              </div>
              <div
                class="form__select_option currencyItem"
                [class.selected]="formGroup.get('currency').value === 'GBP'"
                (click)="setCurrency('GBP', $event)"
              >
                <span>&#65505;GBP {{formGroup.get('currency').value === 'GBP' ? '✓ ' : ''}}</span>
                <img alt="GBP" src="assets/img/flags/flag-gb.png" width="25" height="25">
              </div>
              <div
                class="form__select_option currencyItem"
                [class.selected]="formGroup.get('currency').value === 'CHF'"
                (click)="setCurrency('CHF', $event)"
              >
                &nbsp;&#8355; CHF {{formGroup.get('currency').value === 'CHF' ? '✓ ' : ''}}
                <img alt="CHF" src="assets/img/flags/flag-ch.png" width="25" height="25">
              </div>
              <div
                class="form__select_option currencyItem"
                [class.selected]="formGroup.get('currency').value === 'CAD'"
                (click)="setCurrency('CAD', $event)"
              >
                &nbsp;&#36; CAD {{formGroup.get('currency').value === 'CAD' ? '✓ ' : ''}}
                <img alt="CAD" src="assets/img/flags/flag-ca.png" width="25" height="25">
              </div>
            </div>
            <span class="drop-down-arrow"></span>
          </div>
        </div>
        <span class="form__valid">{{ 'regDialog.brandInfo.wrongFormat' | translate }}</span>
      </div>
      <div
        class="form__box"
        [class.error]="formGroup.get('country').invalid && formGroup.get('country').touched">
        <span class="form__label">{{ 'regDialog.brandInfo.country' | translate }}</span>

        <mat-select-country appearance="standard"
                            (change)="onCountryChange()"
                            [formControl]="country"
                            autocomplete="no"
                            style="border: 1px solid #4BD5CA; height: 55px; padding-bottom: 10px"
                            (onCountrySelected)="onCountrySelected($event)">
        </mat-select-country>


        <span class="form__valid">{{ 'regDialog.brandInfo.required' | translate }}</span>
      </div>
<!--      <div-->
<!--        class="form__box"-->
<!--        [class.error]="formGroup.get('promoCode').invalid && formGroup.get('promoCode').touched"-->
<!--        [class.disabled]="isUserExists || isRequestsOngoing"-->
<!--      >-->
<!--        <span class="form__label">Promo Code</span>-->
<!--        <input-->
<!--          type="text"-->
<!--          class="form__input"-->
<!--          formControlName="promoCode"-->
<!--          [readOnly]="isUserExists"-->
<!--        >-->
<!--        <span class="form__valid">Promo Code is not valid</span>-->
<!--      </div>-->
    </div>
    <div class="modal-reg__row">
      <div class="modal-reg__col">
        <div
          class="form__box"
          [class.error]="formGroup.get('email').invalid && formGroup.get('email').touched || error?.user?.email">
          <span class="form__label">{{ 'regDialog.brandInfo.email' | translate }}</span>
          <input
            type="email"
            class="form__input"
            formControlName="email"
          >
          <span class="form__valid" *ngIf="!error?.user?.email">{{ 'regDialog.brandInfo.wrongFormat' | translate }}</span>
          <span class="form__valid" *ngIf="error?.user?.email">{{error?.user?.email[0]}}</span>
        </div>
        <div class="form__box"
             [class.error]="formGroup.get('password').invalid && formGroup.get('password').touched">
          <span class="form__label">{{ 'regDialog.brandInfo.password' | translate }}</span>
          <input
            type="password"
            class="form__input"
            formControlName="password"
            autocomplete="new-password"
          >
          <span class="form__valid">{{ 'regDialog.brandInfo.wrongFormat' | translate }}</span>
        </div>
        <div class="form__box"
             [class.error]="formGroup.get('confirmPassword').invalid && formGroup.get('confirmPassword').touched">
          <span class="form__label">{{ 'regDialog.brandInfo.confirmPassword' | translate }}</span>
          <input
            type="password"
            class="form__input"
            formControlName="confirmPassword">
          <span *ngIf="!formGroup.get('confirmPassword')?.errors?.notEquivalent" class="form__valid">{{ 'regDialog.brandInfo.wrongFormat' | translate }}</span>
          <span *ngIf="formGroup.get('confirmPassword')?.errors?.notEquivalent" class="form__valid">{{ 'regDialog.brandInfo.passwordMismatch' | translate }}</span>
        </div>
      </div>
      <div class="modal-reg__col">
        <div
          class="form__box"
          [class.error]="formGroup.get('city').invalid && formGroup.get('city').touched">
          <span class="form__label">{{ 'regDialog.brandInfo.city' | translate }}</span>
          <input type="text" class="form__input" formControlName="city">
          <span class="form__valid">{{ 'regDialog.brandInfo.wrongFormat' | translate }}</span>
        </div>
<!--        <div class="modal-reg__input-group">-->
          <div
            class="form__box small-box"
            [class.error]="formGroup.get('postCode').invalid && formGroup.get('postCode').touched">
            <span class="form__label">{{ 'regDialog.brandInfo.postcode' | translate }}</span>
            <input type="text" class="form__input" formControlName="postCode">
            <span class="form__valid">{{ 'regDialog.brandInfo.wrongFormat' | translate }}</span>
          </div>
<!--        </div>-->
        <div
          class="form__box"
          [class.error]="formGroup.get('phone').invalid && formGroup.get('phone').touched">
          <span class="form__label">{{ 'regDialog.brandInfo.phone' | translate }}</span>
          <div class="phone_tel" style="display: flex">
            <input type="text" class="form__input" disabled [value]="phoneCode" style="width: 80px">
            <input type="tel" class="form__input" formControlName="phone" style="margin-left: 10px">
            <span class="form__valid">{{ 'regDialog.brandInfo.wrongFormat' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <label class="checked">
      <input type="checkbox" formControlName="agree">
      <span class="checked__circle"></span>
      <span class="checked__text">{{ 'regDialog.brandInfo.agrees' | translate }}
        <a routerLink="terms-brand"
           class="link"
           target="_blank">
          {{ "newCampaign.submitStep.termsConditions" | translate }}</a>
        {{ "newCampaign.submitStep.and" | translate }}
        <a routerLink="privacy-policy" target="_blank" class="link">{{ "newCampaign.submitStep.privacyPolicy" | translate }}</a></span>
    </label>
  </div>

<!--  <div *ngIf="loading" class="progress-spinner absolute">-->
<!--    <mat-spinner diameter='70'></mat-spinner>-->
<!--  </div>-->
</div>
