import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppState} from "@store/app.state";
import {select, Store} from "@ngrx/store";
import {catchError, takeUntil} from "rxjs/operators";
import {BrandModel} from "@app/shared/model";
import {selectBrand} from "@app/store";
import {Subject, throwError} from "rxjs";
import {Clipboard} from '@angular/cdk/clipboard';
import {CommonService} from "@app/services/common.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import { FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-invite-influencer',
  templateUrl: './invite-influencer.component.html',
  styleUrls: ['./invite-influencer.component.scss']
})
export class InviteInfluencerComponent implements OnInit, OnDestroy {

  brandCode: string;
  destroyed$ = new Subject<boolean>();
  public emailsControl = new FormControl('', [ Validators.required, this.emailListValidator ]);

  constructor(private store: Store<AppState>,
              private clipboard: Clipboard,
              private commonService: CommonService,
              private toastr: ToastrService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.store.pipe(select(selectBrand), takeUntil(this.destroyed$)).subscribe((brand: BrandModel) => {
      this.brandCode = brand.brandCode;
    });
  }

  private emailListValidator(control: FormControl): { [key: string]: any } | null {
    const emailList = control.value ? control.value.trim() : '';

    if (!emailList) {
      return { empty: true };
    }

    const emailRegex = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/;
    const emails = emailList.split(',').map(email => email.trim());

    for (const email of emails) {
      if (!emailRegex.test(email)) {
        return { invalidFormat: true };
      }
    }

    return null;
  }

  copyBrandCode() {
    this.clipboard.copy(this.brandCode);
    this.toastr.success("The code copied to clipboard");
  }

  inviteInfluencer(){
    const emails = this.emailsControl.value;
    const errorMsg = this.translate.instant('commonMsg.somethingWentWrong');
    const successMsg = 'The influencers have been invited!'
    this.commonService
      .inviteInfluencer(emails)
      .pipe(
        catchError((err) => {
          this.toastr.error(errorMsg);
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.toastr.success(successMsg);
        this.emailsControl.reset();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
