import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'socialcollabz.com', // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: 'bottom-left',
  palette: {
    popup: {
      background: '#ffffff',
    },
    button: {
      background: '#000000',
      text: '#fff',
    },
  },
  content: {
    message:
      'We and some selected partners use cookies or similar technologies as specified in the cookie policy. You can consent to the use of these technologies by closing this information, continuing to browse this page, interacting with a link or button outside this information or continuing to navigate in any other way.',
    dismiss: 'Dismiss',
    allow: 'Allow all',
    deny: 'Deny',
    link: 'Learn more',
    href: 'https://socialcollabz.com/cookie-policy',
    policy: 'Cookie Policy',
  },
  theme: 'classic',
  type: 'opt-out',
};
