export const getCurrencySymbol = (currency: string) => {
  if (currency === 'EUR') {
    return '&#8364;';
  } else if (currency === 'USD') {
    return '&#36;';
  } else if (currency === 'GBP') {
    return '&#65505;';
  } else if (currency === 'CHF') {
    return '&#8355;';
  } else if (currency === 'CAD') {
    return '&#36;';
  }
  return '';
};
