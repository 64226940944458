import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { DialogService } from '@ngneat/dialog';
import { LoginDialogComponent } from '@app/landing/dialogs/login/login-dialog/login-dialog.component';
import { select, Store } from '@ngrx/store';
import { resetPostsSelection, selectIsLoaded } from '@app/store';
import { AppState } from '@store/app.state';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { CONFIG } from '@config/configuration';
import {logOutUser} from "@store/app.actions";
import {RequestDemoComponent} from "@app/dialogs/request-demo/request-demo.component";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  public selectedLanguage;
  isLoggedIn = false;
  socialLinks = CONFIG.platform.socialLinks;
  isFeedbackPage = false;

  constructor(
    private authService: AuthService,
    private dialog: DialogService,
    private store: Store<AppState>,
    private router: Router,
    private location: Location,
    private translate: TranslateService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}
  openedMenu = false;
  ngOnInit(): void {
    this.isLoggedIn = !!this.authService.isLoggedIn;

    this.store.pipe(select(selectIsLoaded)).subscribe((user) => {
      this.isLoggedIn = !!user;
    });

    this.router.events.subscribe(() => {
      this.isFeedbackPage = !!this.location.path().includes('services/feedback');
    });
    this.setLanguage();
  }

  openLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      closeButton: true,
    });
  }

  logout(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.authService.logout();
    this.store.dispatch(logOutUser());
    this.router.navigate(['/']);
  }

  setLanguage(): void {
    const { defaultLanguage } = CONFIG.platform;
    const userLanguage = localStorage.getItem('language');

    if (userLanguage) {
      this.translate.use(userLanguage);
      this.selectedLanguage = userLanguage;
    } else {
      this.translate.setDefaultLang(defaultLanguage);
      this.selectedLanguage = defaultLanguage;
    }
  }
  toggleMenu(close?: boolean): void {
    this.openedMenu = close === false ? false : !this.openedMenu;
    const element = this.elementRef.nativeElement.querySelectorAll('.overlay-menu');
    const display = this.openedMenu ? 'block' : 'none';
    this.renderer.setStyle(element[0], 'display', display);
  }

  openDemoDialog() {
    this.dialog.open(RequestDemoComponent)
  }
}
