<section class="insights-top mt-160">
  <div class="wrapper-small">
    <div class="title-group">
      <h2>{{'communityInsights.title' | translate}}</h2>
      <p class="title-krona">{{'communityInsights.description' | translate}}</p>
    </div>
  </div>
</section>

<section class="audience mt-50 community-insights">
  <div class="wrapper">

    <div *ngIf='isloading; then spinner else charts'></div>

    <ng-template #spinner>
      <div class="progress-spinner">
        <mat-spinner diameter='70'></mat-spinner>
      </div>
    </ng-template>

    <ng-template #charts>
      <div class="audience__colum" *ngIf='gender.length'>
        <h3 class="insights-h3">{{'communityInsights.gender' | translate }}</h3>
        <app-pie-chart [data]='gender' [colors]='genderColors'></app-pie-chart>
      </div>

      <div class="audience__colum" *ngIf='age.length' >
        <h3 class="insights-h3">{{'newCampaign.review.age' | translate }}</h3>
        <app-pie-chart [data]='age' [colors]='ageColors'></app-pie-chart>
      </div>

      <div class="audience-fullwidth" *ngIf='regions.length'>
        <h3 class="insights-h3">{{'communityInsights.regions' | translate }}</h3>
        <app-column-chart [data]='regions' [colorStart]='colorCharts[0]' [colorEnd]='colorCharts[1]'></app-column-chart>
      </div>

      <div class="audience-fullwidth" *ngIf='followers.length'>
        <h3 class="insights-h3">{{'communityInsights.influencerTotalFolowers' | translate }}</h3>
        <app-column-chart [data]='followers' [colorStart]='colorCharts[0]'
                          [colorEnd]='colorCharts[1]'></app-column-chart>
      </div>

      <div class="audience-fullwidth" *ngIf='interests.length'>
        <h3 class="insights-h3">{{'newCampaign.review.interests' | translate }}</h3>
        <app-column-chart [data]='interests' [colorStart]='colorCharts[0]'
                          [colorEnd]='colorCharts[1]'></app-column-chart>
      </div>
    </ng-template>
  </div>
</section>
