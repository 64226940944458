import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PaymentsService } from '@app/services/payments.service';
import { SubscriptionModel } from '@app/shared/model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChangePlanDialogComponent } from '@app/dialogs/payments-dialog/change-plan-dialog/change-plan-dialog.component';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import * as brandActions from '@store/brand';
import { CONFIG } from '@config/configuration';
import { getCurrencySymbol } from '@app/shared/helpers/helpers';
import { TokenService } from '@app/services/token.service';
import * as PlanDescriptionEnum from "@app/dialogs/payments-dialog/plan-descriptions";

declare let paypal: any;

@Component({
  selector: 'app-payments-dialog',
  templateUrl: './payments-dialog.component.html',
  styleUrls: ['./payments-dialog.component.scss'],
})
export class PaymentsDialogComponent implements OnInit {
  accessToken: string;
  accountPlan: any[];
  currencySymbol: string;
  planDescription = PlanDescriptionEnum;
  plans: any;
  selectedPlan: any;
  subscription: any;
  isPaymentOngoing = false;
  public loading = false;

  public subscriptionSum: number;
  public paypalPlanId: string;

  constructor(
    private toastr: ToastrService,
    public ref: MatDialogRef<PaymentsDialogComponent>,
    private paymentsService: PaymentsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private store: Store<AppState>,
    private actions: Actions,
    private tokenService: TokenService,
) {
  }

  ngOnInit(): void {
    this.loading = true;

    this.accessToken = this.tokenService?.getToken();
    this.subscription = this.data?.subscription;
    this.setSubscriptionData(this.subscription);
    this.paymentsService.getPlans().subscribe((res) => {
      this.plans = res;
      if (this.plans?.plan?.length) {
        this.accountPlan = this.plans?.plan.reduce((acc, plan, index) => {
          acc[plan.slug] = {
            ...plan,
            index,
          };
          return acc;
        }, {});
        this.currencySymbol = getCurrencySymbol(this.accountPlan[this.subscription?.plan]?.currency);
      }
      if (!this.subscription?.plan) {
        setTimeout(this.openChangePlanDialog.bind(this));
      }
      this.setPlans(this.subscription);
      this.loading = false;
    });

    if (!window['paypal']) {
      const PAYPAL_CLIENT_ID = CONFIG.payPalClientID;
      this.loadExternalScript(
        `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&vault=true&intent=subscription&disable-funding=credit`
      ).then(() => {
        this.initPayPalButton();
      });
    } else {
      this.initPayPalButton();
    }
  }

  setPlans(subscription) {
    this.selectedPlan = this.plans.plan.find((plan) => {
      return plan.slug === subscription.plan;
    });
  }

  setSubscriptionData(subscription): void {
    this.subscriptionSum = subscription.subscription_sum;
    this.paypalPlanId = subscription.paypal_plan_id;
  }

  initPayPalButton(): void {
    paypal
      .Buttons({
        style: {
          layout: 'vertical',
          shape: 'pill',
          label: 'paypal',
          fundingicons: false,
        },
        createSubscription: (data, actions) => {
          return actions.subscription.create({
            'plan_id': this.paypalPlanId,
          });
        },
        onApprove: (data, actions) => {
          // this.paymentsService.completeSubscription(data.subscriptionID).subscribe((res) => {
          //   this.toastr.success('Your subscription has been successfully paid');
          //   this.store.dispatch(brandActions.getBrand());
          //   this.ref.close();
          // });
          this.isPaymentOngoing = true;
          this.ref.disableClose = true;
          let attempts = 0;
          const completePayment = () => {
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append('Authorization', `Bearer ${this.accessToken}`);
            const raw = JSON.stringify({
              subscription_id: data.subscriptionID.toString()
            });
            const requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
            };
            fetch(`${CONFIG.baseUrl}payments/subscriptions/complete`, requestOptions)
              .then(response => response.json())
              .then(result => {
                if (result?.subscription?.success) {
                  this.toastr.success('Your subscription has been successfully paid');
                } else if (result?.subscription?.success === false) {
                  attempts++;
                  if (attempts === 20) {
                    this.toastr.error('Something went wrong');
                  } else {
                    return setTimeout(() => {
                      completePayment();
                    }, 5000);
                  }
                }
                this.isPaymentOngoing = false;
                this.store.dispatch(brandActions.getBrand());
                this.ref.disableClose = false;
                this.ref.close();
                return result;
              })
              .catch(error => {
                this.toastr.error('Something went wrong');
              });
          };
          setTimeout(() => {
            completePayment();
          }, 5000);
        }
      })
      .render('#paypal-button');
  }

  private loadExternalScript(scriptUrl: string): any {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

  openChangePlanDialog() {
    this.dialog.open(ChangePlanDialogComponent, {
      data: {
        accountPlans: this.plans?.plan || [],
        selectedPlan: this.subscription.plan,
      }
    }).afterClosed().subscribe((res) => {
      if (res?.plan) {
        const host = location.protocol + '//' + location.host;
        const url = host + '/redirect';

        const newPlan = {
          plan: res.plan,
          currency: res.currency,
          discount_coupon: res.discountCoupon,
          is_extended_options: res.isExtendedOptions,
          is_trial: res.isTest,
          is_corporate: res.isCorporate,
          subscription_sum: res.totalPrice,
          paypal_plan_id: res.paypalPlanId
        };

        if (this.subscription.id) {
          this.paymentsService.changeActivePlan(this.subscription.id, newPlan, url, host, res?.promocode).subscribe(() => {
              this.store.dispatch(brandActions.getBrand());
              this.actions.pipe(ofType(brandActions.getBrandSuccess), take(1)).subscribe(({ brand }) => {
                this.subscription = brand.subscription;
                this.setPlans(brand.subscription);
                this.setSubscriptionData(brand.subscription);
                if (res?.promocode) {
                  this.ref.close();
                  this.toastr.success('Your subscription has been successfully activated');
                }
              });
            }
          );
        } else {
          this.paymentsService.createSubscription(newPlan).subscribe(() => {
            this.store.dispatch(brandActions.getBrand());
            this.actions.pipe(ofType(brandActions.getBrandSuccess), take(1)).subscribe(({ brand }) => {
              this.subscription = brand.subscription;
              this.setPlans(brand.subscription);
              this.setSubscriptionData(brand.subscription);
              if (res?.promocode) {
                this.ref.close();
                this.toastr.success('Your subscription has been successfully activated');
              }
            });
          });
        }
      }
    });
  }
}
