import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { ForgotPasswordModule } from '@app/landing/dialogs/forgot-password/forgot-password.module';
import { FreeDemoModule } from '@app/landing/dialogs/free-demo/free-demo.module';
import { ActivateInfluencerModule } from '@app/landing/activate-influencer/activate-influencer.module';
import { TranslateModule } from '@ngx-translate/core';
import { LoginDialogModule } from '@app/landing/dialogs/login/login-dialog/login-dialog.module';
import { HomeModule } from '@app/landing/home/home.module';
import { AboutComponent } from './about/about.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    RouterModule,
    ForgotPasswordModule,

    ActivateInfluencerModule,
    LoginDialogModule,
    TranslateModule,
    HomeModule
  ],
  declarations: [
    AboutComponent
  ]
})
export class LandingModule {}
