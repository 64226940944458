import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { selectCampaignDraft, selectUserInfo } from '@app/store';
import { takeUntil } from 'rxjs/operators';
import { CampaignModel, UserModel } from '@app/shared/model';
import { AppState } from '@store/app.state';
import { Subject } from 'rxjs';
import * as campaignDraftActions from '@store/campaign-draft';
import { CommonService } from '@app/services/common.service';
import * as moment from 'moment';
import { FeatureFlagsService } from '@app/services/feature-flag.service';
import { CampaignService } from '@app/services/campaign.service';

@Component({
  selector: 'app-submit-authorized',
  templateUrl: './submit-authorized.component.html',
})
export class SubmitAuthorizedComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();
  private editCampaignMode = false;
  private termsAgreed = false;

  stepTitle = 'submit';
  campaignDraft: CampaignModel;
  openUntilDate: Date | string;
  publishBriefApp: Date | string;
  userEmail: string;
  todayDate: Date = new Date();

  publish = {
    now: true,
    later: false,
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private commonService: CommonService,
    private featureFlagService: FeatureFlagsService,
    private campaignService: CampaignService
  ) {}

  ngOnInit(): void {
    const isAcceptTermsEnabled = this.featureFlagService.isFeatureEnabled('acceptTerms');

    if (!isAcceptTermsEnabled) {
      this.termsAgreed = true;
    }

    this.store
      .pipe(select(selectCampaignDraft), takeUntil(this.destroyed$))
      .subscribe((campaignDraft: CampaignModel) => {
        if (campaignDraft) {
          this.campaignDraft = campaignDraft;
          this.openUntilDate = campaignDraft.openUntilDate;
          this.publishBriefApp = campaignDraft.publishBriefApp;
        }
      });

    this.store.pipe(select(selectUserInfo), takeUntil(this.destroyed$)).subscribe((user: UserModel) => {
      if (user) {
        this.userEmail = user.email;
      }
    });

    if (this.router.routerState.snapshot.url.includes('edit')) {
      this.editCampaignMode = true;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  agreeTerms(event): void {
    this.termsAgreed = event.currentTarget.checked;
  }

  goToPreviousStep(): void {
    this.router.navigate(['../review'], { relativeTo: this.activatedRoute });
  }

  goToNextStep(): void {
    const { openUntilDate, userEmail } = this;

    if (this.publish.now) {
      this.publishBriefApp = moment(new Date()).format('YYYY-MM-DDThh:mm:ss');
    }

    const campaign = {
      publishBriefApp: this.publishBriefApp,
      openUntilDate,
    } as Partial<CampaignModel>;

    this.store.dispatch(campaignDraftActions.patchDraftCampaign({ campaign }));
    // TODO: refactor, the workaround for the backward compatibility
    this.campaignService.deleteCampaignMoodImage(this.campaignDraft.id).subscribe(() => {
      this.store.dispatch(
        campaignDraftActions.editCampaignDraftSubmit({
          campaign: {
            ...this.campaignDraft,
            ...campaign,
          },
          userEmail,
        })
      );
    });
  }

  enableNextStep(): boolean {
    return !!(
      this.termsAgreed &&
      this.openUntilDate &&
      (this.publish.now || (this.publish.later && this.publishBriefApp))
    );
  }
}
