import { Component, OnInit } from '@angular/core';
import {select, Store} from '@ngrx/store';
import { AuthService } from './services/auth.service';
import {getUser, selectBrand} from './store';
import { AppState } from '@store/app.state';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {BrandModel} from "@app/shared/model";
import {DialogService} from "@ngneat/dialog";
declare let process: any;
const env = process.env.NODE_ENV;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
    private ccService: NgcCookieConsentService,
    private location: Location,
    private router: Router,
    private translate: TranslateService,
    private dialog: DialogService,
  ) {}

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.store.dispatch(getUser());
    }
    localStorage.setItem('language', 'en');
    this.translate.use('en');

    if (env  !== 'production') {
      this.ccService.destroy();
    }

    this.store.pipe(select(selectBrand)).subscribe((brand: BrandModel) => {

      if (!brand?.subscription?.active_until) {
        return
      }

      const isTestPlan = String(brand.subscription.plan).toLowerCase().includes('test');

      if (!isTestPlan) {
        return
      }

      const dateObj = new Date();
      const month = dateObj.getUTCMonth() + 1;
      const day = dateObj.getUTCDate();
      const year = dateObj.getUTCFullYear();

      let activeUntil = brand?.subscription?.active_until.split('-');
      const activeUntilYear = Number(activeUntil[2]);
      const activeUntilMonth = Number(activeUntil[1]);
      const activeUntilDay = Number(activeUntil[0]);

      const isDialogShow = !!sessionStorage.getItem("subscriptionDialog");

      if (activeUntilYear == year && activeUntilMonth == month && activeUntilDay - day <= 5 && activeUntilDay - day >= 2 && !isDialogShow) {

        const LeavePageChangeText = `Your plan will expire in ${ activeUntilDay - day} days! please purchase a plan, to continue to manage your campaigns.`;
        sessionStorage.setItem("subscriptionDialog", 'true');
        const dialogRef = this.dialog.success(LeavePageChangeText, {closeButton: false});
        return dialogRef.afterClosed$.subscribe(() => {
          this.router.navigate(['/profile'])
        });
      }
    });

  }
}
