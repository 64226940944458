import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore.default(Highcharts);

@Component({
  selector: 'app-horizontal-bar-chart',
  template: `
    <highcharts-chart
      [Highcharts]='Highcharts'
      [options]='chartOptions'
      style="width: 100%; display: block;"
    >
    </highcharts-chart>`,
  styleUrls: ['./horizontal-bar-chart.component.scss']
})
export class HorizontalBarChartComponent implements OnInit {
  @Input() data: [[number]];

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};

  constructor() { }

  ngOnInit(): void {
    this.initChart(this.data);
  }

  private initChart(data): void{
    this.chartOptions = {
      chart: {
        type: 'bar',
        height: 320,
        style: {
          fontFamily: 'font-family: \'OpenSans\', sans-serif',
        },
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: [
          'REACH',
          'IMPRESSION',
        ],
        labels: {
          style: {
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#136b51'
          }
        }
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      legend: {
        enabled: false
      },
      series: [
        {
          type: 'bar',
          name: '',
          data,
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [[0, '#136b51'], [1, '#57aea1']]
          },
          pointWidth: 60,
        }
      ],
      credits: {
        enabled: false
      }
    };
  }
}


