<section class="video-box mb-100 mt-160">
  <div class="wrapper">
    <div class="title-group">
      <h2>{{'landing.influencer.videoBlock.title' | translate}}</h2>
      <p class="title-krona" [innerHTML]="'landing.influencer.videoBlock.description' | translate"></p>
    </div>

    <div class="video-box__block">
      <div class="video">
        <video #video width="100%" height="100%" controls preload="auto" poster='assets/img/poster.png'>
          <source src="{{ linkToVideo }}" type="video/mp4">
        </video>
      </div>

      <div class="video-box__group-text video-box__group-app">
        <span class="video-box__title" [innerHTML]="'landing.influencer.videoBlock.subDescription' | translate"></span>

        <div class="app-link__wrap">
          <a href="https://apps.apple.com/us/app/flytrendy-influencer/id1240289950" class="app-link">
            <img src="assets/img/AppStore.png" alt="" class="app-link__icon">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.flytrendy.flytrendy" class="app-link">
            <img src="assets/img/GooglePlay.png" alt="" class="app-link__icon">
          </a>
        </div>
      </div>

      <img src="assets/img/video-box_bg.png" alt="" class="video-box__bg">
    </div>
  </div>
</section>

<section class="influencer-text">
  <div class="wrapper">
    <div class="influencer-text__cont">
      <img src="assets/img/influencer-text-1.png" alt="" class="influencer-text__img">

      <div class="influencer-text__group">
        <h2>{{'landing.influencer.unleashYourCreativityTitle' | translate}}</h2>
        <span class="influencer-text__text" [innerHTML]="'landing.influencer.unleashYourCreativityDescription' | translate"></span>
      </div>
    </div>
  </div>
</section>

<section class="simple-as pb-100">
  <div class="wrapper">
    <div class="simple-as__cont influencer-slider">
      <div class="simple-as__colum">
        <h3 class="simple-as__h3">{{'landing.influencer.steps.title' | translate}}</h3>

        <ul class="simple-as__list">
          <li class="simple-as__item active" data-influencer-slider="0" (click)="changeSlider($event)">{{'landing.influencer.steps.items.0' | translate}}</li>
          <li class="simple-as__item" data-influencer-slider="1" (click)="changeSlider($event)">{{'landing.influencer.steps.items.1' | translate}}</li>
          <li class="simple-as__item" data-influencer-slider="2" (click)="changeSlider($event)">{{'landing.influencer.steps.items.2' | translate}}</li>
          <li class="simple-as__item" data-influencer-slider="3" (click)="changeSlider($event)">{{'landing.influencer.steps.items.3' | translate}}</li>
          <li class="simple-as__item" data-influencer-slider="4" (click)="changeSlider($event)">{{'landing.influencer.steps.items.4' | translate}}</li>
          <li class="simple-as__item" data-influencer-slider="5" (click)="changeSlider($event)">{{'landing.influencer.steps.items.5' | translate}}</li>
        </ul>
      </div>
      <div class="simple-as__colum">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="assets/img/influencer-slider-1.png" class="simple-as__img">
            </div>
            <div class="swiper-slide">
              <img src="assets/img/influencer-slider-2.png" class="simple-as__img">
            </div>
            <div class="swiper-slide">
              <img src="assets/img/influencer-slider-3.png" class="simple-as__img">
            </div>
            <div class="swiper-slide">
              <img src="assets/img/influencer-slider-4.png" class="simple-as__img">
            </div>
            <div class="swiper-slide">
              <img src="assets/img/influencer-slider-5.png" class="simple-as__img">
            </div>
            <div class="swiper-slide">
              <img src="assets/img/influencer-slider-6.png" class="simple-as__img">
            </div>
          </div>
        </div>
      </div>
      <div class="simple-as__colum">
        <a href="https://apps.apple.com/us/app/flytrendy-influencer/id1240289950" class="app-link">
          <img src="assets/img/AppStore.png" alt="" class="app-link__icon">
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.flytrendy.flytrendy" class="app-link">
          <img src="assets/img/GooglePlay.png" alt="" class="app-link__icon">
        </a>
      </div>

      <img src="assets/img/simple-as__bg.jpg" alt="" class="simple-as__bg">
    </div>
  </div>
</section>

<section class="nostri-clienti mb-100">
  <div class="wrapper">
    <div class="title-group">
      <h2 class="mb-60">{{'landing.influencer.collaboration' | translate}}</h2>
    </div>

    <div class="nostri-clienti__grid">
      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".5s" target="_blank">
        <img src="assets/img/clients/redbull.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".5s" target="_blank">
        <img src="assets/img/clients/bata.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".5s" target="_blank">
        <img src="assets/img/clients/martini.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".8s" target="_blank">
        <img src="assets/img/clients/brico.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".8s" target="_blank">
        <img src="assets/img/clients/bombay.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".7s" target="_blank">
        <img src="assets/img/clients/agency.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".7s" target="_blank">
        <img src="assets/img/clients/alpitour.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".7s" target="_blank">
        <img src="assets/img/clients/autogrill.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".7s" target="_blank">
        <img src="assets/img/clients/baci.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".5s" target="_blank">
        <img src="assets/img/clients/dominos.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".5s" target="_blank">
        <img src="assets/img/clients/fastweb.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".7s" target="_blank">
        <img src="assets/img/clients/groupm.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".7s" target="_blank">
        <img src="assets/img/clients/hardrock.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".7s" target="_blank">
        <img src="assets/img/clients/huawei.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".5s" target="_blank">
        <img src="assets/img/clients/io.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".5s" target="_blank">
        <img src="assets/img/clients/la.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".5s" target="_blank">
        <img src="assets/img/clients/mediolanum.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".7s" target="_blank">
        <img src="assets/img/clients/nestle.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".7s" target="_blank">
        <img src="assets/img/clients/ogilvy.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".7s" target="_blank">
        <img src="assets/img/clients/riboo.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".5s" target="_blank">
        <img src="assets/img/clients/rossopom.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".5s" target="_blank">
        <img src="assets/img/clients/slam.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".5s" target="_blank">
        <img src="assets/img/clients/very.png" alt="">
      </div>

      <div class="nostri-clienti__link wow fadeInUp" data-wow-delay=".7s" target="_blank">
        <img src="assets/img/clients/wavem.png" alt="">
      </div>
    </div>
  </div>
</section>
