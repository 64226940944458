import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import { PieChartComponent } from '@app/common/chart/pie-chart/pie-chart.component';

@NgModule({
  declarations: [PieChartComponent],
  imports: [
    CommonModule,
    HighchartsChartModule
  ],
  exports: [PieChartComponent]
})
export class PieChartModule { }
