<app-campaign-wizard-step [stepTitle]="stepTitle" [enabledNextStep]='enableNextStep()' (nextStepClicked)="goToNextStep()" (previousStepClicked)='goToPreviousStep()'>

<!--<div class="new-campaign-submit mt-50 mb-50">-->
<!--  <div class="new-campaign-form__wrapper">-->
<!--    <div class="new-campaign-submit__colum">-->
<!--      <div class="form__box">-->
<!--        <span class="form__title">{{'newCampaign.submit.publishBrief' | translate}}</span>-->

<!--        &lt;!&ndash; to activate the button we use the 'active' class &ndash;&gt;-->
<!--        <div class="new-campaign-form__btn-group">-->
<!--          <button (click)='publish.now = !publish.now; publish.later = !publish.later;' type="button" class="btn small campaign-secondary-btn" [ngClass]='{"active" : publish.now}'>-->
<!--            <span class="btn__text">{{'newCampaign.review.now' | translate}}</span>-->
<!--          </button>-->

<!--          <button (click)='publish.now = !publish.now; publish.later = !publish.later;' type="button" class="btn small campaign-secondary-btn" [ngClass]='{"active" : publish.later}'>-->
<!--            <span class="btn__text">{{'newCampaign.review.later' | translate}}</span>-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="form__box mt-30" *ngIf='publish.later'>-->
<!--        <input [min]="todayDate" [(ngModel)]='publishBriefApp' (click)="pickerBrief.open()" [matDatepicker]="pickerBrief"  type="text" class="form__input" placeholder="DD/MM/YYYY">-->
<!--&lt;!&ndash;        <span class="form__error">error text</span>&ndash;&gt;-->
<!--        <mat-datepicker #pickerBrief></mat-datepicker>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="new-campaign-submit__colum">-->
<!--      <div class="form__box">-->
<!--        <span class="form__title">{{'newCampaign.submit.openUntil' | translate}}</span>-->
<!--        <input [min]="todayDate" [(ngModel)]='openUntilDate' (click)="picker.open()" [matDatepicker]="picker" class="form__input" placeholder="DD/MM/YYYY">-->
<!--        <mat-datepicker #picker></mat-datepicker>-->
<!--&lt;!&ndash;        <span class="form__error">error text</span>&ndash;&gt;-->
<!--      </div>-->

<!--      <ng-container *featureFlag="'acceptTerms'">-->
<!--      <div class="form__box mt-30">-->
<!--        <label class="checked">-->
<!--          <input (change)="agreePolicy($event)" type="checkbox" class="checked__input">-->
<!--          <span class="checked__square"></span>-->
<!--          <span class="checked__text">{{'general.acceptConditions' | translate}} <a routerLink='/privacy-policy' target="_blank">FlyTrendy's Privacy Policy</a></span>-->
<!--        </label>-->

<!--        <label class="checked">-->
<!--          <input (change)="agreeTerms($event)" type="checkbox" class="checked__input">-->
<!--          <span class="checked__square"></span>-->
<!--          <span class="checked__text">{{'general.acceptConditions' | translate}} <a routerLink='/terms-brand' target="_blank">FlyTrendy's T&C's</a></span>-->
<!--        </label>-->
<!--      </div>-->
<!--      </ng-container>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
  <div class="builder__cont step-6">
    <div class="builder__head">
      <h2 class="h1">{{ 'newCampaign.submitStep.title' | translate }}</h2>
    </div>
    <div class="builder__main">
      <div class="builder__col">
        <div class="form__box">
          <span class="form__label">{{ 'newCampaign.submitStep.publishBrief' | translate }}</span>
          <div class="btn-group">
            <button type="button" class="btn btn--br btn--secondary active" (click)='publish.now = !publish.now; publish.later = !publish.later;' [ngClass]='{"active" : publish.now}'>
              {{ 'newCampaign.review.now' | translate }}</button>
            <button type="button" class="btn btn--br btn--secondary" (click)='publish.now = !publish.now; publish.later = !publish.later;' [ngClass]='{"active" : publish.later}'>
              {{ 'newCampaign.review.later' | translate }}</button>
          </div>
        </div>
        <div class="form__box" *ngIf='publish.later'>
          <div class="form__date">
            <input type="text" class="form__input" [min]="todayDate" [(ngModel)]='publishBriefApp' (click)="pickerBrief.open()" [matDatepicker]="pickerBrief" placeholder="DD/MM/YYYY">
            <mat-datepicker #pickerBrief></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="builder__col">
        <div class="form__box">
          <span class="form__label">{{ 'newCampaign.submitStep.openUntil' | translate }}</span>
          <div class="form__date">
            <input type="text" class="form__input" [min]="todayDate" [(ngModel)]='openUntilDate' (click)="picker.open()" [matDatepicker]="picker" placeholder="DD/MM/YYYY">
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
        <label class="checked">
          <input type="checkbox" (change)="agreeTerms($event)">
          <span class="checked__circle"></span>
          <span class="checked__text">{{ 'newCampaign.submitStep.agreeText' | translate }}
            <a routerLink="/terms-brand" class="link">{{ 'newCampaign.submitStep.termsConditions' | translate }}</a>
            {{ 'newCampaign.submitStep.and' | translate }}
            <a routerLink="privacy-policy" class="link">{{ 'newCampaign.submitStep.privacyPolicy' | translate }}</a></span>
        </label>
      </div>
    </div>
  </div>

</app-campaign-wizard-step>
