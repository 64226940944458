import { AbstractControl, ValidationErrors } from '@angular/forms';

export function ValidateEqual(equals: string) {
  return (control: AbstractControl): ValidationErrors | null => {

    const equalsField = control.root.get(equals);
    if (equalsField) {
      if (control.value !== equalsField.value) {
        return { equals: true };
      }
    }
    return null;
  };
}
