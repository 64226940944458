<div class='progress-spinner' *ngIf="isLoading">
  <mat-spinner diameter='70'></mat-spinner>
</div>

<div class='post-tab-navigation-buttons' *ngIf="!isLoading">
  <a [routerLink]="'/campaigns/' + post.campaignId" class="post-tab__navigate-button">{{'campaignOverview.post.dialog.backCampaign' | translate}}</a>
</div>

<div *ngIf="isEditing; then postEditingDialog else postDialog"></div>

<ng-template #postDialog>
  <div class="modal-post" *ngIf='!isLoading'>
    <div class="campaign-review">
      <button *ngIf='feedbacksHistory.length' (click)='isEditing = true' class="btn primary-btn-filled modal__button">{{'campaignOverview.post.switchToEditingHistory' | translate}}</button>
      <div class="post-info">
        <div class="post-info__colum">
          <div class="modal-post-info">
            <img [src]='profileImage' alt="influencer profile" class="modal-post-info__avatar" (error)="setDefaultProfileImage()">
            <div class="modal-post-info__group">
              <a *ngIf='post.postSocialType !== "tiktok"' [href]='post.socialAccountInfo.profileUrl' target='_blank' class="modal-post-info__title">{{post.socialAccountInfo.name || post.socialAccountInfo.username}}</a>
              <p *ngIf='post.postSocialType === "tiktok"' class="modal-post-info__title">{{post.socialAccountInfo.name}}</p>
              <span class="modal-post-info__subtitle"><b>{{post.socialAccountInfo.followers}}</b>  {{'campaignOverview.followers' | translate}}</span>
            </div>

          </div>
          <div *ngIf='showPrices && !disableButtons'>
            <div class="post-info-price__wrapper">
              <div class="post-info-price">
                <div class="post-info-price__colum">
                  <span class="post-info-price__text">{{'campaignOverview.post.dialog.media' | translate}}</span>
                  <span class="post-info-price__subtext">{{'campaignOverview.post.dialog.techFee' | translate}}</span>
                </div>
                <div class="post-info-price__colum">
                  <span class="post-info-price__text">€ {{post.postPriceTechFee}}</span>
                </div>
              </div>

              <div class="post-info-price">
                <div class="post-info-price__colum">
                  <span class="post-info-price__text">{{'campaignOverview.post.dialog.iva' | translate}}</span>
                </div>
                <div class="post-info-price__colum">
                  <span class="post-info-price__text">€ {{IVA}}</span>
                </div>
              </div>
            </div>

            <div class="post-info-price-all">
              <span class="post-info-price-all__text">{{'campaignOverview.post.dialog.total' | translate}}</span>
              <span class="post-info-price-all__text">€ {{post.postPriceFinal}}</span>
            </div>
          </div>
          <div *ngIf="statusChangingProcess; then spinner else postStatuses"></div>

          <ng-template #spinner>
            <div class='progress-spinner'>
              <mat-spinner diameter='40'></mat-spinner>
            </div>
          </ng-template>

          <ng-template #postStatuses>
            <div class="post-card__btn-group large" *ngIf='post.status === "pending"'>
              <button type="button" class="post-card-btn success" [ngClass]="{'disabledPostButtons': disableButtons}"
                      (click)='approvePost()'>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="#4BD559" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M29.18 11.16L16 24.34L8.82 17.18L6 20L16 30L32 14L29.18 11.16ZM20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.16 36 4 28.84 4 20C4 11.16 11.16 4 20 4C28.84 4 36 11.16 36 20C36 28.84 28.84 36 20 36Z"/>
                </svg>
                <span>{{'campaignOverview.post.accept' | translate}}</span>
              </button>
              <button type="button" class="post-card-btn warning" [ngClass]="{'disabledPostButtons': disableButtons}"
                      (click)='changePost()'>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="#D5CF4B" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18 26H22V30H18V26ZM18 10H22V22H18V10ZM19.98 0C8.94 0 0 8.96 0 20C0 31.04 8.94 40 19.98 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 19.98 0ZM20 36C11.16 36 4 28.84 4 20C4 11.16 11.16 4 20 4C28.84 4 36 11.16 36 20C36 28.84 28.84 36 20 36Z"/>
                </svg>
                <span>{{'campaignOverview.post.change' | translate}}</span>
              </button>
              <button type="button" class="post-card-btn danger" [ngClass]="{'disabledPostButtons': disableButtons}"
                      (click)='declinePost()'>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="#D54B4B" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M25.18 12L20 17.18L14.82 12L12 14.82L17.18 20L12 25.18L14.82 28L20 22.82L25.18 28L28 25.18L22.82 20L28 14.82L25.18 12ZM20 0C8.94 0 0 8.94 0 20C0 31.06 8.94 40 20 40C31.06 40 40 31.06 40 20C40 8.94 31.06 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z"/>
                </svg>
                <span>{{'campaignOverview.post.decline' | translate}}</span>
              </button>
            </div>
            <div *ngIf='post.status !== "pending"' class="post-card__btn-group large">


              <div class="post-card-btn success"
                   *ngIf='post.status === "approved" || post.status === "published" || post.status === "pending_published"'>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="#4BD559" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M29.18 11.16L16 24.34L8.82 17.18L6 20L16 30L32 14L29.18 11.16ZM20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.16 36 4 28.84 4 20C4 11.16 11.16 4 20 4C28.84 4 36 11.16 36 20C36 28.84 28.84 36 20 36Z"/>
                </svg>
                <span>{{'campaignOverview.post.accepted' | translate}}</span>
              </div>

              <div class="post-card-btn danger" *ngIf='post.status === "declined"'>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="#D54B4B" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M25.18 12L20 17.18L14.82 12L12 14.82L17.18 20L12 25.18L14.82 28L20 22.82L25.18 28L28 25.18L22.82 20L28 14.82L25.18 12ZM20 0C8.94 0 0 8.94 0 20C0 31.06 8.94 40 20 40C31.06 40 40 31.06 40 20C40 8.94 31.06 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z"/>
                </svg>
                <span>{{'campaignOverview.post.declined' | translate}}</span>
              </div>

            </div>
          </ng-template>


          <div *ngIf="requestInsightProcess; then spinnerInsights else insightsButton"></div>
          <ng-template #spinnerInsights>
            <div class='progress-spinner insights-link'>
              <mat-spinner diameter='30'></mat-spinner>
            </div>
          </ng-template>

          <ng-template #insightsButton>
          <span *ngIf='post.postSocialType !== "linkedin" && post.postSocialType !== "tiktok"' (click)='openPostInsight($event, post)' class='post-block__info insights-link'>
            <img src='assets/img/icons/insights.svg' alt='insights icon' />
            <a href=''>{{'campaignOverview.post.dialog.insights' | translate}}</a>
          </span>
          </ng-template>
          <span (click)='downloadMedia(post.id, $event)' class='post-block__info download-link new-tab-page' *ngIf='post.status === "published"'>
            <a href=''>{{'campaignOverview.post.dialog.downloadMediaLink' | translate}}</a>
        </span>

        </div>

        <div class="post-info__colum">
          <video *ngIf='post.isVideo && !post.carouselPostMedia' controls poster="{{post.postImageInfo.imageUrl}}" autoplay>
            <source src="{{post.videoUrl}}" type="video/mp4">
          </video>

          <img *ngIf='!post.isVideo && !post.carouselPostMedia' [src]="post.postImageInfo.imageUrl" style='cursor: pointer' (click)='openImage(post.postImageInfo.imageUrl)' alt="" class="post-info__img">

          <p-carousel *ngIf="post.carouselPostMedia" [value]="post.carouselPostMedia"
                      [numVisible]="1" [numScroll]="1" [circular]="false">
            <ng-template let-item pTemplate="item">
              <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                <div *ngIf="!item.isVideo" class="mb-3" style="cursor: pointer">
                  <img src="{{ item.url }}" class="w-6 shadow-2" alt="carousel post" (click)='openImage(item.url)' />
                </div>
                <div *ngIf="item.isVideo" class="mb-3" style="cursor: pointer">
                  <video controls style="width: 100%">
                    <source src="{{item.url}}" type="video/mp4">
                  </video>
                </div>
              </div>
            </ng-template>
          </p-carousel>

          <p class="feedback-block__description"><b>{{post.postText}}</b></p>

          <p *ngIf='post.postSocialType === "instagram" || post.postSocialType === "reels" || post.postSocialType === "instagramstory"' class="feedback-block__description feedback-block__tags">{{campaignTags?.instagram}}</p>
          <p *ngIf='post.postSocialType === "tiktok"' class="feedback-block__description feedback-block__tags">{{campaignTags?.tiktok}}</p>
          <p *ngIf='post.postSocialType === "facebook"' class="feedback-block__description feedback-block__tags">{{campaignTags?.facebook}}</p>
          <p *ngIf='post.postSocialType === "linkedin"' class="feedback-block__description feedback-block__tags">{{campaignTags?.linkedin}}</p>
          <span class="post-info__submitted">{{'campaignOverview.post.submited' | translate}}: <b>{{post.createdDatetime | date: 'medium'}}</b></span>
        </div>
      </div>
    </div>

  </div>
</ng-template>
<ng-template #postEditingDialog>
  <app-post-editing-dialog [postData]='post' [feedbacksHistory]='feedbacksHistory' [editingHistory]="editingHistory" (isEditingChange)='isEditing = false'></app-post-editing-dialog>
</ng-template>

