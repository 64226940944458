import { Component, OnInit } from '@angular/core';
import { DialogRef, DialogService } from '@ngneat/dialog';
import { AuthService } from '@app/services/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { getUser } from '@app/store';
import { ForgotPasswordComponent } from '@app/landing/dialogs/forgot-password/forgot-password.component';
import {RegistrationDialogComponent} from "@app/dialogs/registration-dialog/registration-dialog.component";

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent implements OnInit {
  loginForm: FormGroup;
  username = '';
  password = '';
  errorMessage = '';
  // isLoadingResults = false;

  constructor(
    public ref: DialogRef,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  forgotPassword(): void {
    this.ref.close();
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      closeButton: true,
    });
  }

  onFormSubmit(event): void {
    event.preventDefault();
    event.stopPropagation();
    // this.isLoadingResults = true;
    this.authService.login(this.loginForm.value).subscribe(
      () => {
        this.store.dispatch(getUser());
        // this.isLoadingResults = false;
        this.router.navigate(['/campaigns']).then((_) => console.log('Logged in'));
        this.ref.close();
      },
      (err: any) => {
        this.errorMessage = err;
        //  this.isLoadingResults = false;
      }
    );
  }
  openRegistrationDialog() {
    this.ref.close();
    const dialogRef = this.dialog.open(RegistrationDialogComponent, {
      enableClose: false,
      width: '1200px'
    });
  }
}
