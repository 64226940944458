<section class="feedback mb-100 mt-100">
  <div class="wrapper-small">
    <h2 class="feedback__title">{{'feedback.campaign' | translate }}: {{postFeedback?.campaignName}}</h2>
  </div>

  <div class="wrapper-big">
    <div *ngIf='clientReviewed' class='wrapper client-reviewed' style='min-height: 400px'>
      <h3>{{'feedback.feedbackSentSuccess' | translate}}</h3>
    </div>
    <div class="wrapper" *ngIf='!clientReviewed'>
      <div class="feedback__cont">
        <div class="feedback-block" *ngFor='let post of postFeedback?.posts'>
          <div class="feedback-block__middle">
            <img *ngIf='!post.videoUrl' [src]="post.postImageUrl" alt="" (click)='openImage(post.postImageUrl)' style='cursor: pointer' class="feedback-block__img">

            <video *ngIf='post.videoUrl' controls poster="{{post.postImageUrl}}">
              <source src="{{post.videoUrl}}" type="video/mp4">
            </video>


            <div class="feedback-info">
              <img [src]="post.socialAccountInfo.profilePicture" alt="" class="feedback-info__avatar" (error)='setDefaultProfileImage(post)'>
              <div class="feedback-info__group">
                <a *ngIf='post.postSocialType !== "tiktok"' [href]='post.socialAccountInfo.profileUrl' target='_blank' class="feedback-info__title">{{post.socialAccountInfo.name || post.socialAccountInfo.username}}</a>
                <p *ngIf='post.postSocialType === "tiktok"' class="feedback-info__title">{{post.socialAccountInfo.name}}</p>
                <span class="feedback-info__subtitle"><b>{{post.socialAccountInfo.followers}}</b> {{ 'feedback.followers' | translate }}</span>
              </div>
              <img *ngIf='post.postSocialType === "tiktok"' src="assets/img/icons/tiktok-color.png" alt="Tiktok post" class="feedback-info__platform">
              <img *ngIf='post.postSocialType === "facebook"' src="assets/img/icons/fb-color.svg" alt="Facebook post" class="feedback-info__platform">
              <img *ngIf='post.postSocialType === "instagramstory"' src="assets/img/icons/in-story-color.svg" alt="Instagram story" class="feedback-info__platform">
              <img *ngIf='post.postSocialType === "reels"' src="assets/img/icons/reels_icon.png" alt="Instagram reels" class="feedback-info__platform">
              <img *ngIf='post.postSocialType === "instagram"' src="assets/img/icons/in-color.svg" alt="Instagram post" class="feedback-info__platform">
              <img *ngIf='post.postSocialType === "linkedin"' src="assets/img/icons/linkedin.png" alt="Linkedin post" class="feedback-info__platform">
            </div>
            <div class="feedback-price" *ngIf='postFeedback.showPrice'>
              <span class="feedback-price__text">{{ 'feedback.total' | translate }}</span>
              <span class="feedback-price__text">€ {{post.price}}</span>
            </div>

            <p class="feedback-block__description"><b>{{post.postText}}</b></p>
          </div>

          <div *ngIf="post.requestInsightProcess; then spinnerInsights else insightsButton"></div>

          <ng-template #spinnerInsights>
            <div class='progress-spinner insights-link_post-feedback'>
              <mat-spinner diameter='30'></mat-spinner>
            </div>
          </ng-template>

          <ng-template #insightsButton>
              <span *ngIf='post.postSocialType !== "linkedin"' (click)='openPostInsight($event, post)' class='post-block__info insights-link_post-feedback' [ngClass]='post.status === "published" ? "three-items" : ""'>
                <img src='assets/img/icons/insights.svg' alt='insights icon' />
                <a href=''>{{'campaignOverview.post.dialog.insights' | translate}}</a>
              </span>
          </ng-template>

          <div class="feedback-block__bottom">
            <button (click)='changePostStatus(post, "approve")' class="feedback-block__btn approve btn-circle primary-btn-circle" type="button" [ngClass]='{"disabled": post.status === "decline" || post.status === "change"}'>
              <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">
                <path d="M43.77 16.74L24 36.51L13.23 25.77L9 30L24 45L48 21L43.77 16.74ZM30 0C13.44 0 0 13.44 0 30C0 46.56 13.44 60 30 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 30 0ZM30 54C16.74 54 6 43.26 6 30C6 16.74 16.74 6 30 6C43.26 6 54 16.74 54 30C54 43.26 43.26 54 30 54Z"/>
              </svg>
              <span class="btn-circle__text">{{'campaignOverview.post.accept' | translate }}</span>
            </button>

            <button (click)='changePostStatus(post, "change")' class="feedback-block__btn change btn-circle secondary-btn-circle" type="button" [ngClass]='{"disabled": post.status === "decline" || post.status === "approve"}'>
              <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">
                <path d="M27 39H33V45H27V39ZM27 15H33V33H27V15ZM29.97 0C13.41 0 0 13.44 0 30C0 46.56 13.41 60 29.97 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 29.97 0ZM30 54C16.74 54 6 43.26 6 30C6 16.74 16.74 6 30 6C43.26 6 54 16.74 54 30C54 43.26 43.26 54 30 54Z"/>
              </svg>
              <span class="btn-circle__text">{{'campaignOverview.post.change' | translate }}</span>
            </button>

            <button (click)='changePostStatus(post, "decline")' class="feedback-block__btn decline btn-circle tertiary-btn-circle" type="button" [ngClass]='{"disabled": post.status === "approve" || post.status === "change"}'>
              <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">
                <path d="M37.77 18L30 25.77L22.23 18L18 22.23L25.77 30L18 37.77L22.23 42L30 34.23L37.77 42L42 37.77L34.23 30L42 22.23L37.77 18ZM30 0C13.41 0 0 13.41 0 30C0 46.59 13.41 60 30 60C46.59 60 60 46.59 60 30C60 13.41 46.59 0 30 0ZM30 54C16.77 54 6 43.23 6 30C6 16.77 16.77 6 30 6C43.23 6 54 16.77 54 30C54 43.23 43.23 54 30 54Z"/>
              </svg>

              <span class="btn-circle__text">{{'campaignOverview.post.decline' | translate }}</span>
            </button>
          </div>
        </div>

      </div>

      <button (click)='sendFeedback()' type="button" class="btn big primary-btn-filled">
        <span class="btn__text">{{ 'feedback.confirm' | translate }}</span>
      </button>
    </div>

    <img src="assets/img/campaigns/campaign-top-bg.jpg" alt="" class="feedback__bg">
  </div>

</section>

