import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from '@app/services/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CampaignModule } from '@app/campaign/campaign.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonUIModule } from '@app/common/common.module';
import { CommunityInsightsModule } from '@app/community-insights/community-insights.module';
import { DialogModule } from '@ngneat/dialog';
import { EffectsModule } from '@ngrx/effects';
import { FeatureFlagsService } from '@app/services/feature-flag.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LandingModule } from '@app/landing/landing.module';
import { LoginModule } from '@app/landing/login/login.module';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NotFoundComponent } from '@app/common/not-found/not-found.component';
import { PaymentsDialogModule } from '@app/dialogs/payments-dialog/payments-dialog.module';
import { PaymentsModule } from '@app/payments/payments.module';
import { PolicyConditionsModule } from '@app/landing/policy-conditions/policy-conditions.module';
import { PostFeedbackModule } from '@app/common/post-feedback/post-feedback.module';
import { PostOverviewDialogModule } from '@app/dialogs/post-overview-dialog/post-overview-dialog.module';
import { PostTabModule } from '@app/campaigns/campaign-overview/post-tab/post-tab.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RegistrationDialogModule } from '@app/dialogs/registration-dialog/registration-dialog.module';
import { RequestDemoComponent } from './dialogs/request-demo/request-demo.component';
import { StoreModule } from '@ngrx/store';
import { ToastrModule } from 'ngx-toastr';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { appReducer, appEffects, metaReducers } from './store';
import { cookieConfig } from '@app/cookie-config';

import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import {ErrorInterceptor} from "@app/services/error.interceptor";
import { ImageDialogModule } from '@app/dialogs/image-dialog/image-dialog.module';

const featureFactory = (featureFlagsService: FeatureFlagsService) => () => featureFlagsService.loadConfig();

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, NotFoundComponent, RequestDemoComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        DialogModule.forRoot(),
        MatSelectCountryModule.forRoot('en'),
        MatDialogModule,
        ToastrModule.forRoot({
          positionClass: 'toast-bottom-right',
          preventDuplicates: true
        }),
        StoreModule.forRoot(appReducer, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        EffectsModule.forRoot(appEffects),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        LandingModule,
        LoginModule,
        CommonUIModule,
        CampaignModule,
        PolicyConditionsModule,
        PostFeedbackModule,
        PostOverviewDialogModule,
        CommunityInsightsModule,
        PostTabModule,
        ClipboardModule,
        RegistrationDialogModule,
        ReactiveFormsModule,
        PaymentsModule,
        PaymentsDialogModule,
      ImageDialogModule,
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: featureFactory,
      deps: [FeatureFlagsService],
      multi: true,
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    {
      provide: MatDialogRef,
      useValue: {},
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
