<div class="modal modal-reg">
  <div class="modal-reg__header">
    <div class="modal-reg__caption-box">
      <span class="h1">{{ 'regDialog.brandInfo.title' | translate }}</span>
      <span class="modal-reg__step-text">{{ 'regDialog.step' | translate }} 3</span>
    </div>
    <span class="h2">{{ 'regDialog.choosePlan.subtitle' | translate }}</span>
  </div>
  <ng-container *ngIf="!plans?.length || loading">
    <div class='progress-spinner'>
      <mat-spinner diameter='70'></mat-spinner>
    </div>
    <button type="button" class="btn btn--bg btn--tertiary btn--modal"
            matStepperPrevious>{{ 'regDialog.back' | translate }}
    </button>
  </ng-container>
  <ng-container *ngIf="plans?.length && !loading">
    <div class="modal-reg__main">
      <app-plan-item
        *ngFor="let plan of plans; let i = index"
        [currency]="plan.currency"
        [planTitle]="plan.name"
        [planSlug]="plan?.slug"
        [planCost]="plan.price"
        [planInfluencers]="plan.max_influencers"
        [planTooltip]="plan?.slug?.includes('test') ? planDescriptions.TEST : planDescriptions[plan?.name.split('_')[0] | uppercase]"
        [isActive]="selectedPlan?.slug === plan?.slug"
        (click)="selectPlan(plan)"
        [class.test-plan-item]="plan?.slug?.includes('test')"
        [class.corporate-plan-item]="plan?.slug?.toLowerCase().includes('corporate')"
      >
      </app-plan-item>

      <!--    <div class="choose-plan" [ngClass]="{'active': control.value === AccountPlan.starter}" (click)="control.setValue(AccountPlan.starter)">-->
      <!--      <div class="choose-plan__wrapper">-->
      <!--        <div class="choose-plan__group">-->
      <!--          <p class="choose-plan__title">STARTER <span>(10 influencers max)</span></p>-->
      <!--          <div class="reward-box__info tooltip-wrap">-->
      <!--            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
      <!--              <path-->
      <!--                d="M8 22.3199C7.72 22.3199 7.42998 22.2499 7.16998 22.1099C6.59998 21.8099 6.25 21.2099 6.25 20.5699V19.15C3.23 18.84 1.25 16.6199 1.25 13.4399V7.43994C1.25 3.99994 3.56 1.68994 7 1.68994H17C20.44 1.68994 22.75 3.99994 22.75 7.43994V13.4399C22.75 16.8799 20.44 19.1899 17 19.1899H13.23L8.96997 22.03C8.67997 22.22 8.34 22.3199 8 22.3199ZM7 3.17993C4.42 3.17993 2.75 4.84993 2.75 7.42993V13.43C2.75 16.01 4.42 17.68 7 17.68C7.41 17.68 7.75 18.02 7.75 18.43V20.56C7.75 20.69 7.83 20.75 7.88 20.78C7.93001 20.81 8.03001 20.84 8.14001 20.77L12.59 17.81C12.71 17.73 12.86 17.68 13.01 17.68H17.01C19.59 17.68 21.26 16.01 21.26 13.43V7.42993C21.26 4.84993 19.59 3.17993 17.01 3.17993H7Z"></path>-->
      <!--              <path-->
      <!--                d="M11.9998 12.1099C11.5898 12.1099 11.2498 11.7699 11.2498 11.3599V11.1499C11.2498 9.9899 12.0998 9.41989 12.4198 9.19989C12.7898 8.94989 12.9098 8.7799 12.9098 8.5199C12.9098 8.0199 12.4998 7.60986 11.9998 7.60986C11.4998 7.60986 11.0898 8.0199 11.0898 8.5199C11.0898 8.9299 10.7498 9.2699 10.3398 9.2699C9.92984 9.2699 9.58984 8.9299 9.58984 8.5199C9.58984 7.1899 10.6698 6.10986 11.9998 6.10986C13.3298 6.10986 14.4098 7.1899 14.4098 8.5199C14.4098 9.6599 13.5698 10.2299 13.2598 10.4399C12.8698 10.6999 12.7498 10.8699 12.7498 11.1499V11.3599C12.7498 11.7799 12.4098 12.1099 11.9998 12.1099Z"></path>-->
      <!--              <path-->
      <!--                d="M12 14.6001C11.58 14.6001 11.25 14.2601 11.25 13.8501C11.25 13.4401 11.59 13.1001 12 13.1001C12.41 13.1001 12.75 13.4401 12.75 13.8501C12.75 14.2601 12.42 14.6001 12 14.6001Z"></path>-->
      <!--            </svg>-->
      <!--            <p>About this plan</p>-->
      <!--            <span class="tooltip left"> It allows you to invite up to 10 influencers from your community, who can become your ambassadors by publishing social content and be immediately rewarded through vouchers. Start inviting your community!</span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <span class="choose-plan__price"><b>450 euro</b> per month</span>-->
      <!--        <img src="assets/icons/success&#45;&#45;white.svg" alt="icon" class="choose-plan__icon">-->
      <!--      </div>-->
      <!--    </div>-->
      <!--    <div class="choose-plan" [ngClass]="{'active': control.value === AccountPlan.pro}" (click)="control.setValue(AccountPlan.pro)">-->
      <!--      <div class="choose-plan__wrapper">-->
      <!--        <div class="choose-plan__group">-->
      <!--          <p class="choose-plan__title">PRO <span>(100 influencers max)</span></p>-->
      <!--          <div class="reward-box__info tooltip-wrap">-->
      <!--            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
      <!--              <path-->
      <!--                d="M8 22.3199C7.72 22.3199 7.42998 22.2499 7.16998 22.1099C6.59998 21.8099 6.25 21.2099 6.25 20.5699V19.15C3.23 18.84 1.25 16.6199 1.25 13.4399V7.43994C1.25 3.99994 3.56 1.68994 7 1.68994H17C20.44 1.68994 22.75 3.99994 22.75 7.43994V13.4399C22.75 16.8799 20.44 19.1899 17 19.1899H13.23L8.96997 22.03C8.67997 22.22 8.34 22.3199 8 22.3199ZM7 3.17993C4.42 3.17993 2.75 4.84993 2.75 7.42993V13.43C2.75 16.01 4.42 17.68 7 17.68C7.41 17.68 7.75 18.02 7.75 18.43V20.56C7.75 20.69 7.83 20.75 7.88 20.78C7.93001 20.81 8.03001 20.84 8.14001 20.77L12.59 17.81C12.71 17.73 12.86 17.68 13.01 17.68H17.01C19.59 17.68 21.26 16.01 21.26 13.43V7.42993C21.26 4.84993 19.59 3.17993 17.01 3.17993H7Z"></path>-->
      <!--              <path-->
      <!--                d="M11.9998 12.1099C11.5898 12.1099 11.2498 11.7699 11.2498 11.3599V11.1499C11.2498 9.9899 12.0998 9.41989 12.4198 9.19989C12.7898 8.94989 12.9098 8.7799 12.9098 8.5199C12.9098 8.0199 12.4998 7.60986 11.9998 7.60986C11.4998 7.60986 11.0898 8.0199 11.0898 8.5199C11.0898 8.9299 10.7498 9.2699 10.3398 9.2699C9.92984 9.2699 9.58984 8.9299 9.58984 8.5199C9.58984 7.1899 10.6698 6.10986 11.9998 6.10986C13.3298 6.10986 14.4098 7.1899 14.4098 8.5199C14.4098 9.6599 13.5698 10.2299 13.2598 10.4399C12.8698 10.6999 12.7498 10.8699 12.7498 11.1499V11.3599C12.7498 11.7799 12.4098 12.1099 11.9998 12.1099Z"></path>-->
      <!--              <path-->
      <!--                d="M12 14.6001C11.58 14.6001 11.25 14.2601 11.25 13.8501C11.25 13.4401 11.59 13.1001 12 13.1001C12.41 13.1001 12.75 13.4401 12.75 13.8501C12.75 14.2601 12.42 14.6001 12 14.6001Z"></path>-->
      <!--            </svg>-->
      <!--            <p>About this plan</p>-->
      <!--            <span class="tooltip left">It allows you to invite up to 100 influencers from your community, ensuring the achievement of KPIs through social content publication and word-of-mouth, and easily and quickly rewarding your community with vouchers! Start reaching KPI’s thanks to your community!</span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <span class="choose-plan__price"><b>700 euro</b> per month</span>-->
      <!--        <img src="assets/icons/success&#45;&#45;white.svg" alt="icon" class="choose-plan__icon">-->
      <!--      </div>-->
      <!--    </div>-->
      <!--    <div class="choose-plan" [ngClass]="{'active': control.value === AccountPlan.enterprise}" (click)="control.setValue(AccountPlan.enterprise)">-->
      <!--      <div class="choose-plan__wrapper">-->
      <!--        <div class="choose-plan__group">-->
      <!--          <p class="choose-plan__title">ENTERPRISE <span>(unlimited influencers)</span></p>-->
      <!--          <div class="reward-box__info tooltip-wrap">-->
      <!--            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
      <!--              <path-->
      <!--                d="M8 22.3199C7.72 22.3199 7.42998 22.2499 7.16998 22.1099C6.59998 21.8099 6.25 21.2099 6.25 20.5699V19.15C3.23 18.84 1.25 16.6199 1.25 13.4399V7.43994C1.25 3.99994 3.56 1.68994 7 1.68994H17C20.44 1.68994 22.75 3.99994 22.75 7.43994V13.4399C22.75 16.8799 20.44 19.1899 17 19.1899H13.23L8.96997 22.03C8.67997 22.22 8.34 22.3199 8 22.3199ZM7 3.17993C4.42 3.17993 2.75 4.84993 2.75 7.42993V13.43C2.75 16.01 4.42 17.68 7 17.68C7.41 17.68 7.75 18.02 7.75 18.43V20.56C7.75 20.69 7.83 20.75 7.88 20.78C7.93001 20.81 8.03001 20.84 8.14001 20.77L12.59 17.81C12.71 17.73 12.86 17.68 13.01 17.68H17.01C19.59 17.68 21.26 16.01 21.26 13.43V7.42993C21.26 4.84993 19.59 3.17993 17.01 3.17993H7Z"></path>-->
      <!--              <path-->
      <!--                d="M11.9998 12.1099C11.5898 12.1099 11.2498 11.7699 11.2498 11.3599V11.1499C11.2498 9.9899 12.0998 9.41989 12.4198 9.19989C12.7898 8.94989 12.9098 8.7799 12.9098 8.5199C12.9098 8.0199 12.4998 7.60986 11.9998 7.60986C11.4998 7.60986 11.0898 8.0199 11.0898 8.5199C11.0898 8.9299 10.7498 9.2699 10.3398 9.2699C9.92984 9.2699 9.58984 8.9299 9.58984 8.5199C9.58984 7.1899 10.6698 6.10986 11.9998 6.10986C13.3298 6.10986 14.4098 7.1899 14.4098 8.5199C14.4098 9.6599 13.5698 10.2299 13.2598 10.4399C12.8698 10.6999 12.7498 10.8699 12.7498 11.1499V11.3599C12.7498 11.7799 12.4098 12.1099 11.9998 12.1099Z"></path>-->
      <!--              <path-->
      <!--                d="M12 14.6001C11.58 14.6001 11.25 14.2601 11.25 13.8501C11.25 13.4401 11.59 13.1001 12 13.1001C12.41 13.1001 12.75 13.4401 12.75 13.8501C12.75 14.2601 12.42 14.6001 12 14.6001Z"></path>-->
      <!--            </svg>-->
      <!--            <p>About this plan</p>-->
      <!--            <span class="tooltip left">It allows you to invite an unlimited number of influencers from your community (even your entire community) and reach the maximum potential audience through social content publication and voucher remuneration. Rock it!</span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <span class="choose-plan__price"><b>1200 euro</b> per month</span>-->
      <!--        <img src="assets/icons/success&#45;&#45;white.svg" alt="icon" class="choose-plan__icon">-->
      <!--      </div>-->
      <!--    </div>-->
      <!--  </div>-->
    </div>
    <form class="choose-plan__column promo-column" [formGroup]="formGroup" *ngIf="!isTest && !isCorporate">

      <div class="modal-reg__promocode">
        <div *ngIf="!isDiscountInput" style="cursor: pointer" class="modal-reg__row align-center"
             (click)="openPromocode()">
          <span class="modal-reg__placeholder">{{ 'changePlan.enterPromocode' | translate }}</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.52879 11.9996H6.66882M11.9999 11.9996H12.1399M17.3294 11.9996H17.4694M2.3999 15.2663V8.73294C2.3999 7.55474 3.35503 6.59961 4.53324 6.59961H19.4666C20.6448 6.59961 21.5999 7.55474 21.5999 8.73294V15.2663C21.5999 16.4445 20.6448 17.3996 19.4666 17.3996H4.53324C3.35503 17.3996 2.3999 16.4445 2.3999 15.2663Z"
              stroke="#CFCFCF" stroke-width="2" stroke-linecap="round" />
          </svg>
        </div>

        <div *ngIf="isDiscountInput" class="form__box" [class.error]="error" [class.success]="success">
          <div class="modal-reg__row form__label align-center" style="cursor:pointer;" (click)="clearPromocode()">
            <span class="modal-reg__placeholder">{{ 'changePlan.cancel' | translate }}</span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18M18 18L6 6" stroke="#CFCFCF" stroke-width="2" stroke-linecap="round" />
            </svg>
          </div>

          <div class="choose-plan__row">
            <input formControlName="discountCoupon" class="form__input" type="text">
            <span class="promo-btn" (click)="getPlans()">{{ 'changePlan.submitPromocode' | translate }}</span>
          </div>
          <span class="form__valid" *ngIf="error">{{ error }}</span>
          <span class="form__valid" *ngIf="success">{{ success }}</span>
        </div>
      </div>

      <div class="choose-plan__row">
        <input class="choose-plan__checkbox" formControlName="isExtendedOptions" type="checkbox">
        <span [innerHTML]="'changePlan.managementFee' | translate:{fee: '250 ' + currency}"></span>
      </div>
    </form>

    <div class="choose-plan__column">
      <div *ngIf="totalPrice && !isTest && !isCorporate" class="choose-plan__total-price">
        {{ 'changePlan.total' | translate }}: <b>{{ totalPrice }} {{ currency }}</b></div>
    </div>

    <div class="modal-reg__buttons">
      <button type="button" class="btn btn--bg btn--tertiary btn--modal"
              matStepperPrevious>{{ 'regDialog.back' | translate }}
      </button>

      <button type="button" class="btn btn--bg btn--primary btn--modal" (click)="createSubscription()"
              [disabled]="!selectedPlan">{{ (selectedPlan?.slug?.includes('test') ? 'regDialog.next' : 'regDialog.submit') | translate }}
      </button>
    </div>
  </ng-container>
</div>
