import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Country } from '@angular-material-extensions/select-country';

import { getCurrencySymbol } from '@app/shared/helpers/helpers';

@Component({
  selector: 'app-brand-information',
  templateUrl: './brand-information.component.html',
  styleUrls: [ './brand-information.component.scss' ]
})
export class BrandInformationComponent {
  @Input() formGroup: FormGroup;
  @Input() error: any;

  public isDropDownVisible = false;
  public getCurrencySymbol = getCurrencySymbol;
  public phoneCode = '';
  public country = new FormControl();

  public toggleDropDown(): void {
    this.isDropDownVisible = !this.isDropDownVisible;
  }

  public onCountryChange(): void {
    this.formGroup.get('country').markAsTouched();
  }

  public onCountrySelected(country: Country): void {
    this.phoneCode = country.callingCode;
    this.formGroup.patchValue({ country: country.name });
    this.formGroup.patchValue({ callingCode: this.phoneCode });
  }

  public setCurrency(currency: string, event: Event): void {
    event.stopPropagation();
    this.formGroup.patchValue({ currency });
    this.toggleDropDown();
  }
}
