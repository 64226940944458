<section class="video-box mt-160 mb-100">
  <div class="wrapper">
    <div class="title-group">
      <h2>{{'landing.results.title' | translate}}</h2>
      <p class="title-krona">{{'landing.results.videoBlock.groupDescription' | translate}}</p>
    </div>

    <div class="video-box__block">
      <div class="video">
        <video #video width="100%" height="100%" controls preload="auto" poster='assets/img/poster.png'>
          <source src="{{ linkToVideo }}" type="video/mp4">
        </video>
      </div>

      <div class="video-box__wrapper-small">
        <div class="video-box__group-text">
          <span class="video-box__title">{{'landing.results.videoBlock.title' | translate}}</span>
          <span class="video-box__text">{{'landing.results.videoBlock.description' | translate}}</span>

          <button (click)='openDemoDialog()' type="button" class="btn big primary-btn-filled">
            <span class="btn__text">{{'landing.home.buttonFindOutMore' | translate}}</span>
          </button>
        </div>
      </div>

      <img src="assets/img/video-box_bg.jpg" alt="" class="video-box__bg">
    </div>
  </div>
</section>

<section class="perche-sponsorizzare pb-50">
  <div class="wrapper-small">
    <div class="perche-sponsorizzare__colum">
      <div class="perche-sponsorizzare__block">
        <h4>{{'landing.results.whySponsorInfluencer.title' | translate}}</h4>
        <span class="perche-sponsorizzare__text">{{'landing.results.whySponsorInfluencer.description' | translate}}</span>
      </div>

      <div class="perche-sponsorizzare__block">
        <ul class="perche-sponsorizzare-list">
          <li class="perche-sponsorizzare-list__item">{{'landing.results.whySponsorInfluencer.items.0' | translate}}</li>
          <li class="perche-sponsorizzare-list__item">{{'landing.results.whySponsorInfluencer.items.1' | translate}}</li>
          <li class="perche-sponsorizzare-list__item">{{'landing.results.whySponsorInfluencer.items.2' | translate}}</li>
          <li class="perche-sponsorizzare-list__item">{{'landing.results.whySponsorInfluencer.items.3' | translate}}</li>
        </ul>

        <img src="assets/img/perche-sponsorizzare-bg.jpg" alt="" class="perche-sponsorizzare__bg">
      </div>
    </div>

    <img src="assets/img/scegli-quali.png" alt="" class="perche-sponsorizzare__img results">
  </div>
</section>

<section class="garantiamo-risultati notebook-block notebook-block-left pb-100">
  <div class="wrapper-small">
    <img src="assets/img/garantiamo-risultati-phone.png" alt="" class="notebook-block__img">

    <div class="notebook-block__colum">
      <div class="notebook-block__block colum">
        <h2>{{'landing.results.guaranteeResults.title' | translate}}</h2>
        <span class="notebook-block__text mb-40"><b>{{'landing.results.guaranteeResults.description' | translate}}</b></span>
      </div>

      <div class="notebook-block__block colum">
        <div class="notebook-block__group">
          <span class="notebook-block__text" [innerHTML]="'landing.results.guaranteeResults.subDescription' | translate"></span>
        </div>

        <img src="assets/img/La-piattaforma__bg.jpg" alt="" class="notebook-block__bg">
      </div>
    </div>
  </div>
</section>
