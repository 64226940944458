import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapChartComponent } from '@app/common/chart/map-chart/map-chart.component';
import {HighchartsChartModule} from 'highcharts-angular';

@NgModule({
  declarations: [MapChartComponent],
  imports: [
    CommonModule,
    HighchartsChartModule
  ],
  exports: [MapChartComponent]
})
export class MapChartModule { }
