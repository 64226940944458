<section class="invite black-bg">
  <div class="wrapper">
    <div class="invite__title-box">
      <h1>{{ 'inviteInfl.title' | translate }}</h1>
    </div>
  </div>
  <div class="wrapper-large">
    <div class="invite__cont">
      <span class="h1">{{ 'inviteInfl.shareCode' | translate }}:</span>
      <div class="invite__code" >
        <span title="Click to copy code" (click)="copyBrandCode()">{{brandCode}}</span>
      </div>
      <div class="form__box">
        <span class="form__label">{{ 'inviteInfl.enterEmails' | translate }}</span>
        <textarea [formControl]="emailsControl" class="form__textarea"></textarea>
        <div *ngIf="emailsControl.invalid && (emailsControl.dirty || emailsControl.touched)">
          <span style="color: var(--btn-danger)" *ngIf="emailsControl.errors.required">{{ 'inviteInfl.requiredEmails' | translate }}</span>
          <span style="color: var(--btn-danger)" *ngIf="emailsControl.errors.invalidFormat">{{ 'inviteInfl.invalidEmailsFormat' | translate }}</span>
        </div>
      </div>
      <button (click)="inviteInfluencer()" type="button" class="btn btn--bg btn--primary"
      [disabled]="emailsControl.invalid">{{ 'inviteInfl.invite' | translate }}</button>
    </div>
  </div>
</section>
