<app-campaign-wizard-step [stepTitle]="stepTitle" [enabledNextStep]='true' (nextStepClicked)="goToNextStep()" (previousStepClicked)='goToPreviousStep()'>
<form class="new-campaign-form">

  <div class="builder__cont step-5 build-review">
    <div class="builder__head">
      <h2 class="h1">{{'newCampaign.review.mainInfo' | translate }}</h2>
    </div>
    <div class="builder__main">
      <div class="builder__col">
        <div class="build-review__block">
          <span class="build-review__title">{{ 'newCampaign.review.logoTitle' | translate }}</span>
          <div class="build-review__brand">
            <div class="current-brand">
              <span class="current-brand__title">{{'newCampaign.review.campaignName' | translate }}</span>
              <span class="current-brand__name">{{campaignDraft?.campaignName}}</span>
            </div>
          </div>
        </div>
        <div class="build-review__block">
          <span class="build-review__title">{{'newCampaign.review.productDescription' | translate }}</span>
          <span class="build-review__text">{{campaignDraft?.campaignDescription}}</span>
        </div>
        <div class="build-review__block">
          <span class="build-review__title">{{'newCampaign.review.contentLove' | translate }}</span>
          <span class="build-review__text">{{campaignDraft?.contentLove}}</span>
        </div>
        <div class="build-review__block">
<!--          <span class="build-review__title">Where to get our product</span>-->
<!--          <span class="build-review__text">{{campaignDraft?.wherePurchase}}</span>-->
        </div>
        <div class="build-review__block">
          <span class="build-review__title">{{ 'newCampaign.review.houseRules.title' | translate }}</span>
          <ul class="list">
            <li class="list__item" *ngFor="let rule of rules" [innerText]="rule"></li>
          </ul>
        </div>
<!--        <div class="build-review__block">-->
<!--          <ul class="list">-->
<!--            <li class="list__item">With An Interest In: Sports, Fitness & Wellbeing, Books, Magazines & Games, Online Shopping & Entertainment, Animals, Music & Movies, Education & Technology, Photography, Social & Family</li>-->
<!--          </ul>-->
<!--        </div>-->
        <div class="build-review__grid-two">
          <div class="build-review__box">
            <span class="build-review__title">{{'newCampaign.review.do' | translate }}</span>
            <ul class="list">
              <li class="list__item" *ngIf='pubGuides' [innerHTML]='pubGuides'></li>
            </ul>
          </div>
          <div class="build-review__box">
            <span class="build-review__title">{{'newCampaign.review.dont' | translate }}</span>
            <ul class="list">
              <li class="list__item" *ngIf='dontDos' [innerHTML]='dontDos'></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="builder__col">
        <div class="build-review__block">
          <span class="build-review__title">{{'newCampaign.review.heroImage' | translate }}</span>
          <img [src]="campaignDraft?.heroImageUrl ? campaignDraft?.heroImageUrl : campaignDraft?.heroImage" alt="hero img" class="build-review__hero-img">
        </div>
        <div class="build-review__block">
          <span class="build-review__title">{{'newCampaign.review.tags' | translate }}</span>
          <div class="review-tags">

            <div *ngIf='campaignDraft?.preTags?.instagram?.trim()'>
              <img src="assets/img/sc_icons/instagram--black.svg" alt="icon" class="review-tags__icon">
              <div class="review-tags__box">
                <span class="review-tags__tag" *ngFor='let tag of extractTags(campaignDraft?.preTags?.instagram)'>{{tag}}</span>
              </div>
            </div>

            <div *ngIf='campaignDraft?.preTags?.facebook?.trim()'>
              <img src="assets/img/sc_icons/facebook--black.svg" alt="icon" class="review-tags__icon">
              <div class="review-tags__box">
                <span class="review-tags__tag" *ngFor='let tag of extractTags(campaignDraft?.preTags?.facebook)'>{{tag}}</span>
              </div>
            </div>

<!--            <div *ngIf='campaignDraft?.preTags?.linkedin?.trim()'>-->
<!--              <img src="assets/img/sc_icons/linkedin&#45;&#45;black.svg" alt="icon" class="review-tags__icon">-->
<!--              <div class="review-tags__box">-->
<!--                <span class="review-tags__tag" *ngFor='let tag of extractTags(campaignDraft?.preTags?.linkedin)'>{{tag}}</span>-->
<!--              </div>-->
<!--            </div>-->

            <div *ngIf='campaignDraft?.preTags?.tiktok?.trim()'>
              <img src="assets/img/sc_icons/tiktok--black.svg" alt="icon" class="review-tags__icon">
              <div class="review-tags__box">
                <span class="review-tags__tag" *ngFor='let tag of extractTags(campaignDraft?.preTags?.tiktok)'>{{tag}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="build-review__block">
          <span class="build-review__title"
                *ngIf="campaignDraft?.moodImages">{{ 'newCampaign.review.moodBoard' | translate }}</span>
          <div class="review-mood">
            <img *ngFor='let moodImage of campaignDraft?.moodImages || moodImages;' [src]="moodImage?.image" alt="image" class="review-mood__img">
          </div>
        </div>
      </div>
    </div>
  </div>

</form>
</app-campaign-wizard-step>
