<div class="modal-block modal-contact-brand">
  <div class="modal__cont">
    <span class="modal__title">{{"campaignOverview.contactBrand.dialog.contactBrand" | translate}}</span>

    <form action="#" method="post" class="form">
      <div class="contact-brand__cont">
        <div class="contact-brand__tile-wrap">

          <div class="contact-brand-tile" *ngFor='let post of posts; let index = index; trackBy: trackByIndex;'>
            <img [src]="post.postImageInfo.imageUrl" alt="post image" class="contact-brand-tile__img">
            <div class="contact-brand-tile__group" *ngIf='isPriceShown && !isVoucher'>
              <div class="contact-brand-tile__colum">
                <span class="contact-brand-tile__text">{{"campaignOverview.contactBrand.dialog.price" | translate}}</span>
              </div>
              <div class="contact-brand-tile__colum">
                <span class="contact-brand-tile__text">€</span>
                <label>
                  <input type="number" name="input-{{index}}" class="form__input" [(ngModel)]="postPrices[index].price" [value]="post.postPriceFinal">
                </label>
              </div>
            </div>
          </div>

        </div>
        <div class="contact-brand__price" *ngIf='isPriceShown && !isVoucher'>
          <span class="contact-brand__text"><b>{{"campaignOverview.contactBrand.dialog.cost" | translate}}</b></span>
          <span class="contact-brand__text"><b>€ {{getTotalCost()}}</b></span>
          <span class="contact-brand__text-info">{{"campaignOverview.contactBrand.techFee" | translate}}</span>
        </div>
      </div>

      <div class="contact-brand__box">
        <mat-slide-toggle [(ngModel)]='isBrandEmail' *ngIf='brandOwner && isBrandAccountFeature' [ngModelOptions]="{standalone: true}">Use brand owner email</mat-slide-toggle>
        <div class="form__box">
          <span class="form__title">{{"campaignOverview.contactBrand.email" | translate}}</span>
          <label>
            <input type="email" required name='brandEmail' [ngClass]='{"disable" : isBrandEmail}' [disabled]='isBrandEmail' [(ngModel)]='brandEmail' class="form__input" [placeholder]='"campaignOverview.contactBrand.brandEmailPlaceholder" | translate'>
          </label>

          <ng-container *featureFlag="'multipleBrandEmails'">
            <label>
              <input type="email" name='brandEmail2' [(ngModel)]='brandEmail2' [disabled]='isBrandEmail' class="form__input" [placeholder]='"campaignOverview.contactBrand.brandEmailPlaceholder" | translate'>
            </label>
            <label>
              <input type="email" name='brandEmail3' [(ngModel)]='brandEmail3' [disabled]='isBrandEmail' class="form__input" [placeholder]='"campaignOverview.contactBrand.brandEmailPlaceholder" | translate'>
            </label>
          </ng-container>
        </div>
        <div class="form__box" *ngIf='brandOwner && isBrandAccountFeature'>
          <span class="form__title">{{"campaignOverview.contactBrand.brandEmail" | translate}}</span>
          <label>
            <input type="email" [disabled]='true' [ngClass]='{"disable" : !isBrandEmail}' required name='brandOwner' [value]='brandOwner' class="form__input" [placeholder]='"Brand owner"'>
          </label>
        </div>
        <label class="checked" *ngIf='isPriceShown && !isVoucher'>
          <input type="checkbox" class="checked__input" [checked]="showPrice" (change)="showPrice = !showPrice">
          <span class="checked__square"></span>
          <span class="checked__text">{{"campaignOverview.contactBrand.showPrice" | translate}}</span>
        </label>
        <button type="submit" class="btn big primary-btn-filled" [disabled]='isSendDisable' (click)='contactBrand()'>
          <span class="btn__text">{{"campaignOverview.contactBrand.dialog.send" | translate}}</span>
        </button>
      </div>

    </form>
  </div>
  <div (click)='ref.close()' class="ngneat-close-dialog"><svg viewBox="0 0 329.26933 329" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path></svg></div>

<!--  <div class="modal-close" (click)='ref.close()'>-->
<!--    <svg width="45" height="45" viewBox="0 0 45 45" fill="currentColor">-->
<!--      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 45C10.0736 45 0 34.9264 0 22.5C0 10.0736 10.0736 0 22.5 0C34.9264 0 45 10.0736 45 22.5C45 34.9264 34.9264 45 22.5 45ZM22.5 40.9091C32.667 40.9091 40.9091 32.6671 40.9091 22.5C40.9091 12.333 32.667 4.09092 22.5 4.09092C12.3329 4.09092 4.09087 12.333 4.09087 22.5C4.09087 32.6671 12.3329 40.9091 22.5 40.9091ZM15.7645 32.1282L22.5 25.3927L29.2354 32.1282L32.1281 29.2355L25.3927 22.5L32.1281 15.7646L29.2354 12.8719L22.5 19.6073L15.7645 12.8719L12.8718 15.7646L19.6073 22.5L12.8718 29.2355L15.7645 32.1282Z"/>-->
<!--    </svg>-->
<!--  </div>-->
</div>
