import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {getCampaignDraft, selectBrand, selectCampaignDraft, selectUserInfo} from '@app/store';
import {BrandModel, CampaignModel, UserModel} from '@app/shared/model';
import { AppState } from '@store/app.state';
import * as campaignDraftActions from '@store/campaign-draft';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { TooltipPosition } from '@angular/material/tooltip';
import { CommonService } from '@app/services/common.service';
import { CampaignService } from '@app/services/campaign.service';
import { FeatureFlagsService } from '@app/services/feature-flag.service';
import { takeUntil } from 'rxjs/operators';
import { noWhitespaceValidator } from '@app/shared/helpers/validate-empty-spaces';

@Component({
  selector: 'app-campaign-step',
  templateUrl: './campaign-step.component.html',
  styleUrls: ['./campaign-step.component.scss'],
})
export class CampaignStepComponent implements OnDestroy, OnInit {
  stepTitle = 'campaign';
  campaignFormSubscription: Subscription;
  destroyed$ = new Subject<boolean>();
  imageHeroChangedEvent: any = '';
  croppedHeroImage = '';
  showHeroCropper = false;
  heroImageUrl = '';

  languages = [
    {
      code: 'it',
      name: 'Italiano',
    },
    {
      code: 'en',
      name: 'English',
    },
  ];

  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[1]);
  campaignWalletTypes = [];
  isCampaignWalletFeatureEnabled: false;
  editCampaignMode = false;

  campaignForm = this.fb.group(
    {
      brandCompanyName: [ '', [ Validators.required, noWhitespaceValidator ] ],
      campaignName: [ '', [ Validators.required, noWhitespaceValidator ] ],
      campaignDescription: [ '', [ Validators.required, noWhitespaceValidator ] ],
      campaignLink: [ '', [ Validators.required, noWhitespaceValidator, this.linkValidator ] ],
      objective: [''],
      language: [ '', [ Validators.required, noWhitespaceValidator ] ],
      campaignWalletTypeId: [''],
      campaignBudget: [null],
      brandEmail: [''],
    },
    {
      updateOn: 'change',
    }
  );
  brandNameInput = this.campaignForm.get('brandCompanyName');
  campaignNameInput = this.campaignForm.get('campaignName');
  campaignDescriptionInput = this.campaignForm.get('campaignDescription');
  campaignLinkInput = this.campaignForm.get('campaignLink');
  objectivesNameInput = this.campaignForm.get('objective');
  campaignWalletInput = this.campaignForm.get('campaignWalletTypeId');
  campaignBudget = this.campaignForm.get('campaignBudget');
  brandEmail = this.campaignForm.get('brandEmail');
  language = this.campaignForm.get('language');
  currentRoute: string;
  submitted = false;
  objectives: any[];
  isBrandAccountFeature = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private commonService: CommonService,
    private campaignService: CampaignService,
    private featureFlagService: FeatureFlagsService
  ) {}

  ngOnInit(): void {
    this.isCampaignWalletFeatureEnabled = this.featureFlagService.isFeatureEnabled('campaignWallet');
    this.store.dispatch(getCampaignDraft());

    this.campaignFormSubscription = this.store
      .pipe(select(selectCampaignDraft))
      .subscribe((campaignDraft: CampaignModel) => {
        this.campaignForm.patchValue(campaignDraft, { emitEvent: false });
        this.heroImageUrl = campaignDraft.heroImageUrl ? campaignDraft.heroImageUrl : campaignDraft.heroImage;
        if (campaignDraft.brandEmail) {
          this.brandEmail.disable();
        }
      });

    this.commonService.getObjectives().subscribe(({ objective }) => {
      this.objectives = objective;
    });

    if (this.router.routerState.snapshot.url.includes('edit')) {
      this.editCampaignMode = true;
    }

    if (!this.editCampaignMode) {
      this.store.pipe(select(selectBrand), takeUntil(this.destroyed$)).subscribe((brand: BrandModel) => {
        const { company } = brand;
        this.campaignForm.patchValue({ brandCompanyName: company });
      });
    }

    // Feature-flag:campaignWallet
    if (this.isCampaignWalletFeatureEnabled && !this.editCampaignMode) {
      this.campaignService.getCampaignWalletTypes().subscribe(({ campaignWalletType }) => {
        this.campaignWalletTypes = campaignWalletType;
      });
    }

    this.store.pipe(select(selectUserInfo), takeUntil(this.destroyed$)).subscribe((user: UserModel) => {
      if (user && user.email === 'brands@flytrendy.com') {
        this.isBrandAccountFeature = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.campaignFormSubscription.unsubscribe();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private linkValidator(control: FormControl): { [key: string]: any } | null {
    const link = control.value ? control.value.trim() : '';

    if (!link) {
      return { empty: true };
    }

    const linkRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

    if (!linkRegex.test(link)) {
      return { invalidLink: true };
    }

    return null;
  }

  goToNextStep(): void {
    const briefUrl = this.editCampaignMode? 'brief' : '../brief';

    this.saveCampaignDraft();
    this.router.navigate([briefUrl], { relativeTo: this.activatedRoute });
  }
  goToPreviousStep(): void {
    this.saveCampaignDraft();
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }
  saveCampaignDraft(): void {
    this.submitted = true;

    const campaign = {
      brandCompanyName: this.brandNameInput.value,
      campaignName: this.campaignNameInput.value,
      campaignDescription: this.campaignDescriptionInput.value,
      campaignLink: this.campaignLinkInput.value,
      objective: this.objectivesNameInput.value,
      language: this.language.value,
      campaignWalletTypeId: this.campaignWalletInput.value,
      campaignBudget: this.campaignBudget.value,
      brandEmail: this.brandEmail.value,
    } as Partial<CampaignModel>;

    if (this.croppedHeroImage) {
      campaign.heroImage = this.croppedHeroImage;
      campaign.heroImageUrl = '';
    }

    this.store.dispatch(campaignDraftActions.patchDraftCampaign({ campaign }));
  }
  fileHeroChangeEvent(event: any): void {
    this.imageHeroChangedEvent = event;
  }

  imageHeroCropped(event: ImageCroppedEvent): void {
    this.croppedHeroImage = event.base64;
  }
  deleteHeroImage(): void {
    this.croppedHeroImage = '';
    this.heroImageUrl = '';
    this.showHeroCropper = false;
  }
  enableNextStep(): boolean {
    return (
      this.campaignForm.valid &&
      (!!this.croppedHeroImage || !!this.heroImageUrl) &&
      this.campaignForm.valid
    );
  }
  imageHeroLoaded(): void {
    this.showHeroCropper = true;
  }
}
