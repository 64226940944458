import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HighchartsChartModule} from 'highcharts-angular';
import { HorizontalBarChartComponent } from '@app/common/chart/horizontal-bar-chart/horizontal-bar-chart.component';

@NgModule({
  declarations: [HorizontalBarChartComponent],
  imports: [
    CommonModule,
    HighchartsChartModule
  ],
  exports: [HorizontalBarChartComponent]
})
export class HorizontalBarChartModule { }
