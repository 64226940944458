import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'floor'
})
export class FloorPipe implements PipeTransform {
  transform(num: number): number {
    if (!num) {
      return 0;
    }
    // Round down if more than 2 values after the decimal point
    if (this.countDecimalPlaces(num) > 2) {
      return Math.floor(num * 100) / 100;
    } else {
      return num;
    }
  }

  private countDecimalPlaces(num: number): number {
    const decimalIndex = num.toString().indexOf('.');
    return decimalIndex >= 0 ? num.toString().length - decimalIndex - 1 : 0;
  }
}
