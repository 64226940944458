import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-plan-item',
  templateUrl: './plan-item.component.html'
})
export class PlanItemComponent {
  @Input() currency: string;
  @Input() isActive: boolean;
  @Input() planCost: number;
  @Input() planInfluencers: number;
  @Input() planInfo: string;
  @Input() planSlug: string;
  @Input() planTitle: string;
  @Input() planTooltip: string;

  public get isTrial(): boolean {
    return this.planSlug?.toLowerCase().includes('test');
  }

  public get isCorporate(): boolean {
    return this.planSlug?.toLowerCase().includes('corporate');
  }
}
