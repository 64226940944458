import { BrandCurrencyModel } from '@app/shared/model/brand-currency.model';
import {AccountPlan} from "@app/shared/types/account-plan.enum";

export class BrandModel {
  billingCompany: string;
  brandCurrency: BrandCurrencyModel;
  ccEmail: string;
  company: string;
  createdDatetime: Date;
  currency: string;
  funds: number;
  id: number;
  isAgency: boolean;
  iva: string;
  locationCity: string;
  locationCountry: string;
  locationPostCode: number;
  locationState: string;
  mobile?: any;
  origin?: any;
  pecUnivoco?: any;
  phone?: any;
  streetAddress: string;
  userEmail: number;
  userName: string;
  platformName?: string;
  user?: number;
  isPrivate: boolean;
  privateInfluencers?: any[];
  brandOwner: string;
  brandCode: string;
  brandLogo: string;
  subscription: SubscriptionModel;
}


export class SubscriptionModel{
  plan: AccountPlan;
  status: 'pending' | 'active' | 'cancelled';
  subscribed_at: Date;
  id: number;
  active_until: string;
}
