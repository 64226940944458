import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BrandModel, CampaignModel, UserModel } from '@app/shared/model';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { selectCampaignDraft, selectUserInfo } from '@app/store';
import { take, takeUntil } from 'rxjs/operators';
import * as campaignDraftActions from '@store/campaign-draft';
import * as userActions from '@store/user';
import * as brandActions from '@store/brand';
import { FormBuilder, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { LoginDialogComponent } from '@app/landing/dialogs/login/login-dialog/login-dialog.component';
import { DialogService } from '@ngneat/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@app/services/user.service';
import { HelperService } from '@app/services/helper.service';
import { CONFIG } from '@config/configuration';

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss'],
})
export class SubmitComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();
  private editCampaignMode = false;
  private policyAgreed: false;
  private termsAgreed: false;

  stepTitle = 'submit';
  campaignDraft: CampaignModel;
  openUntilDate: Date | string;
  publishBriefApp: Date | string;
  userEmail: string;
  todayDate: Date = new Date();
  // country = '';

  publish = {
    now: true,
    later: false,
  };

  accountType = {
    brand: true,
    agency: false,
  };

  countries = [];

  campaignForm = this.fb.group(
    {
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      companyName: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      billingCompany: ['', [Validators.required]],
      streetAddress: ['', [Validators.required]],
      city: ['', [Validators.required]],
      postCode: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      iva: ['', [Validators.required]],
      region: ['', [Validators.required]],
      country: [''],
      pecUnivoco: ['', [Validators.required]],
      openUntilDate: ['', [Validators.required]],
      publishBriefApp: [''],
      ccEmail: [''],
    },
    {
      updateOn: 'change',
      validators: [this.checkIfMatchingPasswords('password', 'confirmPassword')],
    }
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private actions: Actions,
    private toastr: ToastrService,
    private dialog: DialogService,
    private translate: TranslateService,
    private userService: UserService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.countries = CONFIG.platform.countries;
    // this.country = this.countries[0];
    this.store
      .pipe(select(selectCampaignDraft), takeUntil(this.destroyed$))
      .subscribe((campaignDraft: CampaignModel) => {
        if (campaignDraft) {
          this.campaignDraft = campaignDraft;
          this.openUntilDate = campaignDraft.openUntilDate;
          this.publishBriefApp = campaignDraft.publishBriefApp;
          this.campaignForm.patchValue(campaignDraft, { emitEvent: false });
        }
      });

    this.store.pipe(select(selectUserInfo), takeUntil(this.destroyed$)).subscribe((user: UserModel) => {
      if (user) {
        this.userEmail = user.email;
      }
    });

    if (this.router.routerState.snapshot.url.includes('edit')) {
      this.editCampaignMode = true;
    }

    this.subscribeCreateUser();
    this.subscribeCreateNewBrand();
  }
  private subscribeLogIn(): void {
    this.userService.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.submitCampaignDraft();
      }
    });
  }
  private patchCountry(): void {
    this.campaignForm.patchValue({ country: 'Italy' });
  }
  private checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string): any {
    return this.helperService.checkIfMatchingPasswords(passwordKey, passwordConfirmationKey);
  }
  private subscribeCreateUser(): void {
    this.actions.pipe(ofType(userActions.createNewUserSuccess), takeUntil(this.destroyed$)).subscribe(({ profile }) => {
      const brand = this.createBrand(profile);
      this.store.dispatch(brandActions.createNewBrand({ brand }));
    });

    this.actions.pipe(ofType(userActions.createNewUserFail), takeUntil(this.destroyed$)).subscribe(({ error }) => {
      const userExistMsg = this.translate.instant('newCampaign.submitMsg.userExists');
      // @ts-ignore
      if (error.user && error.user.email) {
        this.toastr
          .info(userExistMsg)
          .onHidden.pipe(take(1))
          .subscribe(() => {
            this.dialog.open(LoginDialogComponent, {
              closeButton: true,
            });
            this.subscribeLogIn();
          });
      }
    });
  }

  private subscribeCreateNewBrand(): void {
    this.actions.pipe(ofType(brandActions.createNewBrandSuccess), takeUntil(this.destroyed$)).subscribe(() => {
      this.submitCampaignDraft();
    });
  }

  private submitCampaignDraft(): void {
    const { publishBriefApp, userEmail } = this;
    const openUntilDate = this.campaignForm.get('openUntilDate').value;
    const campaign = {
      publishBriefApp,
      openUntilDate,
    } as Partial<CampaignModel>;

    this.store.dispatch(campaignDraftActions.patchDraftCampaign({ campaign }));
    this.store.dispatch(campaignDraftActions.editCampaignDraftSubmit({ campaign: this.campaignDraft, userEmail }));
  }
  checkFieldError(fieldName): boolean {
    return !!(!this.campaignForm.get(fieldName).valid && this.campaignForm.get(fieldName).touched);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  agreePolicy(event): void {
    this.policyAgreed = event.currentTarget.checked;
  }

  agreeTerms(event): void {
    this.termsAgreed = event.currentTarget.checked;
  }

  goToPreviousStep(): void {
    this.saveCampaignDraft();
    this.router.navigate(['../review'], { relativeTo: this.activatedRoute });
  }

  goToNextStep(): void {
    this.campaignForm.markAllAsTouched();

    if (!this.campaignForm.valid) {
      return;
    }
    this.saveCampaignDraft();
    this.createNewUser();
  }

  saveCampaignDraft(): void {
    const { publishBriefApp, openUntilDate } = this;

    const campaign = {
      publishBriefApp,
      openUntilDate,
    } as Partial<CampaignModel>;

    this.store.dispatch(campaignDraftActions.patchDraftCampaign({ campaign }));
  }

  createBrand(createdUser: UserModel): Partial<BrandModel> {
    const company = this.campaignForm.get('companyName').value;
    const currency = this.campaignForm.get('currency').value;
    const billingCompany = this.campaignForm.get('billingCompany').value;
    const iva = this.campaignForm.get('iva').value;
    const isAgency = this.accountType.agency;
    const locationCity = this.campaignForm.get('city').value;
    const locationPostCode = this.campaignForm.get('postCode').value;
    const locationState = this.campaignForm.get('region').value;
    const mobile = this.campaignForm.get('mobile').value;
    const origin = this.campaignForm.get('country').value;
    const pecUnivoco = this.campaignForm.get('pecUnivoco').value;
    const phone = this.campaignForm.get('phoneNumber').value;
    const platformName = CONFIG.platformName;
    const streetAddress = this.campaignForm.get('streetAddress').value;
    const user = createdUser.id;

    return {
      company,
      currency,
      billingCompany,
      iva,
      isAgency,
      locationCity,
      locationPostCode,
      locationState,
      mobile,
      origin,
      pecUnivoco,
      phone,
      platformName,
      streetAddress,
      user,
    };
  }

  createNewUser(): void {
    const currency = this.campaignForm.get('currency').value;
    const email = this.campaignForm.get('email').value;
    const firstName = this.campaignForm.get('firstName').value;
    const lastName = this.campaignForm.get('lastName').value;
    const password = this.campaignForm.get('password').value;
    const username = this.campaignForm.get('email').value;
    const isBrand = true;

    const user = {
      currency,
      email,
      firstName,
      isBrand,
      lastName,
      password,
      username,
    } as Partial<UserModel>;

    this.store.dispatch(userActions.createNewUser({ user }));
  }

  enableNextStep(): boolean {
    this.openUntilDate = this.campaignForm.get('openUntilDate').value;
    this.publishBriefApp = this.campaignForm.get('publishBriefApp').value;
    return (
      this.termsAgreed &&
      this.policyAgreed &&
      this.openUntilDate &&
      (this.publish.now || (this.publish.later && this.publishBriefApp))
    );
  }
}
