import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ImageDialogComponent} from "@app/dialogs/image-dialog/image-dialog.component";
import { PdfViewerModule } from 'ng2-pdf-viewer';



@NgModule({
  declarations: [ImageDialogComponent],
  imports: [
    CommonModule,
    PdfViewerModule,
  ]
})
export class ImageDialogModule { }
