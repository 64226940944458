<section class="typical-page">
  <div class="wrapper">
    <h1 class="big">404</h1>

    <div class="typical-page__block">
      <div class="typical-page__group">
        <span class="typical-page__title">{{ 'notFound.title' | translate }}</span>
        <a routerLink='/' class="typical-page__subtitle">{{ 'notFound.back' | translate }}</a>
      </div>

      <img src="assets/img/video-box_bg.png" alt="" class="typical-page__bg">
    </div>
  </div>
</section>
