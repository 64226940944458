
<header>
  <div class="wrapper-large">
    <div class="header">
      <button type="button" class="menu-btn">
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.0003 45.8337C36.4587 45.8337 45.8337 36.4587 45.8337 25.0003C45.8337 13.542 36.4587 4.16699 25.0003 4.16699C13.542 4.16699 4.16699 13.542 4.16699 25.0003C4.16699 36.4587 13.542 45.8337 25.0003 45.8337Z" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M19.1045 30.8962L30.8962 19.1045" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M30.8962 30.8962L19.1045 19.1045" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <a href="/" class="logo">
        <img src="assets/images/logo.svg" alt="logo">
      </a>
      <div class="nav">
        <a routerLink="about" class="nav__link">{{ 'topBar.aboutUs' | translate }}</a>
        <a href="#" class="nav__link" (click)="openDemoDialog()">{{ 'topBar.demo' | translate }}</a>
        <a routerLink="contact" class="nav__link">{{ 'topBar.contactUs' | translate }}</a>
      </div>
      <button type="button" class="btn btn--bg btn--primary" (click)="openRegistrationDialog()">{{ 'topBar.createAccount' | translate }}</button>
      <button (click)='openLoginDialog(); openedMenu = false;' type="button" class="btn-text btn--primary">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 10.75C17.59 10.75 17.25 10.41 17.25 10V8C17.25 4.85 16.36 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.75 10.41 6.41 10.75 6 10.75C5.59 10.75 5.25 10.41 5.25 10V8C5.25 5.1 5.95 1.25 12 1.25C18.05 1.25 18.75 5.1 18.75 8V10C18.75 10.41 18.41 10.75 18 10.75Z"/>
          <path d="M12 19.25C10.21 19.25 8.75 17.79 8.75 16C8.75 14.21 10.21 12.75 12 12.75C13.79 12.75 15.25 14.21 15.25 16C15.25 17.79 13.79 19.25 12 19.25ZM12 14.25C11.04 14.25 10.25 15.04 10.25 16C10.25 16.96 11.04 17.75 12 17.75C12.96 17.75 13.75 16.96 13.75 16C13.75 15.04 12.96 14.25 12 14.25Z"/>
          <path d="M17 22.75H7C2.59 22.75 1.25 21.41 1.25 17V15C1.25 10.59 2.59 9.25 7 9.25H17C21.41 9.25 22.75 10.59 22.75 15V17C22.75 21.41 21.41 22.75 17 22.75ZM7 10.75C3.42 10.75 2.75 11.43 2.75 15V17C2.75 20.57 3.42 21.25 7 21.25H17C20.58 21.25 21.25 20.57 21.25 17V15C21.25 11.43 20.58 10.75 17 10.75H7Z"/>
        </svg>
        <span>{{ 'topBar.login' | translate }}</span>
      </button>
    </div>
    <div class="header-mobile">
      <a href="/" class="logo">
        <img src="assets/images/logo.svg" alt="logo">
      </a>
      <button type="button" class="menu-btn">
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.25 14.583H43.75" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
          <path d="M6.25 25H43.75" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
          <path d="M6.25 35.417H43.75" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
        </svg>
      </button>
    </div>
    <div class="overlay" style="display: none"></div>
  </div>
</header>
