import { NgModule } from '@angular/core';
import { PlanItemComponent } from '@app/shared-features/plan-item/plan-item.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PlanItemComponent],
  imports: [ CommonModule, TranslateModule ],
  exports: [PlanItemComponent],
})

export class PlanItemComponentModule { }
