<div class="modal modal-payment modal--padding">
  <ng-container *ngIf="loading">
    <div class='progress-spinner'>
      <mat-spinner diameter='70'></mat-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading">
    <div class="modal-reg__header">
      <div class="modal-reg__caption-box">
        <span class="h1">{{ 'paymentsDialog.planCheckout' | translate }}</span>
      </div>
      <span class="h2"
            [innerHTML]="'paymentsDialog.chosePlan' | translate:{ planName: selectedPlan?.name.toLowerCase().split('_')[0] }"></span>
      <div class="modal-reg__caption-box">
        <!--    <span class="h4 change-plan-button" *ngIf="subscription.status" (click)="openChangePlanDialog()">Change your plan</span>-->
        <span class="h4 change-plan-button"
              (click)="openChangePlanDialog()">{{ 'paymentsDialog.changePlan' | translate }}</span>
      </div>
    </div>
  </ng-container>

  <div class="modal-reg__main">
    <div class="modal__wrapper">
      <ng-container *ngIf="!loading">
        <p class="modal__text"><b>{{ planDescription[selectedPlan?.index] }}</b></p>
        <div class="modal-payment__group">
          <span class="modal-payment__title">{{ 'paymentsDialog.willCharge' | translate }}</span>
          <span class="modal-payment__price">
          <b><span class="modal-payment__price"
                   [innerHTML]="currencySymbol"></span>{{ subscriptionSum }}</b>/{{ 'paymentsDialog.month' | translate }} </span>
        </div>
      </ng-container>
      <div class="modal-payment__group-btn" [class.hidden]="isPaymentOngoing">
        <div id="paypal-button"></div>
      </div>
      <div class='progress-spinner' [class.hidden]="!isPaymentOngoing">
        <mat-spinner diameter='70'></mat-spinner>
      </div>
    </div>
  </div>
</div>
