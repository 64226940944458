import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-pie-chart',
  template: `
    <highcharts-chart
      [Highcharts]='Highcharts'
      [options]='chartOptions'
      style="width: 100%; display: block;"
    >
    </highcharts-chart>`,
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  @Input() data: { y: number; name: string }[];
  @Input() colors: string[];

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};

  constructor() { }

  ngOnInit(): void {
    this.initChart(this.data);
  }

  private initChart(data): void {
    this.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        style: {
          fontFamily: 'font-family: \'OpenSans\', sans-serif',
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      colors: this.colors,
      tooltip: {
        pointFormat: '{point.percentage:.2f}%'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b><br>{point.percentage:.2f} %',
          }
        }
      },
      series: [{
        name: 'Share',
        type: undefined,
        data
      }]
    };
  }

}
