import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopBarComponent } from './top-bar.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MenuAuthorizedModule } from '@app/common/top-bar/menu-authorized/menu-authorized.module';
import { MenuModule } from '@app/common/top-bar/menu/menu.module';
import { PaypalDialogModule } from '@app/dialogs/paypal-dialog/paypal-dialog.module';

@NgModule({
  declarations: [TopBarComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatDialogModule,
    MenuAuthorizedModule,
    MenuModule,
    PaypalDialogModule,
  ],
  exports: [TopBarComponent],
})
export class TopBarModule {}
