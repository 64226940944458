import {
  PostFeedbackModel,
  PostImageInfoModel,
  PostModel,
  PostStatusTypes,
  SocialAccountModel,
} from '@app/shared/model';
import { PostDto, PostFeedbackDto, SocialAccountDto } from '@app/shared/dto';
// @ts-ignore
import { environment } from '@env/environment';
const S3_IMAGES = environment.S3_IMAGES;
const S3_VIDEOS = environment.S3_VIDEOS;

// tslint:disable-next-line:only-arrow-functions
const getPostVideoUrl = function (postResponse: PostDto | PostFeedbackDto): string {
  if (
    postResponse.video_timestamp &&
    !postResponse.video_timestamp.includes('http:') &&
    !postResponse.video_timestamp.includes('https:')
  ) {
    return 'https://' + postResponse.video_timestamp;
  }

  return postResponse.video_timestamp;
};

export class PostAdapter {
  public static createPost(postResponse: PostDto): PostModel {
    if (!postResponse) {
      return null;
    }

    const imageInfo = this.getPostImageInfo(postResponse);
    const histories = this.getPostHistory(postResponse);
    const videoUrl = getPostVideoUrl(postResponse);
    const socialAccountInfo = getSocialAccount(postResponse.social_account_info, postResponse);
    const carouselItems = this.setCarouselItems(postResponse);
    const model = new PostModel();

    model.carouselPostMedia = carouselItems;
    model.brandFeedback = postResponse.brand_feedback;
    model.brandName = postResponse.brand_name;
    model.campaignId = postResponse.campaign;
    model.createdDatetime = postResponse.created_datetime;
    model.updatedDatetime = postResponse.updated_date;
    model.id = postResponse.id;
    model.influencerInterests = postResponse.influencer_interests;
    model.influencerRegionCountry = postResponse.influencer_region_country;
    model.postText = postResponse.post_copy;
    model.postDiscount = postResponse.post_discount;
    model.postImageInfo = imageInfo;
    model.postPrice = postResponse.post_price;
    model.postPriceFinal = postResponse.post_price_final;
    model.postPriceTechFee = postResponse.post_price_tech_fee;
    model.postSocialType = postResponse.post_social_type;
    model.seen = postResponse.seen;
    model.socialAccountInfo = socialAccountInfo;
    model.staffStatus = postResponse.staff_status;
    model.status = postResponse.status as PostStatusTypes;
    model.user = postResponse.user;
    model.videoUrl = videoUrl || imageInfo.videoUrl;
    model.isVideo = !!postResponse.video_url || !!imageInfo.videoType;
    model.histories = histories;
    model.selected = false;
    model.updated = postResponse.updated;
    model.showPrices = postResponse.show_prices;
    model.brandOwnerFeedback = postResponse.brand_owner_feedback;
    return model;
  }

  private static getPostHistory(postResponse: PostDto): any {
    const result = {} as any;
    const { histories } = postResponse;
    if (histories && histories[0].feedbacks_history.length) {
      result.feedbacksHistory = histories[0].feedbacks_history;
    }

    if (histories && histories[1] && histories[1].length) {
      result.postsHistory = histories[1];
    }

    return result;
  }

  private static getPostImageInfo(postResponse: PostDto): PostImageInfoModel {
    const imageInfo = postResponse.post_image_info;
    if (!imageInfo) {
      return null;
    }
    const videoType = imageInfo.video_type;
    const { campaign, id } = postResponse;
    let imageUrl = 'https://' + postResponse.image_url;

    if (imageUrl.includes('http://')) {
      imageUrl = imageUrl.replace('http://', '');
    }

    let videoUrl = '';

    if (videoType) {
      videoUrl = 'https://' + postResponse.video_url;
    }

    return {
      height: imageInfo.height,
      imageType: imageInfo.image_type,
      videoType,
      width: imageInfo.width,
      videoUrl: postResponse.video_url || videoUrl,
      imageUrl,
    };
  }

  private static getSocialAccount(postResponse: PostDto): SocialAccountModel {
    const account = {
      accessToken: postResponse.social_account_info.accessToken,
      followers: postResponse.social_account_info.followers,
      id: +postResponse.social_account_info.id,
      instagramId: postResponse.social_account_info.ig_id,
      isBusiness: postResponse.social_account_info.isBusiness,
      name: postResponse.social_account_info.name,
      profilePicture: postResponse.social_account_info.profile_picture,
      username: postResponse.social_account_info.username,
    } as SocialAccountModel;

    if (postResponse.engagement_rate) {
      account.engagementRate = +postResponse.engagement_rate;
    }
    return account;
  }

  private static setCarouselItems(postResponse: PostDto): null | any[] {
    if (!postResponse.carousel_post_media || !postResponse.carousel_post_media.length) {
      return null;
    }

    const carouselMedia = postResponse.carousel_post_media;
    carouselMedia.forEach((item) => {
      item.url = 'https://' + item.url;
      item.isVideo = !!item.mime_type.includes('video');
    });

    return postResponse.carousel_post_media;
  }
}

function getSocialAccount(socialAccount: SocialAccountDto, postResponse?: any): SocialAccountModel {
  const username = socialAccount.username ? socialAccount.username.replace(/\s/g, '') : '';
  let profileUrl = '';

  switch (postResponse.post_social_type) {
    case 'instagram':
    case 'reels':
    case 'instagramstory':
      profileUrl = 'https://www.instagram.com/' + username;
      break;

    case 'facebook':
      profileUrl = 'https://www.facebook.com/' + socialAccount.id;
      break;

    case 'tiktok':
      profileUrl = 'https://www.tiktok.com/' + username;
      break;

    case 'linkedin':
      profileUrl = socialAccount.public_url;
      break;
  }

  return {
    accessToken: socialAccount.accessToken,
    followers: socialAccount.followers,
    id: +socialAccount.id,
    instagramId: socialAccount.ig_id,
    isBusiness: socialAccount.isBusiness || null,
    name: socialAccount.name,
    profilePicture: socialAccount.profile_picture,
    username: socialAccount.username,
    engagementRate: +postResponse?.engagement_rate || null,
    profileUrl,
  };
}

export class PostFeedbackAdapter {
  public static createPostFeedback(postFeedbackResponse: PostFeedbackDto, feedbackDraft: any): PostFeedbackModel {
    if (!postFeedbackResponse) {
      return null;
    }
    const { campaign, id } = postFeedbackResponse;
    const model = new PostFeedbackModel();
    const videoUrl = getPostVideoUrl(postFeedbackResponse);
    model.campaign = campaign;
    model.id = id;
    model.postText = postFeedbackResponse.post_copy;
    model.postSocialType = postFeedbackResponse.post_social_type;
    model.price = postFeedbackResponse.price;
    model.socialAccountInfo = getSocialAccount(postFeedbackResponse.social_account_info, postFeedbackResponse);
    model.postImageUrl = S3_IMAGES + '/post_images/' + campaign + '/' + campaign + '-' + id + '.png';
    model.videoUrl = videoUrl;

    if (feedbackDraft.status) {
      model.status = feedbackDraft.status;
    }

    if (feedbackDraft.feedback) {
      model.feedback = feedbackDraft.feedback;
    }

    return model;
  }
}
