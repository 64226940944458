import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DialogRef, DialogService} from "@ngneat/dialog";
import {AuthService} from "@app/services/auth.service";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "@store/app.state";
import {ForgotPasswordComponent} from "@app/landing/dialogs/forgot-password/forgot-password.component";
import {getUser} from "@app/store";
import {RegistrationDialogComponent} from "@app/dialogs/registration-dialog/registration-dialog.component";
import {CommonService} from "@app/services/common.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.scss']
})
export class RequestDemoComponent implements OnInit {
  requestDemoForm: FormGroup;
  email = '';
  phone = '';
  name = '';
  errorMessage = '';
  // isLoadingResults = false;

  constructor(
    public ref: DialogRef,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.requestDemoForm = this.formBuilder.group({
      email: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      first_name: ['', Validators.required],
    });
  }

  onFormSubmit(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.commonService.requestDemoPresentation(this.requestDemoForm.value).subscribe((res) => {
      this.ref.close();
      this.toaster.success('We will back to you soon', "Your request has been successfully submitted")
    })
  }
}

