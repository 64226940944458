import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignComponent } from '@app/campaign/campaign.component';

import { RouterModule } from '@angular/router';
import { CampaignStepModule } from '@app/campaign/campaign-step/campaign-step.module';
import { BriefStepModule } from '@app/campaign/brief-step/brief-step.module';
import { InfluencersStepModule } from '@app/campaign/influencers-step/influencers-step.module';
import { ReviewStepModule } from '@app/campaign/review-step/review-step.module';
import { SubmitStepModule } from '@app/campaign/submit-step/submit-step.module';
import { CampaignWizardModule } from '@app/campaign/campaign-wizard/campaign-wizard.module';
import {RewardStepModule} from "@app/campaign/reward-step/reward-step.module";

@NgModule({
  declarations: [CampaignComponent],
  imports: [
    CommonModule,
    RouterModule,
    CampaignStepModule,
    BriefStepModule,
    InfluencersStepModule,
    ReviewStepModule,
    RewardStepModule,
    SubmitStepModule,
    CampaignWizardModule,
  ],
  exports: [CampaignComponent],
})
export class CampaignModule {}
