import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommunityInsightsComponent } from '@app/community-insights/community-insights.component';
import { ColumnChartModule } from '@app/common/chart/column-chart/column-chart.module';
import { PieChartModule } from '@app/common/chart/pie-chart/pie-chart.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CommunityInsightsComponent],
  imports: [
    CommonModule,
    ColumnChartModule,
    PieChartModule,
    MatProgressSpinnerModule,
    TranslateModule
  ],
  exports: [CommunityInsightsComponent]
})
export class CommunityInsightsModule { }
