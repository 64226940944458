export enum AccountPlan {
  enterprise = <any>'enterprise',
  starter = <any>'starter',
  pro = <any>'pro',
  test = <any>'test_eur'
}


export const MaxAmountOfMembersObject = {
  enterprise: -1,
  starter: 30,
  pro: 100,
  test: 3,
}
