import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-create-brand',
  templateUrl: './create-brand.component.html',
  styleUrls: ['./create-brand.component.scss']
})
export class CreateBrandComponent implements OnInit {

  @Input() formGroup: FormGroup;
  image: any;
  imageUrl: string | ArrayBuffer;
  constructor() { }

  ngOnInit(): void {
    console.log(this.formGroup)
  }

  addImageToUpload($event) {
    this.image = $event.target.files[0];
    this.renderImage($event.target.files[0]);
  }

  renderImage(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.imageUrl = reader.result;
      this.formGroup.setValue({name: this.formGroup.get('name').value, brandLogo: reader.result})
    }
  }
}
