<div class="modal-post">
  <div class="swiper-button-prev swiper-button-disabled carousel-previous"></div>
  <div class="swiper-button-next carousel-next"></div>
  <div [ngClass]="postData ? 'campaign-review' : 'modal__cont'">
    <swiper
      [slidesPerView]="1"
      [navigation]="true"
      [config]="config"
      [effect]="'fade'"
      [fadeEffect]="{crossFade: true}"
      class="post-swiper"
    >
      <ng-container *ngFor="let edition of editingHistory">
        <ng-template swiperSlide>
          <button (click)="isEditingChange.emit(false)" class="btn primary-btn-filled modal__button">{{'campaignOverview.post.goBack' | translate}}</button>
          <div class="post-info">
            <div class="post-info__colum">
              <div class="modal-post-info">
                <img [src]="profileImage" alt="influencer profile" class="modal-post-info__avatar"
                     (error)="setDefaultProfileImage()">
                <div class="modal-post-info__group">
                  <a [href]="post.socialAccountInfo.profileUrl" target="_blank"
                     class="modal-post-info__title">{{post.socialAccountInfo.name}}</a>
                  <span class="modal-post-info__subtitle"><b>{{post.socialAccountInfo.followers}}</b> {{'campaignOverview.followers' | translate}}</span>
                </div>
              </div>
              <div *ngIf="edition.price">
                <div class="post-info-price__wrapper">
                  <div class="post-info-price">
                    <div class="post-info-price__colum">
                      <span class="post-info-price__text">{{'campaignOverview.post.dialog.media' | translate}}</span>
                      <span class="post-info-price__subtext">{{'campaignOverview.post.dialog.techFee' | translate}}</span>
                    </div>
                    <div class="post-info-price__colum">
                      <span class="post-info-price__text">€ {{edition.price.post_price_with_fees}}</span>
                    </div>
                  </div>

                  <div class="post-info-price">
                    <div class="post-info-price__colum">
                      <span class="post-info-price__text">{{'campaignOverview.post.dialog.iva' | translate}}</span>
                    </div>
                    <div class="post-info-price__colum">
                      <span class="post-info-price__text">€ {{getIva(edition.price.post_price_with_fees)}}</span>
                    </div>
                  </div>

                  <div class="post-info-price-all">
                    <span class="post-info-price-all__text">{{'campaignOverview.post.dialog.total' | translate}}</span>
                    <span class="post-info-price-all__text">€ {{edition.price.post_price_final}}</span>
                  </div>
                </div>
              </div>
              <div *ngIf="feedbacksHistory.length" class="post-editing-info-changes">
                <span class="post-editing-info-changes__title">{{'campaignOverview.post.requestedChanges' | translate}}:</span>

                <div class='post-editing-info-changes__item' *ngFor='let feedback of feedbacksHistory'>
                  <div class='post-editing-info-changes__feedback'>{{feedback.brand_feedback}}</div>
                  <span class="post-editing-info-changes__date">{{feedback.updated_date | date: 'medium'}}</span>
                </div>
              </div>
            </div>

            <div class="post-info__colum">
              <video *ngIf="edition.video_timestamp" controls poster="{{edition.image_url_timestamp}}">
                <source src="{{edition.video_timestamp}}" type="video/mp4">
              </video>

              <img *ngIf="!edition.video_timestamp && edition.image_url_timestamp" [src]="edition.image_url_timestamp" alt="" class="post-info__img">
              <img *ngIf="!edition.video_timestamp && !edition.image_url_timestamp" src="assets/img/common/no_image.png" alt="" class="post-info__img">

              <p class="feedback-block__description"><b>{{edition.post_copy}}</b></p>

              <p *ngIf='edition.post_social_type === "instagram" || edition.post_social_type === "instagramstory"'
                 class="feedback-block__description feedback-block__tags">{{(campaignTags$ | async)?.instagram}}</p>
              <p *ngIf='edition.post_social_type === "tiktok"'
                 class="feedback-block__description feedback-block__tags">{{(campaignTags$ | async)?.tiktok}}</p>
              <p *ngIf='edition.post_social_type === "facebook"'
                 class="feedback-block__description feedback-block__tags">{{(campaignTags$ | async)?.facebook}}</p>
              <span class="post-info__submitted">{{'campaignOverview.post.submited' | translate}}: <b>{{edition.created_datetime | date: 'medium'}}</b></span>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </swiper>
    <div *ngIf='!postData' (click)='ref.close()' class="ngneat-close-dialog"><svg viewBox="0 0 329.26933 329" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path></svg></div>
  </div>
</div>
