import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DialogRef, DialogService } from '@ngneat/dialog';
import { CampaignModel, PostModel, PostStatusTypes, UserModel } from '@app/shared/model';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import {catchError, take, takeUntil} from 'rxjs/operators';
import {
  approveCampaignPost, CampaignPostsState,
  changeCampaignPost,
  declineCampaignPost,
  selectBrandFunds,
  selectCampaignOnly, selectCampaignPosts,
  selectCampaignTags,
  selectUserInfo,
} from '@app/store';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Actions, ofType } from '@ngrx/effects';
import * as campaignOverviewActions from '@app/store';
import { PostReviewChangeComponent } from '@app/dialogs/post-review-change/post-review-change.component';
import { PostDeclineChangeComponent } from '@app/dialogs/post-decline-change/post-decline-change.component';
import { CONFIG } from '@config/configuration';
import { PostService } from '@app/services/post.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureFlagsService } from '@app/services/feature-flag.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-post-overview-dialog',
  templateUrl: './post-overview-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class PostOverviewDialogComponent implements OnInit, OnDestroy {
  post: PostModel;
  IVA: number;
  campaignTags$: Observable<any>;
  profileImage: string;
  destroyed$ = new Subject<boolean>();
  statusChangingProcess = false;
  requestInsightProcess = false;
  brandFunds: number;
  showPrices: boolean;
  disableButtons: boolean;
  copiedUrl = false;
  postUrl: string;
  isAdditionalUser = false;
  featureFlagWalletEnabled = false;
  isEditing = false;
  editingHistory: any[] = [];
  feedbacksHistory: any[] = [];
  private campaign: CampaignModel;
  private isBrandOwner: boolean;
  private campaignPosts: CampaignPostsState;

  constructor(
    public ref: DialogRef,
    public clipboard: Clipboard,
    private store: Store<AppState>,
    private dialog: DialogService,
    private translate: TranslateService,
    private actions: Actions,
    private toastr: ToastrService,
    private postService: PostService,
    private router: Router,
    private route: ActivatedRoute,
    private featureFlagService: FeatureFlagsService
  ) {}

  get isShowPrices(): boolean {
    if (this.isBrandOwner) {
      return (this.post.showPrices) ? (this.showPrices && !this.disableButtons) : false;
    }
    return this.showPrices && !this.disableButtons;
  }

  ngOnInit(): void {
    this.featureFlagWalletEnabled = this.featureFlagService.isFeatureEnabled('campaignWallet');
    const { data } = this.ref;
    this.post = data.post;
    // const { histories } = this.post;
    this.IVA = +(this.post.postPriceTechFee * CONFIG.platform?.iva).toFixed(2);
    this.showPrices = data.showPrices;
    this.profileImage = this.post.socialAccountInfo.profilePicture;
    this.campaignTags$ = this.store.pipe(select(selectCampaignTags));
    const { platformName } = CONFIG;

    this.postUrl = window.location.href + `/post/${this.post.id}`;
    this.postStatusUpdateSuccess();
    this.postStatusUpdateFail();
    this.checkWalletFunds();

    this.store.pipe(select(selectCampaignOnly), takeUntil(this.destroyed$)).subscribe((campaign) => {
      this.campaign = campaign;
    });

    this.store.pipe(select(selectUserInfo), takeUntil(this.destroyed$)).subscribe((user: UserModel) => {
      if (user) {
        this.isAdditionalUser = !!user.isAdditionalBrandUser;
        this.isBrandOwner = user.isBrandOwner;
        if (platformName === 'io_platform' && this.isAdditionalUser) {
          this.disableButtons = true;
        }
      }
    });

    this.postService.getPostHistory(this.post.id).subscribe(({ campaignPost }) => {
      const { histories } = campaignPost;
      this.editingHistory = histories.post_history;
      this.feedbacksHistory = histories.feedbacks_history;

      if (!this.editingHistory) {
        this.editingHistory = [
          {
            created_datetime: this.post.createdDatetime,
            image_url_timestamp: this.post.postImageInfo.imageUrl,
            post_copy: this.post.postText,
            post_social_type: this.post.postSocialType,
            price: {
              post_price: this.post.postPrice,
              post_price_with_fees: this.post.postPriceTechFee,
              post_price_final: this.post.postPriceFinal,
            },
            video_timestamp: this.post.videoUrl ? this.post.videoUrl : '',
          },
        ];
      }

      if (this.editingHistory) {
        this.editingHistory.forEach((history) => {
          if (
            history.image_url_timestamp &&
            !history.image_url_timestamp.includes('http:') &&
            !history.image_url_timestamp.includes('https:')
          ) {
            history.image_url_timestamp = 'https://' + history.image_url_timestamp;
          }

          if (
            history.video_timestamp &&
            !history.video_timestamp.includes('http:') &&
            !history.video_timestamp.includes('https:')
          ) {
            history.video_timestamp = 'https://' + history.video_timestamp;
          }
        });
      }
    });
    this.store.pipe(select(selectCampaignPosts), take(1)).subscribe((posts) => {
      this.campaignPosts = posts;
    })
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  checkWalletFunds(): void {
    this.store.pipe(select(selectBrandFunds), takeUntil(this.destroyed$)).subscribe((funds) => {
      this.brandFunds = funds;
    });
  }

  openImage(url: string): void {
    window.open(url, '_blank');
  }
  public copy(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.clipboard.copy('');
    this.clipboard.copy(this.postUrl);
    this.copiedUrl = true;
    setTimeout(() => {
      this.copiedUrl = false;
    }, 2000);
  }

  public openPostInNewTab(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    window.open(this.postUrl, '_blank');
  }

  postStatusUpdateSuccess(): void {
    this.actions
      .pipe(ofType(campaignOverviewActions.postStatusUpdatedSuccess), takeUntil(this.destroyed$))
      .subscribe((action) => {
        const { status } = action;
        let successMessage;
        const approvedPostMessage = this.translate.instant('campaignOverview.post.dialog.postApprovedSuccessMsg');
        const declinedPostMessage = this.translate.instant('campaignOverview.post.dialog.postDeclinedSuccessMsg');
        const changePostMessage = this.translate.instant('campaignOverview.post.dialog.sendFeedbackSuccessMsg');

        if (status === PostStatusTypes.APPROVED) {
          successMessage = approvedPostMessage;
        }

        if (status === PostStatusTypes.DECLINED) {
          successMessage = declinedPostMessage;
        }

        if (status === PostStatusTypes.PENDING) {
          successMessage = changePostMessage;
        }

        this.statusChangingProcess = false;
        this.ref.close();
        this.toastr.success(successMessage);
      });
  }
  postStatusUpdateFail(): void {
    this.actions
      .pipe(ofType(campaignOverviewActions.postStatusUpdateFail), takeUntil(this.destroyed$))
      .subscribe(() => {
        const postStatusUpdateFail = this.translate.instant('campaignOverview.post.dialog.postStatusUpdateFailMsg');

        this.statusChangingProcess = false;
        this.ref.close();
        this.toastr.error(postStatusUpdateFail);
      });
  }
  setDefaultProfileImage(): void {
    this.profileImage = 'assets/img/icons/user-default.png';
  }
  downloadMedia(postId: number, event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.postService
      .downloadMedia(postId)
      .pipe(
        // @ts-ignore
        catchError((err) => {
          const errorResponse = this.translate.instant('commonMsg.somethingWentWrong');
          this.toastr.warning(errorResponse);
        })
      )
      .subscribe((response) => {
        const successResponse = this.translate.instant('campaignOverview.post.dialog.downloadMediaLinkSuccessMsg');
        this.ref.close();
        this.toastr.success(successResponse);
      });
  }
  approvePost(): void {
    const body = this.translate.instant('campaignOverview.post.dialog.approve');
    const fundsError = this.translate.instant('campaignOverview.post.dialog.insufficientFailMsg');
    const exceedCampaignWalletError = this.translate.instant('campaignOverview.post.dialog.walletExceededMsg');

    if (this.post.postPriceFinal > this.brandFunds) {
      this.dialog.error({ title: '', body: fundsError });
      return;
    }
    const approvedPosts = this.campaignPosts.posts.filter((post) => post.status === 'approved');
    if (!this.campaign.reward.cash && this.campaign.vouchers.active <= approvedPosts.length) {
      const dialog = this.dialog.error({ title: '', body: 'You have no active vouchers to approve this post.\n' +
          'Please, upload the new vouchers to be able to approve new posts.' });
      dialog.afterClosed$.subscribe((res) => {
        this.ref.close();
        this.router.navigate(['/admin/vouchers/create'])
      })
      return;
    }

    // featureFlag: campaign wallet
    if (
      this.featureFlagWalletEnabled &&
      this.campaign.campaignSpent + this.post.postPriceFinal > this.campaign.campaignWallet
    ) {
      this.dialog.error({ title: '', body: exceedCampaignWalletError });
      return;
    }

    this.dialog.confirm({ title: '', body }).afterClosed$.subscribe((confirmed) => {
      if (confirmed) {
        this.statusChangingProcess = true;
        this.store.dispatch(approveCampaignPost({ postId: this.post.id, status: PostStatusTypes.APPROVED }));
      }
    });
  }
  changePost(): void {
    const changePost = this.dialog.open(PostReviewChangeComponent, {
      data: {
        feedback: this.post.brandFeedback,
      },
      closeButton: true,
    });

    changePost.afterClosed$.subscribe((feedback) => {
      if (feedback && feedback.trim() && feedback !== this.post.brandFeedback) {
        this.store.dispatch(changeCampaignPost({ postId: this.post.id, status: PostStatusTypes.PENDING, feedback }));
      }
    });
  }
  declinePost(): void {
    const declinePost = this.dialog.open(PostDeclineChangeComponent, {
      closeButton: true,
    });

    declinePost.afterClosed$.subscribe((declineReason) => {
      if (declineReason && declineReason.trim()) {
        this.store.dispatch(
          declineCampaignPost({ postId: this.post.id, status: PostStatusTypes.DECLINED, feedback: declineReason })
        );
      }
    });
  }

  openPostInsight(event, post): void {
    event.preventDefault();
    event.stopPropagation();
    this.requestInsightProcess = true;
    this.postService
      .getPostInsights(this.post.id)
      .pipe(
        // @ts-ignore
        catchError((err) => {
          this.toastr.warning('Can not get insights for this post');
          this.requestInsightProcess = false;
        })
      )
      .subscribe((insights) => {
        this.requestInsightProcess = false;
        this.ref.close();
        this.postService.setInsights(insights);
        this.router.navigate(['/insights', post.id]);
      });
  }
}
