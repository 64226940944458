import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from '@app/landing/login/login.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LoginComponent],
  imports: [ CommonModule, TranslateModule ],
  exports: [LoginComponent],
})
export class LoginModule {}
