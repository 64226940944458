<app-campaign-wizard-step  (nextStepClicked)="goToNextStep()" [stepTitle]='stepTitle' [enabledNextStep]='enableNextStep()'>
  <form [formGroup]="rewardForm" >

    <div class="builder__cont step-1">
      <div class="builder__head">
        <h2 class="h1">{{ 'newCampaign.rewardStep.title' | translate }}</h2>
      </div>
      <div class="builder__main">
        <div class="reward-box" [ngClass]='{"active" : reward.cash }' (click)="setRewardOption('cash')">
          <div class="reward-box__box">
            <img src="assets/img/sc_icons/moneys.svg" alt="icon" class="reward-box__icon">
            <div class="reward-box__group">
              <span class="reward-box__title">{{ 'newCampaign.rewardStep.cash' | translate }}</span>
              <div class="reward-box__info tooltip-wrap">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 22.3199C7.72 22.3199 7.42998 22.2499 7.16998 22.1099C6.59998 21.8099 6.25 21.2099 6.25 20.5699V19.15C3.23 18.84 1.25 16.6199 1.25 13.4399V7.43994C1.25 3.99994 3.56 1.68994 7 1.68994H17C20.44 1.68994 22.75 3.99994 22.75 7.43994V13.4399C22.75 16.8799 20.44 19.1899 17 19.1899H13.23L8.96997 22.03C8.67997 22.22 8.34 22.3199 8 22.3199ZM7 3.17993C4.42 3.17993 2.75 4.84993 2.75 7.42993V13.43C2.75 16.01 4.42 17.68 7 17.68C7.41 17.68 7.75 18.02 7.75 18.43V20.56C7.75 20.69 7.83 20.75 7.88 20.78C7.93001 20.81 8.03001 20.84 8.14001 20.77L12.59 17.81C12.71 17.73 12.86 17.68 13.01 17.68H17.01C19.59 17.68 21.26 16.01 21.26 13.43V7.42993C21.26 4.84993 19.59 3.17993 17.01 3.17993H7Z"/>
                  <path d="M11.9998 12.1099C11.5898 12.1099 11.2498 11.7699 11.2498 11.3599V11.1499C11.2498 9.9899 12.0998 9.41989 12.4198 9.19989C12.7898 8.94989 12.9098 8.7799 12.9098 8.5199C12.9098 8.0199 12.4998 7.60986 11.9998 7.60986C11.4998 7.60986 11.0898 8.0199 11.0898 8.5199C11.0898 8.9299 10.7498 9.2699 10.3398 9.2699C9.92984 9.2699 9.58984 8.9299 9.58984 8.5199C9.58984 7.1899 10.6698 6.10986 11.9998 6.10986C13.3298 6.10986 14.4098 7.1899 14.4098 8.5199C14.4098 9.6599 13.5698 10.2299 13.2598 10.4399C12.8698 10.6999 12.7498 10.8699 12.7498 11.1499V11.3599C12.7498 11.7799 12.4098 12.1099 11.9998 12.1099Z"/>
                  <path d="M12 14.6001C11.58 14.6001 11.25 14.2601 11.25 13.8501C11.25 13.4401 11.59 13.1001 12 13.1001C12.41 13.1001 12.75 13.4401 12.75 13.8501C12.75 14.2601 12.42 14.6001 12 14.6001Z"/>
                </svg>
                <p>{{ 'newCampaign.rewardStep.rewardInfo' | translate }}</p>
                <span class="tooltip left">{{ 'newCampaign.rewardStep.cashTooltip' | translate }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="reward-box" [ngClass]='{"active" : reward.voucher_fixed > 0 }' (click)="setRewardOption('voucher_fixed')">
          <div class="reward-box__box">
            <img src="assets/img/sc_icons/ticket-star.svg" alt="icon" class="reward-box__icon">
            <div class="reward-box__group">
              <span class="reward-box__title">{{ 'newCampaign.rewardStep.fixed' | translate }}</span>
            </div>
          </div>
          <div class="reward-box__input-box">
            <input type="number" formControlName="voucherFixed" (input)="onRewardFieldChanged('voucherFixed')" class="form__input" [placeholder]="'newCampaign.rewardStep.fixedPlaceholder' | translate:{currency: currency}">
          </div>
        </div>
        <div class="reward-box" [ngClass]='{"active" : reward.voucher_percentage > 0 }' (click)="setRewardOption('voucher_percentage')">
          <div class="reward-box__box">
            <img src="assets/img/sc_icons/ticket-star.svg" alt="icon" class="reward-box__icon">
            <div class="reward-box__group">
              <span class="reward-box__title">{{ 'newCampaign.rewardStep.percent' | translate }}</span>
            </div>
          </div>
          <div class="reward-box__input-box">
            <input type="number" formControlName="voucherPercentage" (input)="onRewardFieldChanged('voucherPercentage')" class="form__input" [placeholder]="'newCampaign.rewardStep.percentPlaceholder' | translate">
<!--            <img src="assets/img/sc_icons/Accept.svg" alt="icon" class="reward-box__img">-->
          </div>
        </div>
      </div>
    </div>

  </form>

</app-campaign-wizard-step>
