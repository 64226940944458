import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { TranslateModule } from '@ngx-translate/core';
import {MatSelectCountryModule} from "@angular-material-extensions/select-country";

@NgModule({
  declarations: [HomeComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatSelectCountryModule
    ]
})
export class HomeModule { }
