import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AfterRedirectComponent } from './after-redirect/after-redirect.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";



@NgModule({
  declarations: [
    AfterRedirectComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ]
})
export class PaymentsModule { }
