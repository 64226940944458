import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DialogRef } from '@ngneat/dialog';

import { PlanDto, SubscriptionDto } from '@app/shared/dto';
import { PaymentsService } from '@app/services/payments.service';
import { PlanDescriptionEnum } from '@app/dialogs/payments-dialog/plan-descriptions';

@Component({
  selector: 'app-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: [ './choose-plan.component.scss' ]
})
export class ChoosePlanComponent implements OnInit, OnDestroy {
  @Input() public plans: PlanDto[];
  @Input() public currency: string;
  @Input() public formGroup: FormGroup;
  @Output() public submitEmitter = new EventEmitter<SubscriptionDto>();

  private destroyed$ = new Subject<void>();
  public planDescriptions = PlanDescriptionEnum;
  public stepNum = 3;

  public selectedPlan: PlanDto;
  public success: string;
  public error: string;

  public isTest = false;
  public isCorporate = false;
  public isDiscountInput = false;

  private paypalPlanId: string;
  public totalPrice: number;

  public loading = false;

  constructor(
    public ref: DialogRef,
    public router: Router,
    private paymentsService: PaymentsService
  ) {}

  ngOnInit(): void {
    this.formGroup.controls.isExtendedOptions.valueChanges
    .pipe(takeUntil(this.destroyed$))
    .subscribe((fee) => this.getPlans(fee));

    if (!this.selectedPlan) {
      this.manageFormControls('disable');
    }
    this.getInitialPlans();
  }

  ngOnDestroy(): void {
    this.formGroup.reset({}, { emitEvent: false });
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private getInitialPlans(): void {
    this.loading = true;
    this.paymentsService.getPlans({ currency: this.currency })
    .pipe(take(1))
    .subscribe((res) => {
      if (res?.plan?.length) {
        this.plans = res.plan.sort((a, b) => a?.price - b?.price);
      }
      this.loading = false;
    });
  }

  private manageFormControls(action: 'disable' | 'enable'): void {
    const { isExtendedOptions, discountCoupon } = this.formGroup.controls;
    if (action === 'enable') {
      discountCoupon.enable({ emitEvent: false });
      isExtendedOptions.enable({ emitEvent: false });
    } else {
      discountCoupon.disable({ emitEvent: false });
      isExtendedOptions.disable({ emitEvent: false });
    }
  }

  public getPlans(isExtendedOptions?: boolean): void {
    const form = this.formGroup.value;

    const params = {
      fee: isExtendedOptions ?? form.isExtendedOptions,
      promocode: form.discountCoupon,
      isTrial: this.isTest,
      currency: this.currency,
    };

    this.paymentsService.getPlans(params)
    .pipe(take(1))
    .subscribe((res) => {
      const selectedPlan = res.plan.find((plan) => plan.slug === this.selectedPlan?.slug);
      if (!selectedPlan) {
        this.manageFormControls('disable');
        return;
      }
      this.manageFormControls('enable');

      if (selectedPlan.error) {
        this.success = null;
        this.error = selectedPlan.error ? 'Wrong discount code' : null;
      } else {
        this.error = null;
        this.success = selectedPlan.message ? 'Discount ' + selectedPlan.message + '%' : null;
      }

      if (!selectedPlan.slug.includes('test')) {
        this.totalPrice = selectedPlan.price;
        this.paypalPlanId = selectedPlan.paypal_plan_id;
      }
    });
  }

  public selectPlan(plan: PlanDto): void {
    if (this.selectedPlan === plan) {
      return;
    }
    this.selectedPlan = plan;

    const isTest = plan.slug.includes('test');
    const isCorporate = plan.slug.toLowerCase().includes('corporate');

    if (isTest || isCorporate) {
      this.isTest = isTest;
      this.isCorporate = isCorporate;

      this.isDiscountInput = false;
      this.totalPrice = 0;
      this.paypalPlanId = '';
      this.formGroup.setValue({
        isExtendedOptions: false,
        discountCoupon: ''
      }, { emitEvent: false });
    } else {
      if (this.stepNum === 3) {
        this.isTest = false;
        this.isCorporate = false;
      }
    }

    this.getPlans();
  }

  public openPromocode(): void {
    if (this.selectedPlan) {
      this.isDiscountInput = true;
    }
  }

  public clearPromocode(): void {
    this.isDiscountInput = false;
    this.formGroup.controls.discountCoupon.setValue('');
    this.getPlans();
  }

  public createSubscription(): void {
    const isTest = this.selectedPlan.slug.includes('test');
    if (isTest) {
      this.isTest = true;
      this.plans = this.plans.filter((plan) => !plan?.slug?.includes('test') && !plan?.slug?.toLowerCase().includes('corporate'));
      this.stepNum++;
      this.selectedPlan = null;
      this.manageFormControls('disable');
      return;
    }

    const form = this.formGroup.value;
    const subscriptionData: SubscriptionDto = {
      plan: this.selectedPlan?.slug,
      currency: this.currency,
      discount_coupon: form.discountCoupon,
      is_extended_options: form.isExtendedOptions,
      is_trial: this.isTest,
      is_corporate: this.isCorporate,
      subscription_sum: this.totalPrice,
      paypal_plan_id: this.paypalPlanId
    };

    this.submitEmitter.emit(subscriptionData);
  }
}
