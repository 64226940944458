import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectCampaignAnalytics, selectCampaignOnly, selectUserInfo } from '@app/store';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '@store/app.state';
import { Subject } from 'rxjs';
import { CampaignAnalyticsModel } from '@app/shared/model/campaign-analytics.model';
import { CampaignModel, UserModel } from '@app/shared/model';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { TranslateService } from '@ngx-translate/core';
import { CONFIG } from '@config/configuration';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit {
  destroyed$ = new Subject<boolean>();
  analytics: CampaignAnalyticsModel;
  campaign: CampaignModel;
  isAdditionalUser = false;
  hideAnalytics = false;

  public lineChartColors:Array<any> = [
    {
      backgroundColor: '#59D79A',
    },
    {
      backgroundColor: '#45E1E1',
    },
    {
      backgroundColor: '#59D79A',
    },
    {
      backgroundColor: '#45E1E1',
    },

  ];

  public contentOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      labels: {
        fontSize: 13,
        usePointStyle: true,
        padding: 50,
      }
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
          },
          ticks: {
            beginAtZero: true, // @ts-ignore
            stepValue: 10,
          },
        },
      ],
    },
  };

  public barChartType: ChartType = 'bar';
  public totalContentLabel: Label[] = ["Total Published Content"];
  public totalEngagementLabel: Label[] = ["Total Engagements"];
  public totalTrueReachLabel: Label[] = ["True reach and Impressions"];


  public totalContentData: ChartDataSets[] = [
    { data: [0], label: 'Instagram' },
    { data: [0], label: 'Facebook' },
    { data: [0], label: 'TikTok' },
    { data: [0], label: 'Linkedin' },
  ];

  public totalEngagementsData: ChartDataSets[] = [
    { data: [0], label: 'Likes' },
    { data: [0], label: 'Comments' },
    { data: [0], label: 'Shares/Saves' },
    { data: [0], label: 'Views' },
  ];

  public totalTrueReachData: ChartDataSets[] = [
    { data: [0], label: 'True reach' },
    { data: [0], label: 'Impressions' },
  ];

  public isEmptyTotalContentData = false;
  public isEmptyEngagementsData = false;
  public isEmptyTrueReachData = false;

  constructor(private store: Store<AppState>, private translate: TranslateService) {}

  ngOnInit(): void {
    const { platformName } = CONFIG;

    this.store.pipe(select(selectCampaignAnalytics), takeUntil(this.destroyed$)).subscribe((analytics) => {
      this.analytics = analytics;

      if (analytics) {
        const TOTAL_CONTENT = this.totalContentDataAdapter(analytics.totalContent);
        const TOTAL_ENGAGEMENTS = this.totalEngagementsAdapter(analytics.breakdownContent);

        if (TOTAL_CONTENT?.length) {
          this.totalContentData = [
            {
              data: [ TOTAL_CONTENT[0] ],
              label: 'Instagram'
            },

            {
              data: [ TOTAL_CONTENT[1] ],
              label: 'TikTok'
            },

            {
              data: [ TOTAL_CONTENT[2] ],
              label: 'Facebook'
            },

            {
              data: [ TOTAL_CONTENT[3] ],
              label: 'Linkedin'
            }
          ];
        } else {
          this.isEmptyTotalContentData = true;
        }


        if (TOTAL_ENGAGEMENTS?.length) {
          this.totalEngagementsData = [
            {
              data: [ this.analytics.numberLikes ],
              label: 'Likes'
            },

            {
              data: [ this.analytics.numberComments ],
              label: 'Comments'
            },

            {
              data: [ TOTAL_ENGAGEMENTS[2] ],
              label: 'Shares/Saves'
            },

            {
              data: [ TOTAL_ENGAGEMENTS[3] ],
              label: 'Views'
            }
          ];
          this.isEmptyEngagementsData = !TOTAL_ENGAGEMENTS.some((engagement) => engagement);
        }

        this.totalTrueReachData = [
          {
            data: [analytics.trueReach],
            label: "True Reach",
          },

          {
            data: [analytics.totalImpressions],
            label: "Impressions",
          },
        ];
        this.isEmptyTrueReachData = !analytics.trueReach && !analytics.totalImpressions;
      }
    });

    this.store.pipe(select(selectCampaignOnly), takeUntil(this.destroyed$)).subscribe((campaign) => {
      this.campaign = campaign;
    });

    this.store.pipe(select(selectUserInfo), takeUntil(this.destroyed$)).subscribe((user: UserModel) => {
      if (user) {
        // disable analytics for territorial IOL users
        this.isAdditionalUser = !!user.isAdditionalBrandUser;
        if (platformName === 'io_platform' && this.isAdditionalUser) {
          this.hideAnalytics = true;
        }
      }
    });
  }

  totalEngagementsAdapter(totalEngagements): any {
    // index 0 - likes, 1 - comments, 2 - Shares/Saves, 3 - Views
    let engagements = [0, 0, 0, 0];

    if (!totalEngagements) {
      return;
    }

    if (totalEngagements && totalEngagements.likes) {
      // @ts-ignore
      engagements[0] = <number>Object.values(totalEngagements.likes).reduce((a: number, b: number) => a + b, 0);
    }

    if (totalEngagements && totalEngagements.comments) {
      // @ts-ignore
      engagements[1] = <number>Object.values(totalEngagements.comments).reduce((a: number, b: number) => a + b, 0);
    }

    if (totalEngagements && totalEngagements['shares/saved']) {
      // @ts-ignore
      engagements[2] = <number>Object.values(totalEngagements['shares/saved']).reduce((a: number, b: number) => a + b, 0);
    }

    if (totalEngagements && totalEngagements.views) {
      // @ts-ignore
      engagements[3] = <number>Object.values(totalEngagements.views).reduce((a: number, b: number) => a + b, 0);
    }
    return engagements;
  }

  totalContentDataAdapter(totalContent): any {
    // index 0 - instagram, 1 - tiktok, 2 - facebook, 3 - linkedin
    let content = [0,0,0,0];

    if (!totalContent || totalContent.length == 0) {
      return;
    }

    totalContent.forEach((item) => {

      if (item.post_social_type === 'instagram') {
        content[0] += item.number_of_types;
      }

      if (item.post_social_type === 'instagramstory') {
        content[0] += item.number_of_types;
      }

      if (item.post_social_type === 'reels') {
        content[0] += item.number_of_types;
      }

      if (item.post_social_type === 'tiktok') {
        content[1] = item.number_of_types;
      }

      if (item.post_social_type === 'facebook') {
        content[2] = item.number_of_types;
      }

      if (item.post_social_type === 'linkedin') {
        content[3] = item.number_of_types;
      }
    });

    return content;

  }
}
