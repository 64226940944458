import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TopBarModule } from './top-bar/top-bar.module';
import { FooterModule } from './footer/footer.module';
import { MapChartModule } from '@app/common/chart/map-chart/map-chart.module';
import { ColumnChartModule } from '@app/common/chart/column-chart/column-chart.module';
import { HorizontalBarChartModule } from '@app/common/chart/horizontal-bar-chart/horizontal-bar-chart.module';

@NgModule({
  imports: [RouterModule, CommonModule, BrowserModule, TopBarModule, FooterModule],
  exports: [TopBarModule, FooterModule, MapChartModule, ColumnChartModule, HorizontalBarChartModule],
})
export class CommonUIModule {}
