<!--<div class='tile' [ngClass]='isTabs ? "two-tabs": ""' *ngIf='!linkedinInterests'>-->
<div class="social-media builder__grid-two">
  <div class="interest-tile" (click)='toggleInterests("Sports, Fitness & Wellbeing")' [ngClass]='{"active" : checkInterest("Sports, Fitness & Wellbeing")}'>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.67 14H4C2.9 14 2 14.9 2 16V22H8.67V14Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.3302 10H10.6602C9.56016 10 8.66016 10.9 8.66016 12V22H15.3302V12C15.3302 10.9 14.4402 10 13.3302 10Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.9981 17H15.3281V22H21.9981V19C21.9981 17.9 21.0981 17 19.9981 17Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.5183 2.07007L13.0483 3.13006C13.1183 3.28006 13.3083 3.42006 13.4683 3.44006L14.4283 3.60007C15.0383 3.70007 15.1883 4.15005 14.7483 4.58005L13.9983 5.33005C13.8683 5.46005 13.7983 5.70006 13.8383 5.87006L14.0483 6.79007C14.2183 7.52007 13.8283 7.80007 13.1883 7.42007L12.2883 6.89007C12.1283 6.79007 11.8583 6.79007 11.6983 6.89007L10.7983 7.42007C10.1583 7.80007 9.76828 7.52007 9.93828 6.79007L10.1483 5.87006C10.1883 5.70006 10.1183 5.45005 9.98828 5.33005L9.24828 4.59006C8.80828 4.15006 8.94828 3.71005 9.56828 3.61005L10.5283 3.45007C10.6883 3.42007 10.8783 3.28007 10.9483 3.14007L11.4783 2.08005C11.7683 1.50005 12.2283 1.50007 12.5183 2.07007Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span class="interest-tile__text">{{ 'newCampaign.interests.sportsFitnessWellbeing' | translate}}</span>
  </div>

  <div class="interest-tile" (click)='toggleInterests("Books, Magazines & Games")' [ngClass]='{"active" : checkInterest("Books, Magazines & Games")}'>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 16.7399V4.66994C22 3.46994 21.02 2.57994 19.83 2.67994H19.77C17.67 2.85994 14.48 3.92994 12.7 5.04994L12.53 5.15994C12.24 5.33994 11.76 5.33994 11.47 5.15994L11.22 5.00994C9.44 3.89994 6.26 2.83994 4.16 2.66994C2.97 2.56994 2 3.46994 2 4.65994V16.7399C2 17.6999 2.78 18.5999 3.74 18.7199L4.03 18.7599C6.2 19.0499 9.55 20.1499 11.47 21.1999L11.51 21.2199C11.78 21.3699 12.21 21.3699 12.47 21.2199C14.39 20.1599 17.75 19.0499 19.93 18.7599L20.26 18.7199C21.22 18.5999 22 17.6999 22 16.7399Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 5.48999V20.49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.75 8.48999H5.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.5 11.49H5.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span class="interest-tile__text">Books, Magazines & Games</span>
  </div>

  <div class="interest-tile" (click)='toggleInterests("Music & Movies")' [ngClass]='{"active" : checkInterest("Music & Movies")}'>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 16.7399V4.66994C22 3.46994 21.02 2.57994 19.83 2.67994H19.77C17.67 2.85994 14.48 3.92994 12.7 5.04994L12.53 5.15994C12.24 5.33994 11.76 5.33994 11.47 5.15994L11.22 5.00994C9.44 3.89994 6.26 2.83994 4.16 2.66994C2.97 2.56994 2 3.46994 2 4.65994V16.7399C2 17.6999 2.78 18.5999 3.74 18.7199L4.03 18.7599C6.2 19.0499 9.55 20.1499 11.47 21.1999L11.51 21.2199C11.78 21.3699 12.21 21.3699 12.47 21.2199C14.39 20.1599 17.75 19.0499 19.93 18.7599L20.26 18.7199C21.22 18.5999 22 17.6999 22 16.7399Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 5.48999V20.49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.75 8.48999H5.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.5 11.49H5.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span class="interest-tile__text">{{ 'newCampaign.interests.musicMovies' | translate}}</span>
  </div>

  <div class="interest-tile" (click)='toggleInterests("Online Shopping & Entertainment")' [ngClass]='{"active" : checkInterest("Online Shopping & Entertainment")}'>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 8H21"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span class="interest-tile__text">Online Shopping & Entertainment</span>
  </div>

  <div class="interest-tile" (click)='toggleInterests("Photography")' [ngClass]='{"active" : checkInterest("Photography")}'>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.76017 22H17.2402C20.0002 22 21.1002 20.31 21.2302 18.25L21.7502 9.99C21.8902 7.83 20.1702 6 18.0002 6C17.3902 6 16.8302 5.65 16.5502 5.11L15.8302 3.66C15.3702 2.75 14.1702 2 13.1502 2H10.8602C9.83017 2 8.63017 2.75 8.17017 3.66L7.45017 5.11C7.17017 5.65 6.61017 6 6.00017 6C3.83017 6 2.11017 7.83 2.25017 9.99L2.77017 18.25C2.89017 20.31 4.00017 22 6.76017 22Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.5 8H13.5"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 18C13.79 18 15.25 16.54 15.25 14.75C15.25 12.96 13.79 11.5 12 11.5C10.21 11.5 8.75 12.96 8.75 14.75C8.75 16.54 10.21 18 12 18Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span class="interest-tile__text">Photography</span>
  </div>

  <div class="interest-tile" (click)='toggleInterests("Food, Lifestyle & Events")' [ngClass]='{"active" : checkInterest("Food, Lifestyle & Events")}'>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.79 10.47V17.79C17.79 20.12 15.9 22 13.58 22H6.21C3.89 22 2 20.11 2 17.79V10.47C2 8.14001 3.89 6.26001 6.21 6.26001H13.58C15.9 6.26001 17.79 8.15001 17.79 10.47Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.5 4V2.25"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.5 4V2.25"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.5 4V2.25"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21.9991 13.16C21.9991 15.48 20.1091 17.37 17.7891 17.37V8.94995C20.1091 8.94995 21.9991 10.83 21.9991 13.16Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2 12H17.51"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span class="interest-tile__text">Food, Lifestyle & Events </span>
  </div>

  <div class="interest-tile" (click)='toggleInterests("Animals")' [ngClass]='{"active" : checkInterest("Animals")}'>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.40869 16.75C4.16869 19.64 6.34869 22 9.24869 22H14.0387C17.2987 22 19.5387 19.37 18.9987 16.15C18.4287 12.77 15.1687 10 11.7387 10C8.01869 10 4.71869 13.04 4.40869 16.75Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.4688 7.5C11.8495 7.5 12.9688 6.38071 12.9688 5C12.9688 3.61929 11.8495 2.5 10.4688 2.5C9.08804 2.5 7.96875 3.61929 7.96875 5C7.96875 6.38071 9.08804 7.5 10.4688 7.5Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.3008 8.69995C18.4053 8.69995 19.3008 7.80452 19.3008 6.69995C19.3008 5.59538 18.4053 4.69995 17.3008 4.69995C16.1962 4.69995 15.3008 5.59538 15.3008 6.69995C15.3008 7.80452 16.1962 8.69995 17.3008 8.69995Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21 12.7C21.8284 12.7 22.5 12.0284 22.5 11.2C22.5 10.3715 21.8284 9.69995 21 9.69995C20.1716 9.69995 19.5 10.3715 19.5 11.2C19.5 12.0284 20.1716 12.7 21 12.7Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.96875 10.7C5.07332 10.7 5.96875 9.80452 5.96875 8.69995C5.96875 7.59538 5.07332 6.69995 3.96875 6.69995C2.86418 6.69995 1.96875 7.59538 1.96875 8.69995C1.96875 9.80452 2.86418 10.7 3.96875 10.7Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span class="interest-tile__text">Animals</span>
  </div>

  <div class="interest-tile" (click)='toggleInterests("Education & Technology")' [ngClass]='{"active" : checkInterest("Education & Technology")}'>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.6 20H14.4C18.4 20 20 18.4 20 14.4V9.6C20 5.6 18.4 4 14.4 4H9.6C5.6 4 4 5.6 4 9.6V14.4C4 18.4 5.6 20 9.6 20Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.5 17H13.5C16 17 17 16 17 13.5V10.5C17 8 16 7 13.5 7H10.5C8 7 7 8 7 10.5V13.5C7 16 8 17 10.5 17Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.01172 4V2"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 4V2"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 4V2"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20 8H22"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20 12H22"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20 16H22"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 20V22"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.0117 20V22"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.01172 20V22"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2 8H4"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2 12H4"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2 16H4"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.9987 9.69995L11.0587 11.34C10.8487 11.7 11.0187 12 11.4387 12H12.5587C12.9787 12 13.1487 12.3 12.9387 12.66L11.9987 14.3"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span class="interest-tile__text">Education & Technology</span>
  </div>

  <div class="interest-tile" (click)='toggleInterests("Travel & Cars")' [ngClass]='{"active" : checkInterest("Travel & Cars")}'>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.51 2.82996H8.49C6 2.82996 5.45 4.06996 5.13 5.58996L4 11H20L18.87 5.58996C18.55 4.06996 18 2.82996 15.51 2.82996Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21.9888 19.82C22.0988 20.99 21.1588 22 19.9588 22H18.0788C16.9988 22 16.8488 21.54 16.6588 20.97L16.4588 20.37C16.1788 19.55 15.9988 19 14.5588 19H9.43876C7.99876 19 7.78876 19.62 7.53876 20.37L7.33876 20.97C7.14876 21.54 6.99876 22 5.91876 22H4.03876C2.83876 22 1.89876 20.99 2.00876 19.82L2.56876 13.73C2.70876 12.23 2.99876 11 5.61876 11H18.3788C20.9988 11 21.2888 12.23 21.4288 13.73L21.9888 19.82Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 8H3"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21 8H20"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 3V5"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.5 5H13.5"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6 15H9"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15 15H18"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span class="interest-tile__text">Travel & Cars</span>
  </div>

  <div class="interest-tile" (click)='toggleInterests("Social & Family")' [ngClass]='{"active" : checkInterest("Social & Family")}'>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.9981 7.16C17.9381 7.15 17.8681 7.15 17.8081 7.16C16.4281 7.11 15.3281 5.98 15.3281 4.58C15.3281 3.15 16.4781 2 17.9081 2C19.3381 2 20.4881 3.16 20.4881 4.58C20.4781 5.98 19.3781 7.11 17.9981 7.16Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.9675 14.44C18.3375 14.67 19.8475 14.43 20.9075 13.72C22.3175 12.78 22.3175 11.24 20.9075 10.3C19.8375 9.59004 18.3075 9.35003 16.9375 9.59003"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.97047 7.16C6.03047 7.15 6.10047 7.15 6.16047 7.16C7.54047 7.11 8.64047 5.98 8.64047 4.58C8.64047 3.15 7.49047 2 6.06047 2C4.63047 2 3.48047 3.16 3.48047 4.58C3.49047 5.98 4.59047 7.11 5.97047 7.16Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.0014 14.44C5.6314 14.67 4.12141 14.43 3.06141 13.72C1.65141 12.78 1.65141 11.24 3.06141 10.3C4.13141 9.59004 5.6614 9.35003 7.0314 9.59003"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.9981 14.63C11.9381 14.62 11.8681 14.62 11.8081 14.63C10.4281 14.58 9.32812 13.45 9.32812 12.05C9.32812 10.62 10.4781 9.46997 11.9081 9.46997C13.3381 9.46997 14.4881 10.63 14.4881 12.05C14.4781 13.45 13.3781 14.59 11.9981 14.63Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.08875 17.78C7.67875 18.72 7.67875 20.26 9.08875 21.2C10.6888 22.27 13.3087 22.27 14.9087 21.2C16.3187 20.26 16.3187 18.72 14.9087 17.78C13.3187 16.72 10.6888 16.72 9.08875 17.78Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span class="interest-tile__text">Social & Family</span>
  </div>
<!--  <div (click)='toggleInterests("Books, Magazines & Games")' class='tile__box interests small'-->
<!--       [ngClass]='{"active" : checkInterest("Books, Magazines & Games")}'>-->
<!--    <span class='interests__text'>{{ 'newCampaign.interests.booksMagazinesGames' | translate}}</span>-->
<!--    <svg class='interests__svg' width='70' height='70' viewBox='0 0 70 70'>-->
<!--      <path-->
<!--        d='M61.6667 33.3333H56.6667V20C56.6667 16.3333 53.6667 13.3333 50 13.3333H36.6667V8.33333C36.6667 3.73333 32.9333 0 28.3333 0C23.7333 0 20 3.73333 20 8.33333V13.3333H6.66667C3 13.3333 0.0333333 16.3333 0.0333333 20V32.6667H5C9.96667 32.6667 14 36.7 14 41.6667C14 46.6333 9.96667 50.6667 5 50.6667H0V63.3333C0 67 3 70 6.66667 70H19.3333V65C19.3333 60.0333 23.3667 56 28.3333 56C33.3 56 37.3333 60.0333 37.3333 65V70H50C53.6667 70 56.6667 67 56.6667 63.3333V50H61.6667C66.2667 50 70 46.2667 70 41.6667C70 37.0667 66.2667 33.3333 61.6667 33.3333Z' />-->
<!--    </svg>-->
<!--  </div>-->

<!--  <div (click)='toggleInterests("Education & Technology")' class='tile__box interests small'-->
<!--       [ngClass]='{"active" : checkInterest("Education & Technology")}'>-->
<!--    <span class='interests__text'>{{ 'newCampaign.interests.educationTechnology' | translate}}</span>-->
<!--    <svg class='interests__svg' width='70' height='70' viewBox='0 0 70 70'>-->
<!--      <path-->
<!--        d='M46.6667 23.3333H23.3333V46.6667H46.6667V23.3333ZM38.8889 38.8889H31.1111V31.1111H38.8889V38.8889ZM70 31.1111V23.3333H62.2222V15.5556C62.2222 11.2778 58.7222 7.77778 54.4444 7.77778H46.6667V0H38.8889V7.77778H31.1111V0H23.3333V7.77778H15.5556C11.2778 7.77778 7.77778 11.2778 7.77778 15.5556V23.3333H0V31.1111H7.77778V38.8889H0V46.6667H7.77778V54.4444C7.77778 58.7222 11.2778 62.2222 15.5556 62.2222H23.3333V70H31.1111V62.2222H38.8889V70H46.6667V62.2222H54.4444C58.7222 62.2222 62.2222 58.7222 62.2222 54.4444V46.6667H70V38.8889H62.2222V31.1111H70ZM54.4444 54.4444H15.5556V15.5556H54.4444V54.4444Z' />-->
<!--    </svg>-->
<!--  </div>-->


<!--  <div (click)='toggleInterests("Food, Lifestyle & Events")' class='tile__box interests small'-->
<!--       [ngClass]='{"active" : checkInterest("Food, Lifestyle & Events")}'>-->
<!--    <span class='interests__text'>{{ 'newCampaign.interests.foodLifestyleEvents' | translate}}</span>-->
<!--    <svg class='interests__svg' width='71' height='70' viewBox='0 0 71 70'>-->
<!--      <path-->
<!--        d='M54.3065 70H59.5907C62.2647 70 64.4611 67.9627 64.7794 65.3524L70.0318 12.8922H54.1155V0H47.8445V12.8922H32.0236L32.9786 20.3411C38.422 21.8372 43.5152 24.543 46.5712 27.5352C51.1551 32.0555 54.3065 36.7349 54.3065 44.3747V70ZM0 66.8167V63.6653H47.8445V66.8167C47.8445 68.5675 46.412 70 44.6294 70H3.2151C1.43247 70 0 68.5675 0 66.8167ZM47.8445 44.5339C47.8445 19.0678 0 19.0678 0 44.5339H47.8445ZM0.0636652 50.9322H47.8126V57.2988H0.0636652V50.9322Z' />-->
<!--    </svg>-->
<!--  </div>-->

<!--  <div (click)='toggleInterests("Travel & Cars")' class='tile__box interests small'-->
<!--       [ngClass]='{"active" : checkInterest("Travel & Cars")}'>-->
<!--    <span class='interests__text'>{{ 'newCampaign.interests.travelCars' | translate}}</span>-->
<!--    <svg class='interests__svg' width='67' height='70' viewBox='0 0 67 70'>-->
<!--      <path-->
<!--        d='M66.5 49V42L38.5 24.5V5.25C38.5 2.345 36.155 0 33.25 0C30.345 0 28 2.345 28 5.25V24.5L0 42V49L28 40.25V59.5L21 64.75V70L33.25 66.5L45.5 70V64.75L38.5 59.5V40.25L66.5 49Z' />-->
<!--    </svg>-->
<!--  </div>-->

<!--  <div (click)='toggleInterests("Animals")' class='tile__box interests small'-->
<!--       [ngClass]='{"active" : checkInterest("Animals")}'>-->
<!--    <span class='interests__text'>{{ 'newCampaign.interests.animals' | translate}}</span>-->
<!--    <svg class='interests__svg' width='70' height='67' viewBox='0 0 70 67'>-->
<!--      <path-->
<!--        d='M8.5 32C13.1944 32 17 27.9706 17 23C17 18.0294 13.1944 14 8.5 14C3.80558 14 0 18.0294 0 23C0 27.9706 3.80558 32 8.5 32Z' />-->
<!--      <path-->
<!--        d='M24.5 18C29.1944 18 33 13.9706 33 9C33 4.02944 29.1944 0 24.5 0C19.8056 0 16 4.02944 16 9C16 13.9706 19.8056 18 24.5 18Z' />-->
<!--      <path-->
<!--        d='M45.5 18C50.1944 18 54 13.9706 54 9C54 4.02944 50.1944 0 45.5 0C40.8056 0 37 4.02944 37 9C37 13.9706 40.8056 18 45.5 18Z' />-->
<!--      <path-->
<!--        d='M61.5 32C66.1944 32 70 27.9706 70 23C70 18.0294 66.1944 14 61.5 14C56.8056 14 53 18.0294 53 23C53 27.9706 56.8056 32 61.5 32Z' />-->
<!--      <path-->
<!--        d='M53.7265 41.5384C50.6806 37.9032 48.1248 34.8027 45.0439 31.1676C43.4334 29.2431 41.3678 27.3186 38.9171 26.4633C38.532 26.3207 38.1469 26.2138 37.7618 26.1426C36.8865 26 35.9412 26 35.031 26C34.1207 26 33.1754 26 32.2652 26.1782C31.88 26.2495 31.4949 26.3564 31.1098 26.4989C28.6591 27.3543 26.6285 29.2787 24.983 31.2032C21.9371 34.8383 19.3814 37.9389 16.3005 41.574C11.7141 46.2427 6.07745 51.4102 7.12776 58.6448C8.14306 62.28 10.6988 65.8794 15.2852 66.913C17.8409 67.4475 25.9983 65.3449 34.6809 65.3449H35.3111C43.9936 65.3449 52.151 67.4119 54.7068 66.913C59.2931 65.8794 61.8489 62.2443 62.8642 58.6448C63.9495 51.3746 58.3128 46.207 53.7265 41.5384Z' />-->
<!--    </svg>-->
<!--  </div>-->

<!--  <div (click)='toggleInterests("Social & Family")' class='tile__box interests small'-->
<!--       [ngClass]='{"active" : checkInterest("Social & Family")}'>-->
<!--    <span class='interests__text'>{{ 'newCampaign.interests.socialFamily' | translate}}</span>-->
<!--    <svg class='interests__svg' width='67' height='70' viewBox='0 0 67 70'>-->
<!--      <path-->
<!--        d='M38.5 0V28H66.5C66.5 12.53 53.97 0 38.5 0ZM60.62 48.615C64.295 43.89 66.5 37.94 66.5 31.5H15.54L12.215 24.5H0V31.5H7.77C7.77 31.5 14.385 45.745 15.19 46.97C11.34 49.035 8.75 53.095 8.75 57.75C8.75 64.505 14.245 70 21 70C27.16 70 32.27 65.45 33.11 59.5H40.39C41.23 65.45 46.34 70 52.5 70C59.255 70 64.75 64.505 64.75 57.75C64.75 54.11 63.14 50.855 60.62 48.615ZM21 63C18.095 63 15.75 60.655 15.75 57.75C15.75 54.845 18.095 52.5 21 52.5C23.905 52.5 26.25 54.845 26.25 57.75C26.25 60.655 23.905 63 21 63ZM52.5 63C49.595 63 47.25 60.655 47.25 57.75C47.25 54.845 49.595 52.5 52.5 52.5C55.405 52.5 57.75 54.845 57.75 57.75C57.75 60.655 55.405 63 52.5 63Z' />-->
<!--    </svg>-->
<!--  </div>-->
<!--</div>-->
<!--<div class='tile' [ngClass]='isTabs ? "two-tabs": ""' *ngIf='linkedinInterests'>-->
<!--  <div *ngFor='let interest of linkedinInterests' (click)='toggleInterests(interest)' class='tile__box interests small'-->
<!--       [ngClass]='{"active" : checkInterest(interest)}'>-->
<!--    <span class='interests__text'>{{'newCampaign.linkedinInterests.' + getKeyNameForTranslate(interest) | translate}}</span>-->
<!--  </div>-->
<!--</div>-->
</div>
