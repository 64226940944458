import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {CampaignModel, PostModel, UserModel} from '@app/shared/model';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { postSelection, selectAgency, selectUserInfo } from '@app/store';
import { catchError, takeUntil } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { PostService } from '@app/services/post.service';
import { ToastrService } from 'ngx-toastr';
import { CONFIG } from '@config/configuration';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
})
export class PostComponent implements OnInit, OnDestroy {
  profileImage: string;
  isAgency = false;
  @Input() post: PostModel;
  @Input() hideSelect = false;
  @Input() showPrices: boolean;
  @Input() campaign: CampaignModel;
  @Output() selectedPost: EventEmitter<PostModel> = new EventEmitter();
  destroyed$ = new Subject<boolean>();
  disableButtons: boolean;
  isAdditionalUser = false;
  isBrandOwner: boolean;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private postService: PostService,
    private toastr: ToastrService
  ) {}

  get isShowPrices(): boolean {
    if (this.isBrandOwner) {
      return false;
    }
    return this.showPrices && !this.disableButtons;
  }

  ngOnInit(): void {
    const { platformName } = CONFIG;
    this.profileImage = this.post.socialAccountInfo.profilePicture;

    this.store.pipe(select(selectAgency), takeUntil(this.destroyed$)).subscribe((isAgency) => {
      this.isAgency = isAgency;
    });

    this.store.pipe(select(selectUserInfo), takeUntil(this.destroyed$)).subscribe((user: UserModel) => {
      if (user) {
        this.isAdditionalUser = !!user.isAdditionalBrandUser;
        this.isBrandOwner = user.isBrandOwner;
        if (platformName === 'io_platform' && this.isAdditionalUser) {
          this.disableButtons = true;
        }
      }
    });
  }
  selectPost(post: PostModel, event): void {
    event.preventDefault();
    event.stopPropagation();
    this.store.dispatch(postSelection({ postId: post.id, selected: !post.selected }));
  }
  setDefaultProfileImage(): void {
    this.profileImage = 'assets/img/icons/user-default.png';
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
