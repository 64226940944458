<section class="typical-page">
  <div class="wrapper">
    <h1 class="average">{{ 'loginPage.title' | translate }}</h1>

    <div class="typical-page__block">
      <div class="typical-page__group max-660">
        <a (click)='openLoginDialog()' class="typical-page__title" style="cursor: pointer">{{ 'loginPage.needLogin' | translate }}</a>
      </div>

      <img src="assets/img/video-box_bg.png" alt="" class="typical-page__bg">
    </div>
  </div>
</section>
