import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import * as fromBrandActions from '@app/store';
import { AppState } from '@store/app.state';
import { BrandModel } from '@app/shared/model';
import { CONFIG } from '@config/configuration';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from '@app/services/token.service';
import { TranslateService } from '@ngx-translate/core';
import { getCurrencySymbol } from '@app/shared/helpers/helpers';
import { select, Store } from '@ngrx/store';
import { selectBrand, selectBrandCurrency } from '@app/store';
import { takeUntil } from 'rxjs/operators';

declare let paypal: any;

@Component({
  selector: 'app-paypal-dialog',
  templateUrl: './paypal-dialog.component.html',
  styleUrls: ['./paypal-dialog.component.scss'],
})
export class PaypalDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  brand$: Observable<BrandModel>;
  destroyed$ = new Subject<boolean>();
  accessToken: string;
  currency: string;
  currencySymbol: string;
  paymentForm = this.fb.group(
    {
      amount: ['', [Validators.required, Validators.min(50)]],
    },
    {
      updateOn: 'change',
    }
  );

  private readonly payPayPalFee = 0.039;
  private payPayPalFlatFee = 0.35;
  private feeByCurrency = {
    CAD: 0.30,
    CHF: 0.55,
    EUR: 0.35,
    GBP: 0.20,
    USD: 0.30,
  };
  constructor(
    private fb: FormBuilder,
    public ref: DialogRef,
    private store: Store<AppState>,
    private tokenService: TokenService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.brand$ = this.store.pipe(select(selectBrand));
    this.store.pipe(select(selectBrandCurrency), takeUntil(this.destroyed$)).subscribe((currency: string) => {
      this.currencySymbol = getCurrencySymbol(currency);
      this.payPayPalFlatFee = this.feeByCurrency?.[currency];
    });
    // tslint:disable-next-line:no-string-literal
    this.loadExternalScript('https://www.paypalobjects.com/api/checkout.js').then(() => {
      this.initPayPalButton();
    });
    this.accessToken = this.tokenService.getToken();
    this.paymentForm.patchValue({ amount: 50 }, { emitEvent: false });
  }

  initPayPalButton(): void {
    paypal.Button?.render(
      {
        env: 'production',
        commit: true,
        style: {
          size: 'large',
        },
        payment: () => {
          // tslint:disable-next-line:no-shadowed-variable
          const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.accessToken}`,
            };

          // Backup code
          // return paypal.request.post(this.getPaymentUrl(),
          //   { amount: this.paymentForm.get('amount').value },
          //   { headers }).then((data: any) => {
          //   console.log('data', data);
          //   const { createPaypalPayment } = data;
          //   if (createPaypalPayment && createPaypalPayment.id) {
          //     return createPaypalPayment.id;
          //   }
          // }).catch((e) => {
          //   console.log('error', e);
          // });

          return paypal.request({
            method: 'post',
            url: this.getPaymentUrl(),
            json: { amount: this.paymentForm.get('amount').value },
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.accessToken}`,
            },
          }).then((data) => {
            const { createPayment } = data;
            if (createPayment && createPayment.payment_id) {
              return createPayment.payment_id;
            }
          });
        },
        onAuthorize: (data) => {
          // return (
          //   paypal.request
          //     .post(this.getExecutePaymentUrl(), {
          //       token: data.paymentID,
          //     })
          //     // tslint:disable-next-line:no-shadowed-variable
          //     .then((data) => {
          //       if (data && data.executePaypalPayment && data.executePaypalPayment.success) {
          //         this.updateFundsSuccess();
          //       } else {
          //         this.updateFundsFail();
          //       }
          //     })
          // );
          return paypal.request({
            method: 'get',
            url: this.getExecutePaymentUrl(data.paymentToken),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.accessToken}`,
            },
          }).then((res) => {
            if (res && res.executePayment && res.executePayment.success) {
              this.updateFundsSuccess();
            } else {
              this.updateFundsFail();
            }
          });
        },
      },
      '#paypal-button'
    );
  }
  ngAfterViewInit(): void {
    setTimeout(() => {}, 500);
  }

  private loadExternalScript(scriptUrl: string): any {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

  private updateFundsSuccess(): void {
    const successMsg = this.translate.instant('paypalWallet.fundsSuccessMsg');
    const addAmount = this.paymentForm.get('amount').value;
    this.store.dispatch(fromBrandActions.updateBrandFunds({ addAmount }));
    this.toastr.success(successMsg);
    this.ref.close();
  }

  private updateFundsFail(): void {
    const failMsg = this.translate.instant('commonMsg.somethingWentWrong');
    this.toastr.error(failMsg);
  }

  getFeeAmount(): number {
    const amount = 1; // this.paymentForm.get('amount').value;
    // const amount = this.paymentForm.get('amount').value;
    const calculatedFee = amount * this.payPayPalFee;
    return +(calculatedFee + this.payPayPalFlatFee).toFixed(2) || 0;
  }

  private getPaymentUrl(): string {
    const amount = this.paymentForm.get('amount').value;
    return (
      CONFIG.baseUrl +
      `payments/create/`
    );
  }

  private getExecutePaymentUrl(id): string {
    return CONFIG.baseUrl + `payments/execute?token=${id}`;
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
