
<header>
  <div class="wrapper-large">
    <div class="header" [ngClass]="{'active': isMobileMenuOpened}">
      <button type="button" class="menu-btn" (click)="isMobileMenuOpened = false">
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.0003 45.8337C36.4587 45.8337 45.8337 36.4587 45.8337 25.0003C45.8337 13.542 36.4587 4.16699 25.0003 4.16699C13.542 4.16699 4.16699 13.542 4.16699 25.0003C4.16699 36.4587 13.542 45.8337 25.0003 45.8337Z" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M19.1045 30.8962L30.8962 19.1045" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M30.8962 30.8962L19.1045 19.1045" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <a (click)="goToRoute('/')" class="logo">
        <img src="assets/images/logo.svg" alt="logo">
      </a>
      <div class="nav">
        <a (click)="goToRoute('campaigns')" class="nav__link">{{ 'topBar.campaigns' | translate }}</a>
        <a (click)="goToRoute('admin')" class="nav__link">{{ 'topBar.administration' | translate }}</a>
        <a (click)="goToRoute('invite-influencer')" class="nav__link">{{ 'topBar.inviteInfluencer' | translate }}</a>
        <a (click)="goToRoute('contact')" class="nav__link">{{ 'topBar.contactUs' | translate }}</a>
      </div>
      <button class="wallet" (click)='openPayPalDialog()'>
        <img src="assets/icons/empty-wallet.svg" alt="icon" class="wallet__icon">
        <span class="wallet__value" [innerHTML]="currencySymbol"></span>
        <span class="wallet__value">{{brand?.funds | floor}}</span>
      </button>
      <button (click)="goToRoute('campaign')" type="button" class="btn btn--bg btn--primary">{{ 'topBar.newCampaign' | translate }}</button>
      <button type="button" class="btn-text btn--primary">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.50998C8.71997 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span (click)='goToRoute("profile")'>{{ 'topBar.myAccount' | translate }}</span>
      </button>
    </div>
    <div class="header-mobile">
      <a href="/" class="logo">
        <img src="assets/images/logo.svg" alt="logo">
      </a>
      <button type="button" class="menu-btn" (click)="isMobileMenuOpened = true">
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.25 14.583H43.75" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
          <path d="M6.25 25H43.75" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
          <path d="M6.25 35.417H43.75" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
        </svg>
      </button>
    </div>
    <div class="overlay" style="display: none"></div>
  </div>
</header>
