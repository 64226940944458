import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuAuthorizedComponent } from './menu-authorized.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FloorPipe } from './floor.pipe';

@NgModule({
  declarations: [MenuAuthorizedComponent, FloorPipe],
  imports: [CommonModule, RouterModule, TranslateModule],
  exports: [MenuAuthorizedComponent],
})
export class MenuAuthorizedModule {}
