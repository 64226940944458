<section class="contact-us">
  <div class="wrapper">
    <h1>{{ 'contact.title' | translate }}</h1>
    <h2>{{ 'contact.subtitle' | translate }}</h2>
    <div class="contact-us__block">
      <div class="contact-us__box">
        <img src="assets/img/sc_icons/location-tick.svg" alt="icon" class="contact-us__icon">
        <div class="contact-us__group">
          <a href="https://goo.gl/maps/iF8QsFERMGwzkpop8" class="contact-us__text" target="_blank">Nordstrasse 1, 6300 Zug<br/>Switzlerand, CH499205145</a>
        </div>
      </div>
      <div class="contact-us__box">
        <img src="assets/img/sc_icons/call.svg" alt="icon" class="contact-us__icon">
        <div class="contact-us__group">
          <a href="tel:+41 41 711 01 05" class="contact-us__text" target="_blank">+41 41 711 01 05</a>
        </div>
      </div>
      <div class="contact-us__box">
        <img src="assets/img/sc_icons/mail.svg" alt="icon" class="contact-us__icon">
        <div class="contact-us__group">
          <a href="mailto:info@socialcollabz.com" class="contact-us__text" target="_blank">info@socialcollabz.com</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="contact-form">
  <div class="wrapper">
    <form [formGroup]="contactForm">
    <h2 class="h1">{{ 'contact.form.title' | translate }}</h2>
    <span class="h2">{{ 'contact.form.subtitle' | translate }}</span>
    <div class="contact-form__cont">
      <div class="contact-form__row three">
        <div class="form__box" [ngClass]='{"error": checkFieldError("name")}'>
          <span class="form__label">{{'contactUs.name' | translate }}</span>
          <input formControlName="name" type="text" class="form__input">
          <span class="form__valid">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
        </div>
        <div class="form__box" [ngClass]='{"error": checkFieldError("surname")}'>
          <span class="form__label">{{'contactUs.surname' | translate }}</span>
          <input formControlName='surname' type="text" class="form__input">
          <span class="form__valid">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
        </div>
        <div class="form__box" [ngClass]='{"error": checkFieldError("email")}'>
          <span class="form__label">Email</span>
          <input formControlName='email' type="email" class="form__input">
          <span class="form__valid">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
        </div>
      </div>
      <div class="contact-form__row two">
        <div class="form__box" [ngClass]='{"error": checkFieldError("company")}'>
          <span class="form__label">{{'contactUs.company' | translate }}</span>
          <input  formControlName='company' type="text" class="form__input">
          <span class="form__valid">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
        </div>
      </div>
      <div class="form__box" [ngClass]='{"error": checkFieldError("message")}'>
        <span class="form__label">{{'contactUs.message' | translate }}</span>
        <textarea formControlName='message' class="form__textarea"></textarea>
        <span class="form__valid">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>
      <label class="checked" [ngClass]='{"error": checkFieldError("acceptRules")}'>
        <input type="checkbox" formControlName='acceptRules'>
        <span class="checked__circle"></span>
        <span class="checked__text">{{ 'newCampaign.submitStep.agreeText' | translate }}
          <a routerLink='../terms-brand' target="_blank" class="link">{{ 'newCampaign.submitStep.termsConditions' | translate }}</a>
          {{ 'newCampaign.submitStep.and' | translate }}
          <a routerLink='../privacy-policy' target="_blank" class="link">{{ 'newCampaign.submitStep.privacyPolicy' | translate }}</a></span>
      </label>
      <button (click)='contactUs()' type="button" class="btn btn--bg btn--primary">{{ 'contact.form.send' | translate }}</button>
    </div>
    </form>
  </div>
</section>
