import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CommonService } from '@app/services/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activate-influencer',
  templateUrl: './activate-influencer.component.html',
  styleUrls: ['./activate-influencer.component.scss'],
})
export class ActivateInfluencerComponent implements OnInit {
  email: string;
  resultMessage: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    let successMsg;
    let failMsg;

    this.translate.get('activateInfluencer.success').subscribe(() => {
      successMsg = this.translate.instant('activateInfluencer.success');
      failMsg = this.translate.instant('activateInfluencer.failActivated');
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      const email = params.email;
      const activationToken = params.activation_token;

      this.commonService
        .activateUser(email, activationToken)
        .pipe(
          catchError((err) => {
            return throwError(err);
          })
        )
        .subscribe(({ activateAccount }) => {
          // this.toastr.success(successMsg);
          if (activateAccount.success) {
            this.resultMessage = successMsg;
            return;
          }
          this.resultMessage = failMsg;
        });
    });
  }
}
