import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '@app/services/common.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { CONFIG } from '@config/configuration';
import { camelCase } from 'lodash';

@Component({
  selector: 'app-community-insights',
  templateUrl: './community-insights.component.html',
  styleUrls: ['./community-insights.component.scss'],
})
export class CommunityInsightsComponent implements OnInit {
  communityInsight: any;
  regions: { y: number; name: string }[];
  age: { y: number; name: string }[];
  gender: { y: number; name: string }[];
  followers: { y: number; name: string }[];
  interests: { y: number; name: string }[];
  isloading = true;

  colorCharts = CONFIG.platform.chartColors;
  mockData = CONFIG.platform.communityDemographics;
  genderColors = ['#EB355E', '#5AC0F0'];
  ageColors = ['#698D29', '#89BC39', '#A6DD4A', '#b8ea6b'];

  constructor(
    private commonService: CommonService,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isloading = true;
      this.changeDetectorRef.detectChanges();
      this.getCommunityInsights();
    });
  }

  ngOnInit(): void {
    this.getCommunityInsights();
  }

  private getCommunityInsights(): void {
    this.commonService.getCommunityInsights().subscribe((insights) => {
      const { regions, age_categories, gender, influencer_total_followers, interests } = insights; // TODO: refactor later, implemented the existing approach.
      this.regions = this.transformData(regions);
      this.age = this.translateName(this.transformData(age_categories));
      this.gender = this.translateName(this.sortData(this.transformData(gender).sort()));
      this.followers = this.transformData(influencer_total_followers);
      this.interests = this.translateInterests(this.transformData(interests));
      this.isloading = false;
    });
  }

  private translateInterests(data): { y: number; name: string }[] {
    return data.map((item) => {
      const stringInCamelCase = camelCase(item.name);
      const translatedName = this.translate.instant(`newCampaign.interests.${stringInCamelCase}`);
      return { ...item, name: translatedName };
    });
  }

  private translateName(data): { y: number; name: string }[] {
    return data.map((item) => {
      const translatedName = item.name
        .split(' ')
        .map((words) => {
          const translateString = `communityInsights.${camelCase(words)}`;
          const translatedWords = this.translate.instant(translateString);
          if (translatedWords === translateString) {
            return words;
          }
          return translatedWords;
        })
        .join(' ');
      return { ...item, name: translatedName };
    });
  }

  private sortData(data): { y: number; name: string }[] {
    return data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  }

  private transformData(data): { y: number; name: string }[] {
    if (Array.isArray(data)) {
      const array = data.map((item) => this.transformData(item));
      return array.reduce((acc, val) => acc.concat(val), []);
    }
    return this.filteredItems(Object.keys(data).map((name) => ({ name, y: data[name] })));
  }

  private filteredItems(data): { y: number; name: string }[] {
    return this.formattedName(
      data.filter((item) => {
        const { name } = item;
        return name !== 'null' && name !== 'twitter' && name !== 'Milano';
      })
    );
  }

  private formattedName(data): { y: number; name: string }[] {
    return data.map((item) => {
      const { name } = item;
      const changedName = name.charAt(0).toUpperCase() + name.slice(1).replace(/_|-|\./g, ' ');
      return { ...item, name: changedName };
    });
  }
}
