import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolicyConditionsComponent } from './policy-conditions.component';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [PolicyConditionsComponent],
  imports: [CommonModule, BrowserModule],
  exports: [PolicyConditionsComponent],
})
export class PolicyConditionsModule {}
