import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CONFIG } from '@config/configuration';
declare var Swiper: any;
declare var $: any;

enum videoLinks {
  'it' = 'https://flytrendy.com/videos/Creators%20IT.mp4',
  'en' = 'https://flytrendy-assets.s3.eu-central-1.amazonaws.com/static/videos/SpotFlyTrendyBrandEN.mp4\n',
}

@Component({
  selector: 'app-influencer',
  templateUrl: './influencer.component.html',
})
export class InfluencerComponent implements OnInit {
  linkToVideo = videoLinks[this.translate.currentLang];
  swiper: any;
  @ViewChild('video') video: ElementRef<HTMLVideoElement>;

  constructor(private meta: Meta, private translate: TranslateService) {}

  getVideoLink(): void {
    const lang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;
    this.linkToVideo = videoLinks[lang];
    this.video?.nativeElement.load();
  }

  ngOnInit(): void {
    this.getVideoLink();
    this.meta.updateTag({
      name: 'keyword',
      content:
        'Automated Influencer Marketing Platform Italy, Influencer Marketing Italia, Campagna Influencer Marketing, Micro Influencer Italia, Trovare Influencer, Influencer Marketing Italy',
    });

    this.meta.updateTag({
      name: 'description',
      content:
        'Automated Influencer Marketing Platform Italy, Influencer Marketing Italia, Campagna Influencer Marketing, Micro Influencer Italia, Trovare Influencer, Influencer Marketing Italy',
    });

    this.translate.onLangChange.subscribe(() => this.getVideoLink());

    this.swiper = new Swiper('.swiper-container', {
      slidesPerView: 1,
      effect: 'fade',
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      infinity: false,
      autoHeight: true,
      updateOnWindowResize: true,
      on: {
        slideChange: this.toggleClassOnItem,
      },
    });
  }

  private toggleClassOnItem({ activeIndex }): void {
    $('.simple-as__item.active').removeClass('active');
    $('.simple-as__list').find(`[data-influencer-slider='${activeIndex}']`).addClass('active');
  }

  public changeSlider({ target }): void {
    this.swiper.slideTo(target.dataset.influencerSlider);
  }
}
