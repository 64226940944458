<div class="demo-modal modal modal-login modal-reg">
  <div class="modal-reg__header">
    <div class="modal-reg__caption-box">
      <span class="h1">{{ 'requestDemo.title' | translate }}</span>
    </div>
    <span class="h2">{{ 'requestDemo.subtitle' | translate }}</span>
  </div>
  <div class="modal-reg__main">
    <form [formGroup]="requestDemoForm" (ngSubmit)="onFormSubmit($event)">
      <div class="modal__wrapper">
        <div class="modal-reg__row">
          <div class="modal-reg__col">
            <div class="form__box">
              <span class="form__label">{{ 'regDialog.brandInfo.name' | translate }}</span>
              <input type="text" class="form__input" formControlName='first_name'>
              <span class="form__valid" *ngIf="!requestDemoForm.get('first_name').valid && requestDemoForm.get('first_name').touched">
                {{ 'requestDemo.enterName' | translate }}
              </span>
            </div>
            <div class="form__box">
              <span class="form__label">{{ 'regDialog.brandInfo.email' | translate }}</span>
              <input type="email" class="form__input" formControlName='email' email='true'>
              <span class="form__valid" *ngIf="!requestDemoForm.get('email').valid && requestDemoForm.get('email').touched">
                {{ 'requestDemo.enterEmail' | translate }}
              </span>
            </div>
          </div>
          <div class="modal-reg__col">
            <div class="form__box">
              <span class="form__label">{{ 'regDialog.brandInfo.phone' | translate }}</span>
              <input type="number" class="form__input" formControlName='phone'>
              <span class="form__valid" *ngIf="!requestDemoForm.get('phone').valid && requestDemoForm.get('phone').touched">
                {{ 'requestDemo.enterPhone' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn--bg btn--primary btn--modal" [disabled]="requestDemoForm.invalid">{{ 'requestDemo.request' | translate }}</button>
    </form>
  </div>
</div>
