import { Component, OnInit } from '@angular/core';
import {PaymentsService} from "@app/services/payments.service";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {getUser} from "@app/store";

@Component({
  selector: 'app-after-redirect',
  templateUrl: './after-redirect.component.html',
  styleUrls: ['./after-redirect.component.scss']
})
export class AfterRedirectComponent implements OnInit {

  constructor(
    private paymentsService: PaymentsService,
    private router: Router,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.paymentsService.completeUpdatePlan().subscribe((res) => {
      this.store.dispatch(getUser());
      this.router.navigate(['profile'])
    })
  }

}
