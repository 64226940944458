<!--<div class="post-block" [ngClass]='{"post-block__unseen" : !post.seen || post.updated, "post-blur" : !post.brandOwnerFeedback && isBrandOwner}'>-->
<!--  <div class="post-block__wrapper">-->
<!--    <div class="post-block__header">-->
<!--      <img *ngIf='post.postSocialType === "instagram"' src="assets/img/icons/in-color.svg" alt="instagram post" class="post-block__icon">-->
<!--      <img *ngIf='post.postSocialType === "instagramstory"' src="assets/img/icons/in-story-color.svg" alt="instagram story" class="post-block__icon">-->
<!--      <img *ngIf='post.postSocialType === "facebook"' src="assets/img/icons/fb-color.svg" alt="facebook post" class="post-block__icon">-->
<!--      <img *ngIf='post.postSocialType === "reels"' src="assets/img/icons/reels_icon.png" alt="reels post" class="post-block__icon">-->
<!--      <img *ngIf='post.postSocialType === "tiktok"' src="assets/img/icons/tiktok-color.png" alt="tiktok post" class="post-block__icon">-->
<!--      <img *ngIf='post.postSocialType === "linkedin"' src="assets/img/icons/linkedin.png" alt="linkedin post" class="post-block__icon">-->
<!--      &lt;!&ndash; twitter &ndash;&gt;-->
<!--      <div class="post-block__group">-->
<!--        <span class="post-block__info"><b>{{post.socialAccountInfo.followers}}</b> {{'campaigns.post.followers' | translate}}</span>-->
<!--        <span class="post-block__info"><b>{{post.socialAccountInfo.engagementRate}} %</b> {{'campaigns.post.engage' | translate}}</span>-->
<!--      </div>-->
<!--      <span *ngIf='isShowPrices' class="post-block__price">€ {{post.postPriceTechFee}}</span>-->
<!--      <div *ngIf='post.updated && post.seen' class="post-block__updated"></div>-->
<!--      <div *ngIf='!post.seen' class="post-block__seen"></div>-->
<!--    </div>-->

<!--    <div class="post-card">-->
<!--      <div class="post-card__top">-->
<!--        <img [src]="profileImage" alt="" class="post-card__avatar" (error)="setDefaultProfileImage()">-->
<!--        <div class="post-card__gorup">-->
<!--          <span class="post-card__name">{{post.socialAccountInfo.name || post.socialAccountInfo.username}}</span>-->
<!--          <span class="post-card__date">{{post.createdDatetime | date:'medium' }}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="post-card__wrap-img">-->
<!--        <img [src]="post.postImageInfo.imageUrl" alt="" class="post-card__middle-img">-->
<!--        <img *ngIf='post.isVideo' src='assets/img/icons/icon-play.png' alt='play video' class='post-card__video-icon-img'>-->
<!--      </div>-->
<!--      <div class="post-card__bottom">-->
<!--        <span class="post-card__description">{{post.postText}}</span>-->
<!--        <div class="post-card__btn-group">-->
<!--          <div class="btn-circle primary-btn-circle post-icon-group" >-->
<!--            <svg *ngIf='post.status === "approved" || post.status === "published" || post.status === "pending_published"' class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">-->
<!--              <path d="M43.77 16.74L24 36.51L13.23 25.77L9 30L24 45L48 21L43.77 16.74ZM30 0C13.44 0 0 13.44 0 30C0 46.56 13.44 60 30 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 30 0ZM30 54C16.74 54 6 43.26 6 30C6 16.74 16.74 6 30 6C43.26 6 54 16.74 54 30C54 43.26 43.26 54 30 54Z"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <div class="btn-circle tertiary-btn-circle post-icon-group" >-->
<!--            <svg *ngIf='post.status === "declined"' class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">-->
<!--              <path d="M37.77 18L30 25.77L22.23 18L18 22.23L25.77 30L18 37.77L22.23 42L30 34.23L37.77 42L42 37.77L34.23 30L42 22.23L37.77 18ZM30 0C13.41 0 0 13.41 0 30C0 46.59 13.41 60 30 60C46.59 60 60 46.59 60 30C60 13.41 46.59 0 30 0ZM30 54C16.77 54 6 43.23 6 30C6 16.77 16.77 6 30 6C43.23 6 54 16.77 54 30C54 43.23 43.23 54 30 54Z"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="post-block__bottom" *ngIf='isAgency && !disableButtons && !hideSelect'>-->
<!--      <span class="post-block__text">{{'campaigns.post.selectPost' | translate}}</span>-->
<!--      <button *ngIf='!post.selected' (click)='selectPost(post, $event)' type="button" class="btn small primary-btn-radius">-->
<!--        <span class="btn__text">{{'campaigns.post.no' | translate}}</span>-->
<!--      </button>-->
<!--      <button *ngIf='post.selected' (click)='selectPost(post, $event)' type="button" class="btn small secondary-btn-radius">-->
<!--        <span class="btn__text">{{'campaigns.post.yes' | translate}}</span>-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="post-card" [ngClass]='{"post-block__unseen" : !post.seen || post.updated}'>
  <div class="post-card-top">
    <img *ngIf='post.postSocialType === "instagram"' src="assets/img/sc_icons/instagram--black.svg" alt="icon" class="post-card-top__icon">
    <img *ngIf='post.postSocialType === "instagramstory"' src="assets/img/sc_icons/instagram-story--black.svg" alt="icon" class="post-card-top__icon">
    <img *ngIf='post.postSocialType === "tiktok"' src="assets/img/sc_icons/tiktok--black.svg" alt="icon" class="post-card-top__icon">
    <img *ngIf='post.postSocialType === "facebook"' src="assets/img/sc_icons/facebook--black.svg" alt="icon" class="post-card-top__icon">
    <img *ngIf='post.postSocialType === "linkedin"' src="assets/img/sc_icons/linkedin--black.svg" alt="icon" class="post-card-top__icon">

    <img *ngIf='post.postSocialType === "reels"' src="assets/img/sc_icons/instagram-reels.svg" alt="icon" class="post-card-top__icon">

    <div class="post-card-top__group">
      <span class="post-card-top__text"><b>{{post.socialAccountInfo.followers}}</b> {{'campaigns.post.followers' | translate}}</span>
      <span class="post-card-top__text"><b>{{post.socialAccountInfo.engagementRate}}</b> {{'campaigns.post.engage' | translate}}</span>
    </div>
    <span class="post-card-top__number" *ngIf="campaign.reward.cash === true">{{post.postPriceTechFee}}</span>
    <img src="assets/img/sc_icons/ticket-star-black.svg" *ngIf="campaign.reward.cash !== true" alt="icon" class="reward-box__icon__post">
  </div>
  <div class="post-card-middle">
    <div class="post-card-middle__author">
      <img [src]="profileImage" (error)="setDefaultProfileImage()" alt="avatar" class="post-card-middle__avatar">
      <div class="post-card-middle__group">
        <span class="post-card-middle__name">{{post.socialAccountInfo.name || post.socialAccountInfo.username}}</span>
        <span class="post-card-middle__date">{{post.createdDatetime | date:'medium' }}</span>
      </div>
    </div>
    <div class="post-card-middle__img">
      <img [src]="post.postImageInfo.imageUrl" alt="post image">
      <img class="play-icon" *ngIf='post.isVideo' src='assets/img/icons/icon-play.png' alt='play video'>
    </div>
    <span class="post-card-middle__text">{{post.postText}}</span>
  </div>
  <div class="post-card__btn-group medium">
    <button *ngIf='post.status === "approved" || post.status === "published"' type="button" class="post-card-btn">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="#4BD559" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.18 11.16L16 24.34L8.82 17.18L6 20L16 30L32 14L29.18 11.16ZM20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.16 36 4 28.84 4 20C4 11.16 11.16 4 20 4C28.84 4 36 11.16 36 20C36 28.84 28.84 36 20 36Z"></path>
      </svg>
    </button>
    <button *ngIf='post.status === "declined"' type="button" class="post-card-btn">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="#D54B4B" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.18 12L20 17.18L14.82 12L12 14.82L17.18 20L12 25.18L14.82 28L20 22.82L25.18 28L28 25.18L22.82 20L28 14.82L25.18 12ZM20 0C8.94 0 0 8.94 0 20C0 31.06 8.94 40 20 40C31.06 40 40 31.06 40 20C40 8.94 31.06 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z"/>
      </svg>
    </button>
    <div *ngIf='post.status === "pending"' style="display: flex">
      <button type="button" class="post-card-btn no-active">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="#4BD559" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.18 11.16L16 24.34L8.82 17.18L6 20L16 30L32 14L29.18 11.16ZM20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.16 36 4 28.84 4 20C4 11.16 11.16 4 20 4C28.84 4 36 11.16 36 20C36 28.84 28.84 36 20 36Z"></path>
        </svg>
      </button>
      <button type="button" class="post-card-btn no-active">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="#D54B4B" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.18 12L20 17.18L14.82 12L12 14.82L17.18 20L12 25.18L14.82 28L20 22.82L25.18 28L28 25.18L22.82 20L28 14.82L25.18 12ZM20 0C8.94 0 0 8.94 0 20C0 31.06 8.94 40 20 40C31.06 40 40 31.06 40 20C40 8.94 31.06 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z"/>
        </svg>
      </button>
    </div>
  </div>
  <div class="post-card__bottom" *ngIf='isAgency && !disableButtons && !hideSelect'>
    <div class="post-card__text">{{'campaigns.post.selectPost' | translate}}</div>
    <button *ngIf='!post.selected' (click)='selectPost(post, $event)' type="button" class="btn small primary-btn-radius">
      <span class="btn__text">{{'campaigns.post.no' | translate}}</span>
    </button>
    <button *ngIf='post.selected' (click)='selectPost(post, $event)' type="button" class="btn small secondary-btn-radius selected">
      <span class="btn__text">{{'campaigns.post.yes' | translate}}</span>
    </button>
  </div>
</div>
