/* tslint:disable */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, concatMap, tap, catchError } from 'rxjs/operators';
import * as fromBrandActions from './brand.actions';
import { BrandService } from '@app/services/brand.service';

@Injectable()
export class BrandEffects {
  constructor(private actions$: Actions, private brandService: BrandService) {}

  getBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBrandActions.getBrand),
      mergeMap(() =>
        this.brandService.getBrand().pipe(
          map((brand) => {
            return fromBrandActions.getBrandSuccess({ brand });
          }),
          catchError(() => fromBrandActions.getBrandFail)
        )
      )
    )
  );

  createBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBrandActions.createNewBrand),
      concatMap(({ brand }) =>
        this.brandService.createBrand(brand).pipe(
          map((brand) => {
            return fromBrandActions.createNewBrandSuccess({ brand })
          }),
          catchError((err) => {
            return of(fromBrandActions.createNewBrandError({ error: err }))
          })
        )
      )
    )
  );

  updateBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromBrandActions.updateBrandInfo),
      mergeMap(({ brand }) =>
        this.brandService.updateBrandInfo(brand).pipe(
          map((brand) => fromBrandActions.updateBrandInfoSuccess({ brand })),
          catchError(({ error, status }) => {
            const isTooOftenRequest = status === 429;
            return of(fromBrandActions.updateBrandInfoFail({ isTooOftenRequest }));
          })
        )
      ))
  );
}
